import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { getAccount, getBorrowerReport } from "../../../apis";

const store = (set) => ({
    isOpen: false,
    customerId: null,
    isLoading: false,
    data: null,

    isAnalysisLoading: false,
    analysis: null,

    openBorrowerInfo: async (customerId) => {
        if (!customerId) return;

        set(() => ({ isOpen: true, customerId, isLoading: true }));

        const res = await getAccount({ customer_id: customerId });

        if (res.success) {
            const data = res.data;
            set(() => ({ isLoading: false, data, isAnalysisLoading: true }));

            if (data?.data?.loan_account_no) {
                const analysisResponse = await getBorrowerReport({ lan: data?.data?.loan_account_no });

                if (analysisResponse.success) {
                    const details = analysisResponse.data?.data?.data[0];
                    if (details) set(() => ({ isAnalysisLoading: false, analysis: details }));
                } else set(() => ({ isAnalysisLoading: false }));
            } else {
                set(() => ({ isAnalysisLoading: false }));
            }
        } else set(() => ({ isLoading: false, data: null }));
    },
    closeBorrowerInfo: () => set(() => ({ isOpen: false, customerId: null, data: null, analysis: null })),
});

const useBorrowerInfo = create(devtools(store));

export default useBorrowerInfo;
