import React, { useState, useMemo } from "react";
import { IoCheckbox, IoSquareOutline } from "react-icons/io5";
import { HiOutlineChevronUp } from "react-icons/hi";
import SelectDropdown from "./forms/SelectDropdown";
import useClientStore from "../store/clientStore";

const MappingRow = ({ label = "", valueKey = "", handleChange, value = "", options = [] }) => (
    <div className="row mb-2 align-items-center">
        <div className="col-md-5 ps-0 pe-0">
            <div className="form-control text-start tx-readonly" style={{ padding: "11px" }}>
                {label}
            </div>
        </div>
        <div className="col-md-1 ps-0 pe-0">
            <div className="field-sep m-0" style={{ opacity: !!value ? "1" : "0.5" }} />
        </div>
        <div className="col-md-4 ps-0 text-start pe-0">
            <SelectDropdown
                options={options?.map((el) => ({ label: el?.label, value: el?.value, disabled: !el?.available }))}
                value={value}
                onChange={(val) => handleChange(valueKey, val)}
                placeholder="Select From Options"
            />
        </div>
        {!!value ? (
            <div className="col-md-2 ps-2 pe-0 text-start d-inline-flex align-items-center">
                <IoCheckbox className="ms-2 me-2" style={{ fontSize: "24px", color: "#2B5AFC" }} />
                <em>Matched</em>
            </div>
        ) : (
            <div className="col-md-2 ps-2 pe-0 text-start d-inline-flex align-items-center">
                <IoSquareOutline className="ms-2 me-2" style={{ fontSize: "24px", color: "#E1DEEC" }} />
                <em>Not Matched</em>
            </div>
        )}
    </div>
);

export default function BatchUploadHandler({
    availableHeaderNames = [],
    matchedHeaderNames,
    upfrontColumns = [],
    labelMapping = {},
    handleHeaderChange,
    automapping = false,
}) {
    const fileMappings = useClientStore((state) => state.mappings);

    const [show, setShow] = useState(false);

    const [toShowParameters, toHideParameters] = useMemo(() => {
        let showParams = [];
        let hideParams = [];

        const fileMappingKeys = Object.keys(labelMapping);

        for (let i = 0; i < fileMappingKeys.length; i++) {
            const el = fileMappingKeys[i];

            if (!automapping) {
                !!upfrontColumns?.includes(el) ? showParams.push(el) : hideParams.push(el);
            } else {
                if (!!fileMappings?.[el]?.length) showParams.push(el);
                else hideParams.push(el);
            }
        }

        if (!showParams?.length) {
            showParams = [...fileMappingKeys];
            hideParams = [];
        }

        return [showParams, hideParams];
    }, [fileMappings, labelMapping]);

    return (
        <div style={{ height: "100%", minHeight: "300px", maxHeight: "400px" }} className="batch-upload-main">
            <div className="batch-upload-field">
                {/* Category Heading */}
                <div className="row mb-3">
                    <div className="col-md-6 ps-0 pe-0 text-start">Prospect Attributes</div>
                    <div className="col-md-6 ps-0 pe-0 text-start">Column Label For File</div>
                </div>

                {/* Upfront Fields */}
                {!!toShowParameters.length &&
                    toShowParameters.map((el, key) => (
                        <MappingRow
                            key={key}
                            valueKey={el}
                            label={labelMapping?.[el]}
                            value={matchedHeaderNames?.[el]}
                            options={availableHeaderNames}
                            handleChange={handleHeaderChange}
                        />
                    ))}

                {!!toHideParameters.length && (
                    <>
                        <div className="w-100 d-flex flex-row justify-content-center align-items-center">
                            <button
                                className="btn btn-link d-flex flex-column justify-content-center align-items-center mt-3"
                                style={{ fontWeight: "normal", textDecoration: "none" }}
                                onClick={() => setShow((prev) => !prev)}
                            >
                                {show ? "Hide Fields" : "Show All Fields"}
                                <HiOutlineChevronUp
                                    style={{ fontSize: "20px", transition: "transform ease-in-out 200ms", transform: show ? "rotate(0deg)" : "rotate(180deg)" }}
                                />
                            </button>
                        </div>
                        <hr />
                    </>
                )}

                {/* Hidden Fields */}
                {show &&
                    !!toHideParameters.length &&
                    toHideParameters.map((el, key) => (
                        <MappingRow
                            key={key}
                            valueKey={el}
                            label={labelMapping?.[el]}
                            value={matchedHeaderNames?.[el]}
                            options={availableHeaderNames}
                            handleChange={handleHeaderChange}
                        />
                    ))}
            </div>
        </div>
    );
}
