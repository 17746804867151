
import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { FaPlay, FaCalendarAlt, FaCheck, FaEye, FaReply, FaPaperPlane, FaExclamationTriangle, FaSync, FaList, FaSearch, FaInfoCircle, FaFileExport, FaColumns } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';

const StatCard = ({ icon: Icon, count, label }) => (
  <Card className="mb-3 h-100">
    <Card.Body className="d-flex align-items-center">
      <Icon size={30} className="me-3 text-primary"/>
      <div>ScheduledBroadcast   
        <h3 className="mb-0">{count}</h3>
        <small>{label} <FaInfoCircle size={12} /></small>
      </div>
    </Card.Body>
  </Card>
);

const BroadcastAnalytics = () => {
  const [dateFrom, setDateFrom] = useState(new Date('2024-10-14'));
  const [dateTo, setDateTo] = useState(new Date('2024-10-21'));
  const [period, setPeriod] = useState('Last 7 days');

  return (
    <Container fluid className="py-4">
      <Row className="mb-4 align-items-center">
        <Col>
          <h5 className="mb-0">Date range filter</h5>
        </Col>
        <Col xs="auto">
          <Button variant="primary" className="me-2">
            <FaPlay className="me-2" />
            Watch Tutorial
          </Button>
          <Button variant="primary">New Broadcast</Button>
        </Col>
      </Row>

      <Row className="mb-4 align-items-end">
        <Col sm={6} md={3}>
          <Form.Group>
            <Form.Label>Date picker from</Form.Label>
            <DatePicker
              selected={dateFrom}
              onChange={(date) => setDateFrom(date)}
              customInput={
                <Form.Control />
              }
            />
          </Form.Group>
        </Col>
        <Col sm={6} md={3}>
          <Form.Group>
            <Form.Label>Date picker to</Form.Label>
            <DatePicker
              selected={dateTo}
              onChange={(date) => setDateTo(date)}
              customInput={
                <Form.Control />
              }
            />
          </Form.Group>
        </Col>
        <Col sm={6} md={3}>
          <Form.Group>
            <Form.Label>Period</Form.Label>
            <Form.Select value={period} onChange={(e) => setPeriod(e.target.value)}>
              <option>Last 7 days</option>
              <option>Last 30 days</option>
              <option>Custom</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col sm={6} md={3}>
          <Button variant="outline-primary" className="me-2">Apply now</Button>
          <Button variant="outline-secondary">
            <FaFileExport className="me-2" />
            Export
          </Button>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <h5 className="mb-3">Overview</h5>
          <Row>
            <Col sm={6} md={4} lg={3}><StatCard icon={FaCheck} count={6931} label="Sent" /></Col>
            <Col sm={6} md={4} lg={3}><StatCard icon={FaCheck} count={6040} label="Delivered" /></Col>
            <Col sm={6} md={4} lg={3}><StatCard icon={FaEye} count={4210} label="Read" /></Col>
            <Col sm={6} md={4} lg={3}><StatCard icon={FaReply} count={55} label="Replied" /></Col>
            <Col sm={6} md={4} lg={3}><StatCard icon={FaPaperPlane} count={4} label="Sending" /></Col>
            <Col sm={6} md={4} lg={3}><StatCard icon={FaExclamationTriangle} count={540} label="Failed" /></Col>
            <Col sm={6} md={4} lg={3}><StatCard icon={FaSync} count={0} label="Processing" /></Col>
            <Col sm={6} md={4} lg={3}><StatCard icon={FaList} count={0} label="Queued" /></Col>
          </Row>
        </Col>
      </Row>

      <Row className="mb-4 align-items-center">
        <Col>
          <h5 className="mb-0">Broadcast list</h5>
        </Col>
        <Col xs="auto">
          <Form.Group className="d-flex align-items-center">
            <Form.Label className="me-2 mb-0">Sorted by:</Form.Label>
            <Form.Select className="w-auto">
              <option>Latest</option>
              <option>Oldest</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs={12} sm="auto" className="mt-2 mt-sm-0">
          <Form.Group className="d-flex">
            <Form.Control type="text" placeholder="Search..." />
            <Button variant="outline-secondary">
              <FaSearch />
            </Button>
          </Form.Group>
        </Col>
        <Col xs="auto" className="mt-2 mt-sm-0">
          <Button variant="outline-secondary">
            <FaColumns />
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default BroadcastAnalytics;