import { format } from "date-fns";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { HiXCircle } from "react-icons/hi";
import useCallCustomer from "../../../../hooks/useCallCustomer";
import useBorrowerInfo from "../store";

export default function PtpConfirm({ onConfirm = () => {}, show, setShow = () => {}, amount, date }) {
    const data = useBorrowerInfo((state) => state.data);

    const { callCustomer, callTypes, loading } = useCallCustomer();

    const closeModal = () => {
        setShow(false);
    };

    const handleConfirm = () => {
        onConfirm();
        closeModal();
    };

    return (
        <>
            <Modal show={show} size="sm">
                <Modal.Body style={{ borderRadius: "0 0 10px 10px", position: "relative" }} className="pt-5 pb-3">
                    <button
                        onClick={() => closeModal()}
                        style={{
                            fontSize: "0.8rem",
                            position: "absolute",
                            top: "1rem",
                            right: "1rem",
                            background: "transparent",
                            border: "none",
                            color: "#555",
                        }}
                        className="d-flex align-items-center gap-1"
                    >
                        <HiXCircle style={{ fontSize: "1.5rem", color: "#aaa" }} /> CLOSE
                    </button>

                    <div className="alert alert-warning text-center mt-2" style={{ fontSize: "1rem" }} role="alert">
                        Please confirm that <strong>{data?.data?.customer_name}</strong> promised to pay on <strong>Rs. {!!amount ? amount : 0}</strong> on{" "}
                        <strong>{!!date ? format(new Date(date), "dd MMM yyyy") : "XX"}</strong>
                    </div>
                </Modal.Body>
                <Modal.Footer className="row justify-content-center gap-3">
                    <Button
                        variant="primary"
                        className="col-5"
                        style={{ paddingTop: "0.65rem", paddingBottom: "0.65rem" }}
                        disabled={loading}
                        onClick={async () => {
                            await callCustomer({ call_type: callTypes?.AGENT, customer_mobile_number: data?.data?.customer_mobile_number });
                            closeModal();
                        }}
                    >
                        <strong>{loading ? "Calling..." : "CALL AGAIN"}</strong>
                    </Button>
                    <Button className="col-5 py-2" onClick={() => handleConfirm()} variant="outline-primary">
                        I CONFIRM
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
