import { isEmpty } from "./isEmpty";

// Mask mobile number with asterisks first 5 digits
export const maskMobileNumber = (mobile, digitsToShow = 5) => {
    if (isEmpty(mobile)) return "";

    const fullNumber = mobile.toString();
    const last5 = fullNumber.slice(-1 * digitsToShow);
    const masked = last5.padStart(fullNumber.length, "*");
    return masked;
};

export const maskString = (str, digitsToShow = 4) => {
    if (isEmpty(str)) return "";

    const fullNumber = str.toString();
    const last5 = fullNumber.slice(-1 * digitsToShow);
    const masked = last5.padStart(fullNumber.length, "*");
    return masked;
};

export const maskStringFromStart = (str, digitsToHide = 5) => {
    if (isEmpty(str)) return "";

    const fullString = str.toString();
    const restString = fullString.slice(digitsToHide);

    return restString.padStart(fullString.length, "*");
};

export const maskEmail = (email) => {
    if (isEmpty(email)) return "";

    const emailSplit = email.split("@");
    if (emailSplit.length < 2) return "";

    const emailUserName = emailSplit[0];

    return `${emailUserName[0]}*****${emailUserName.length > 6 ? emailUserName.slice(-3) : emailUserName.slice(-1)}@${emailSplit[1]}`;
};
