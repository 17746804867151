import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import XLSX from "xlsx";
import useClientStore from "../store/clientStore";
import SelectDropdown from "../ui/forms/SelectDropdown";
import { convertToMapValue } from "../utils/fileMappings";
import { showToast } from "../utils/handleToast";

// parse and match
export const parseAndMatch = (parsedHeaders, allParameters) => {
    let headArr = [...parsedHeaders.filter((el) => !!el)];

    let copyHeadArr = headArr.map((item) => convertToMapValue(item));

    let matchedHead = {};

    for (const [key, value] of Object.entries(allParameters)) {
        let status = {
            found: false,
            at: -1,
            key,
        };

        for (let val of value) {
            const r = copyHeadArr.indexOf(val);
            if (r !== -1) {
                status = {
                    found: true,
                    at: r,
                    key,
                };
                break;
            } else continue;
        }

        matchedHead[key] = status.found ? headArr[status.at] : "";
    }

    return { headArr, matchedHead };
};

// sort by property
const sortByProperty = (property) => (a, b) => {
    if (a[property] === b[property]) return 0;
    else if (a[property]) return -1;
    return 1;
};

// upload batch handler hook
export const useBatchUploadHandler = ({ showProgress = true }) => {
    const fileMappings = useClientStore((state) => state.mappings);

    const [batchFile, setBatchFile] = useState(null);
    const [fileName, setFileName] = useState("");
    const [progressValue, setProgressValue] = useState(0);
    const [importDisabled, setImportDisabled] = useState(false);

    const [allHeaderName, setAllHeaderName] = useState([]);
    const [matchedHeaderNames, setMatchedHeaderNames] = useState({});

    const [sheets, setSheets] = useState([]);
    const [showSelectSheet, setShowSelectSheet] = useState(false);
    const [workSheets, setWorkSheets] = useState(null);

    const resetBatchUpload = () => {
        setBatchFile(null);
        setFileName("");
        setProgressValue(0);
        setImportDisabled(false);
        setAllHeaderName([]);
        setMatchedHeaderNames({});

        setSheets([]);
        setShowSelectSheet(false);
        setWorkSheets(null);
    };

    // parse headers
    const parseFileHeaders = (file) => {
        return new Promise((resolve, reject) => {
            if (!file) {
                reject("File not provided");
            } else {
                const reader = new FileReader();
                reader.onload = (e) => {
                    // Parse Data
                    const ab = e.target.result;
                    const wb = XLSX.read(ab, { type: "array" });

                    setWorkSheets(wb);
                    setSheets(wb.SheetNames);
                    setShowSelectSheet(true);

                    resolve(true);

                    // const wsname = wb.SheetNames[0];
                    // const ws = wb.Sheets[wsname];

                    // data = XLSX.utils.sheet_to_json(ws, { header: 1 });

                    // resolve(data[0]);
                };
                reader.readAsArrayBuffer(file);
            }
        });
    };

    const getUploadHeaders = () => {
        let uploadHead = {};
        for (const [key, value] of Object.entries(matchedHeaderNames)) {
            if (!!value) uploadHead[value] = key;
        }
        return uploadHead;
    };

    const headerNameLeft = () => {
        let availableHead = [...allHeaderName].map((el) => ({
            label: el,
            value: el,
            available: !Object.values(matchedHeaderNames).includes(el),
        }));

        // sorting for making the available to top
        return availableHead.sort(sortByProperty("available"));
    };

    const handleHeaderChange = (key, value) => {
        setMatchedHeaderNames((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    async function handleFileValidation(e) {
        let filePath = e.target.value;
        let file = e.target.files[0];
        let allowedExtensions = /(\.xlse|\.xlsx|\.xlsm|\.xlsb|\.xltx|\.xltm|\.xls|\.xlt|\.xls|\.xlsb|\.xml|\.xla|\.xlw|\.xlr)$/i;
        if (!allowedExtensions.exec(filePath)) {
            toast.error("Please upload file having extensions Excel or Binary Excel.");
            return false;
        } else {
            try {
                setBatchFile(file);
                setFileName(file?.name);
                setImportDisabled(true);

                await parseFileHeaders(file);
            } catch (error) {
                console.log(error);
            }
        }
    }

    function proceedMapping(sheetSelected) {
        if (!sheetSelected || !workSheets) {
            showToast({ variant: "warning", message: "Unknown Error Occured" });
            return;
        }

        if (!!showProgress) setProgressValue((prev) => prev + 1);

        const ws = workSheets?.Sheets[sheetSelected];
        let data = XLSX.utils.sheet_to_json(ws, { header: 1 });

        let { headArr, matchedHead } = parseAndMatch(data[0], fileMappings);
        setAllHeaderName([...headArr]);
        setMatchedHeaderNames({ ...matchedHead });
        setImportDisabled(false);
    }

    useEffect(() => {
        !!showProgress &&
            progressValue <= 100 &&
            progressValue > 0 &&
            setTimeout(() => {
                setProgressValue((prev) => prev + 2);
            }, 30);
    }, [progressValue]);

    return {
        SelectSheetModal: () => (
            <SelectSheetModal
                show={showSelectSheet}
                setShow={setShowSelectSheet}
                onSuccess={proceedMapping}
                sheetOptions={sheets?.map((el) => ({ label: el, value: el }))}
            />
        ),

        progressValue,
        importDisabled,
        fileName,
        batchFile,
        matchedHeaderNames,
        availableHeaderNames: headerNameLeft(),
        handleHeaderChange,
        handleFileValidation,
        getUploadHeaders,
        resetBatchUpload,
    };
};

function SelectSheetModal({ show, setShow, onSuccess, sheetOptions = [] }) {
    const [selectedSheet, setSelectedSheet] = useState("");

    const closeModal = () => {
        setShow(false);
    };

    return (
        <Modal size="sm" show={show} backdrop="static">
            <Modal.Header>
                <Modal.Title>Choose Sheet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-4 mt-4 row">
                    <label className="col-form-label">Choose Sheet</label>
                    <SelectDropdown placeholder="Choose Sheet" value={selectedSheet} onChange={setSelectedSheet} options={sheetOptions} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                    Close
                </Button>
                <Button
                    disabled={!selectedSheet}
                    variant="primary"
                    onClick={() => {
                        onSuccess(selectedSheet);
                        closeModal();
                    }}
                >
                    Next
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
