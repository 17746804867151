import React from "react";
import AudioPlayer from "material-ui-audio-player";
import BackgroundOrange from "../../Static/RawImages/record_bar-orange.svg";
import BackgroundYellow from "../../Static/RawImages/record_bar-yellow.svg";
import BackgroundPurple from "../../Static/RawImages/record_bar-purple.svg";
import ContentLoader from "../ContentLoader";
import { format } from "date-fns";
import { isEmpty } from "../../Functions/isEmpty";

var sectionStyleorange = {
    backgroundImage: `url(${BackgroundOrange})`,
};
var sectionStyleyellow = {
    backgroundImage: `url(${BackgroundYellow})`,
};
var sectionStylepurple = {
    backgroundImage: `url(${BackgroundPurple})`,
};

const audioArray = [sectionStyleorange, sectionStyleyellow, sectionStylepurple];

function AccountCall({ data, adminDispURL }) {
    // let i = index % 3
    return adminDispURL ? (
        <div className="account_extra_call">
            <div className="audio-player" style={audioArray[0]}>
                <AudioPlayer
                    elevation={1}
                    width="100%"
                    variation="default"
                    spacing={3}
                    autoplay={false}
                    order="standart"
                    preload="auto"
                    volume={false}
                    src={!isEmpty(adminDispURL) ? adminDispURL : ""}
                />
            </div>
        </div>
    ) : data ? (
        <>
            {data.length > 0 ? (
                <div className="account_extra_call">
                    <p className="title-hr mt-2 mb-4">
                        <span>{format(new Date(), "do MMM yyyy")}</span>
                    </p>

                    {data?.map((item, index) => {
                        let i = index % 3;
                        return (
                            <div className="audio-player" style={audioArray[i]} key={index}>
                                <AudioPlayer
                                    elevation={1}
                                    width="100%"
                                    variation="default"
                                    spacing={3}
                                    autoplay={false}
                                    order="standart"
                                    preload="auto"
                                    volume={false}
                                    src={!isEmpty(item?.RecordingUrl) ? item.RecordingUrl : ""}
                                />
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div className="fs-5 fw-bold">No Data Found</div>
            )}
        </>
    ) : (
        <ContentLoader />
    );
}

export default AccountCall;
