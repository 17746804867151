import React, { useEffect, useMemo, useState } from "react";
import { RiCloseCircleFill } from "react-icons/ri";
import { Offcanvas, Accordion } from "react-bootstrap";
import { format } from "date-fns";
import { getDisposition } from "../../../utils/dispositions";
import { fetchDispositionData } from "../../../API/agentApis";
import ContentLoader from "../../../Components/ContentLoader";
import { isEmpty } from "../../../Functions/isEmpty";
import classes from "./style.module.css";

const tabs = ["Connected", "Not Connected", "All"];

function checkValidNumber(num) {
    if (isEmpty(num) || num <= 0 || isNaN(num)) return false;
    return true;
}

export default function DispositionHistory({ show, setShow = () => {}, customerMobileNo }) {
    const [activeTab, setActiveTab] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const closeModal = () => setShow(false);

    const fetchDetails = async () => {
        if (!customerMobileNo) return;
        setLoading(true);
        setActiveTab(0);

        const res = await fetchDispositionData({ customer_mobile_number: customerMobileNo });
        setData(res?.data?.length ? res.data : []);
        setLoading(false);
    };

    useEffect(() => {
        if (customerMobileNo && show) fetchDetails();
    }, [customerMobileNo, show]);

    const filteredData = useMemo(() => {
        if (activeTab === 0) return data?.filter(el => el?.is_connected_disposition);
        if (activeTab === 1) return data?.filter(el => !el?.is_connected_disposition);
        return data;
    }, [data, activeTab]);

    return (
        <Offcanvas show={show} onHide={closeModal} placement="end" className={classes.offcanvas}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Disposition History {!!data?.length ? `(${data?.length})` : ""}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className={classes.tabs} style={{ gridTemplateColumns: `repeat(${tabs.length},1fr)` }}>
                    {tabs.map((tab, index) => (
                        <button
                            key={index}
                            onClick={() => setActiveTab(index)}
                            className={activeTab === index ? classes.active_tab : classes.tab}
                        >
                            {tab}
                        </button>
                    ))}
                    <span
                        style={{
                            left: `${(activeTab * 100) / tabs.length}%`,
                            width: `${100 / tabs.length}%`,
                        }}
                        className={classes.active_tab_indicator}
                    />
                </div>

                <div className={classes.scroll_content}>
                    {loading ? <ContentLoader /> : !!filteredData.length ? (
                        <div className={classes.list_wrapper}>
                            <Accordion defaultActiveKey="1" className="level-one">
                                {filteredData.map((item, index) => (
                                    <DispItem data={item} eventKey={index + 1} key={index} />
                                ))}
                            </Accordion>
                        </div>
                    ) : (
                        <p style={{ textAlign: "center", padding: "20px" }}>No Disposition History found</p>
                    )}
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    );
}

function DispItem({ data, eventKey }) {
    return (
        <Accordion.Item eventKey={eventKey} style={{ border: "none", paddingRight: "0.5rem" }}>
            <Accordion.Header className="p-0">
                <div className="disposition-row pb-3 w-100">
                    <div className="d-flex align-items-center gap-2 w-100">
                        <div>
                            <div className="dispo-date text-center" style={{ whiteSpace: "nowrap" }}>
                                {data.contact_date ? format(new Date(data.contact_date), "dd") : "DD"}
                                <br />
                                <span>{data.contact_date ? format(new Date(data.contact_date), "MMM yy") : "MMM YY"}</span>
                            </div>
                        </div>
                        <div>
                            <div className="dispo-note">
                                <p className="tx-15 text-black mb-1" style={{ fontWeight: "normal" }}>
                                    {data.disposition ? getDisposition(data.disposition) : ""}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Accordion.Header>
            <Accordion.Body className="p-0">
                <div className="dispositions-body grey-bg px-4 py-3">
                    <p className="m-0 p-0" style={{ lineHeight: "30px" }}>
                        {/* Additional disposition details */}
                    </p>
                </div>
            </Accordion.Body>
        </Accordion.Item>
    );
}
