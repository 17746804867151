import React from "react";

export default function WhatsappButton({ whatsapp_no = null }) {
    // const link = `https://wa.me/${whatsapp_no}?text=Hi%2C%20I'm%20from%20KS%20Legal.%20This%20is%20regarding%20your%20outstanding%20loan.%20`;

    return <a className="site-btn text-center blue-btn disabled-btn">WhatsApp</a>;
    // return !!whatsapp_no ? (
    //     <a href={link} target="_blank" rel="noreferrer" className="site-btn text-center blue-btn">
    //         WhatsApp
    //     </a>
    // ) : (
    //     <a className="site-btn text-center blue-btn disabled-btn">WhatsApp</a>
    // );
}
