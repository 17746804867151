import { API_ENPOINTS } from "./endpoints";
import { apiGET, apiPOST } from "./handler";

// ALL GROUP APIs
export const getExcludedCustomers = (params) => apiGET(API_ENPOINTS.client_panel_excluded_customer, params);
export const addExcludedCustomer = (body) => apiPOST(API_ENPOINTS.client_panel_excluded_customer, body);

export const getGupshupTemplateMapping = (params) => apiGET(API_ENPOINTS.dashboard_gupshup_template_mapping, params);
export const addGupshupTemplateMapping = (body) => apiPOST(API_ENPOINTS.dashboard_gupshup_template_mapping, body);
export const removeGupshupTemplateMapping = (params) => apiGET(API_ENPOINTS.dashboard_gupshup_template_mappingNew, params);
export const statusGupshupTemplateMapping = (body) => apiPOST(API_ENPOINTS.dashboard_gupshup_template_mappingNew, body);

export const getProspectiveCandidate = (params) => apiGET(API_ENPOINTS.dashboard_prospective_candidate, params);
export const addProspectiveCandidate = (body) => apiPOST(API_ENPOINTS.dashboard_prospective_candidate, body);
export const updateProspectiveCandidate = (body) => apiPOST(API_ENPOINTS.dashboard_prospective_candidateNew, body);

export const getClientPoc = (params) => apiGET(API_ENPOINTS.dashboard_client_poc, params);
export const addClientPoc = (body) => apiPOST(API_ENPOINTS.dashboard_client_poc, body);
export const updateClientPoc = (id, body) => apiPOST(`${API_ENPOINTS.dashboard_client_pocNew}${id}`, body);

export const getAutoCampaigningMapping = (params) => apiGET(API_ENPOINTS.dashboard_auto_campaigning_mapping, params);
export const addAutoCampaigningMapping = (body) => apiPOST(API_ENPOINTS.dashboard_auto_campaigning_mapping, body);
export const updateAutoCampaigningMapping = (body) => apiPOST(API_ENPOINTS.dashboard_auto_campaigning_mappingNew, body);

export const getExoPhone = (params) => apiGET(API_ENPOINTS.dashboard_languages, params);
export const addExoPhone = (body) => apiPOST(API_ENPOINTS.dashboard_languages, body);
export const updateExophone = (id, body) => apiPOST(`${API_ENPOINTS.dashboard_languages}${id}`, body);

export const getPaymentReceipts = (params, cancelToken) => apiGET(API_ENPOINTS.dashboard_payment_receipt, params, cancelToken);
export const updatePaymentReceipts = (body) => apiPOST(API_ENPOINTS.dashboard_payment_receiptNew, body);
export const removePaymentReceipts = (params) => apiPOST(API_ENPOINTS.dashboard_payment_receiptNew, undefined, params);

export const getCallQualityListing = (params, cancelToken) => apiGET(API_ENPOINTS.call_call_quality_listing_v1, params, cancelToken);
export const updateCallQualityListing = (id, body) => apiPOST(`${API_ENPOINTS.call_call_quality_listing_v1}${id}`, body);

export const getPaymentReview = (params, cancelToken) => apiGET(API_ENPOINTS.pre_litigation_payment_review, params, cancelToken);
export const updatePaymentReview = (noticeId, body) => apiPOST(`${API_ENPOINTS.pre_litigation_update_payment_review}${noticeId}`, body);

export const getConciliationIntention = (body) => apiPOST(API_ENPOINTS.pre_litigation_get_conciliation_intention, body);
export const updateConciliationIntention = (body) => apiPOST(API_ENPOINTS.pre_litigation_get_conciliation_intentionNew, body);
export const updateClientChannels = (body) => apiPOST(API_ENPOINTS.dashboard_update_client_channels, body);

// ALL GET APIs
export const getGupshupMappingSummary = (params) => apiGET(API_ENPOINTS.dashboard_gupshup_mapping_summary, params);
export const getBorrowerPageAdminData = (params) => apiGET(API_ENPOINTS.dashboard_borrower_page_admin_data, params);
export const getBorrowerPageAdminActionHistory = (params) => apiGET(API_ENPOINTS.dashboard_borrower_page_admin_action_history, params);
export const getSendProspectiveCandidateJD = (params) => apiGET(API_ENPOINTS.dashboard_send_prospective_candidate_jd, params);
export const getGenerateSendOfferLetter = (params) => apiGET(API_ENPOINTS.dashboard_generate_send_offer_letter, params);
export const getAgentPanelData = (params) => apiGET(API_ENPOINTS.dashboard_panel_data, params);
export const getAgentLeadCounts = (params) => apiGET(API_ENPOINTS.dashboard_get_agent_leads_count, params);
export const getFinalSummaryManagementReport = (params, cancelToken) => apiGET(API_ENPOINTS.dashboard_final_summary_management_report, params, cancelToken);
export const getOpenAiAnalysis = (params) => apiGET(API_ENPOINTS.pre_litigation_open_ai_analysis, params);
export const getGupshupAccount = (params) => apiGET(API_ENPOINTS.dashboard_gupshup_account, params);
export const getIvrCampaignRestartSummary = (params) => apiGET(API_ENPOINTS.campaigns_todays_ivr_campaign_restart_summary, params);
export const getNoticeCampaigningReport = (params) => apiGET(API_ENPOINTS.pre_litigation_notice_campaigning_report, params);
export const getBatchesForAdmin = (params) => apiGET(API_ENPOINTS.dashboard_get_batchs_for_admin, params);
export const getAlternateNumberManagementReport = (params) => apiGET(API_ENPOINTS.dashboard_alternate_number_management_report, params);
export const getRetentionData = (params) => apiGET(API_ENPOINTS.dashboard_get_retention_data, params);
export const getSetlAnalysisReport = (params) => apiGET(API_ENPOINTS.pre_litigation_setl_analysis_report, params);
export const getSetlStageAnalysisReport = (params, cancelToken) => apiGET(API_ENPOINTS.pre_litigation_setl_stage_analysis_report, params, cancelToken);
export const getBorrowerReport = (params) => apiGET(API_ENPOINTS.pre_litigation_borrower_report, params);
export const getAllNoticePdf = (params, cancelToken) => apiGET(API_ENPOINTS.pre_litigation_all_notice_pdf, params, cancelToken);
export const getDeliveryStatus = (params, cancelToken) => apiGET(API_ENPOINTS.pre_litigation_delivery_status, params, cancelToken);
export const getCallAiAnalysisReport = (params, cancelToken) => apiGET(API_ENPOINTS.dashboard_call_ai_analysis_report, params, cancelToken);
export const getCallAgentAnalysisReport = (params, cancelToken) => apiGET(API_ENPOINTS.dashboard_call_agent_analysis_report, params, cancelToken);
export const getWapDeliveryReport = (params, cancelToken) => apiGET(API_ENPOINTS.dashboard_wap_delivery_report, params, cancelToken);
export const getWapReplyReport = (params, cancelToken) => apiGET(API_ENPOINTS.dashboard_wap_reply_report, params, cancelToken);
export const getEmailDeliveryReport = (params, cancelToken) => apiGET(API_ENPOINTS.dashboard_email_delivery_report, params, cancelToken);
export const getEmailReplyReport = (params, cancelToken) => apiGET(API_ENPOINTS.dashboard_email_reply_report, params, cancelToken);
export const getSmsDeliveryReport = (params, cancelToken) => apiGET(API_ENPOINTS.dashboard_sms_delivery_report, params, cancelToken);
export const getConciliationMeetingReport = (params, cancelToken) => apiGET(API_ENPOINTS.dashboard_conciliation_meeting_report, params, cancelToken);
export const getConciliationIntentionReport = (params, cancelToken) => apiGET(API_ENPOINTS.dashboard_conciliation_intention_report, params, cancelToken);
export const getDashboardBorrowerReport = (params, cancelToken) => apiGET(API_ENPOINTS.dashboard_borrower_report, params, cancelToken);
export const getNewRetentionReport = (params, cancelToken) => apiGET(API_ENPOINTS.dashboard_new_retention_report, params, cancelToken);
export const getNewCallReport = (params, cancelToken) => apiGET(API_ENPOINTS.dasboard_new_call_report, params, cancelToken);
export const getLetterAgingSummary = (params, cancelToken) => apiGET(API_ENPOINTS.pre_litigation_letter_aging_summary, params, cancelToken);
export const getLetterOverallSummary = (params, cancelToken) => apiGET(API_ENPOINTS.pre_litigation_letter_overall_summary, params, cancelToken);
export const getProcessPanelHelplineReport = (params) => apiGET(API_ENPOINTS.process_panel_helpline_report, params);
export const getAllocationAnalysis = (params, cancelToken) => apiGET(API_ENPOINTS.dashboard_allocation_analysis, params, cancelToken);
export const getExcludedCustomerData = (params, cancelToken) => apiGET(API_ENPOINTS.client_panel_excluded_customer_data, params, cancelToken);
export const getGenerateMissingNoticePdf = (params) => apiGET(API_ENPOINTS.pre_litigation_generate_missing_notice_pdf, params);
export const getIvrCallReport = (params) => apiGET(API_ENPOINTS.dashboard_ivr_call_report, params);
export const getConciliationMeetingReminderEmail = (params) => apiGET(API_ENPOINTS.dashboard_conciliation_meeting_reminder_email, params);
export const getExophoneReport = (params) => apiGET(API_ENPOINTS.dashboard_exophone_report, params);
export const getExotelCampaign = (params, cancelToken) => apiGET(API_ENPOINTS.campaigns_exotel_campaign, params, cancelToken);
export const getAccount = (params, cancelToken) => apiGET(API_ENPOINTS.dashboard_account, params, cancelToken);
export const getBorrowerAccountDetails = (params, cancelToken) => apiGET(API_ENPOINTS.dashboard_borrower_account_details, params, cancelToken);
export const getFirWorkingState = (params, cancelToken) => apiGET(API_ENPOINTS.fir_working_state, params, cancelToken);
export const getBatchAllocationReport = (params, cancelToken) => apiGET(API_ENPOINTS.batch_allocation_report, params, cancelToken);
export const getTodaysActivity = (params) => apiGET(API_ENPOINTS.dashboard_todays_activity, params);
export const removeDataFromConsolidateMail = (params) => apiGET(API_ENPOINTS.dashboard_remove_data_from_consolidate_mail, params);
export const getUrgentDailyCampaign = (params) => apiGET(API_ENPOINTS.campaigns_urgent_daily_campaign, params);
export const removeUrgentDailyCampaign = (params) => apiPOST(API_ENPOINTS.campaigns_urgent_daily_campaign_delete, params);
export const preLitigationWhatsappChatsData = (params) => apiGET(API_ENPOINTS.pre_litigation_whatsapp_chats_data, params);
export const preLitigationAltWhatsappChatsData = (params) => apiGET(API_ENPOINTS.pre_litigation_alt_whatsapp_chats_data, params);
export const preLitigationConciliationIntentionHistory = (params) => apiGET(API_ENPOINTS.pre_litigation_concilation_intention_history, params);
export const preLitigationDownloadConciliationHearing = (params) => apiGET(API_ENPOINTS.pre_litigation_download_concilation_hearing, params);
export const preLitigationAxisCommunicationReport = (params) => apiGET(API_ENPOINTS.axis_communication_report, params);
export const getCampaignsCallRecord = (params) => apiGET(API_ENPOINTS.campaigns_call_record, params);
export const getCampaignsCallDetail = (params) => apiGET(API_ENPOINTS.campaigns_call_detail, params);
export const sendWaiverRequestEmailByAdmin = (params) => apiGET(API_ENPOINTS.send_waiver_request_email, params);
export const filterAdminActions = (params) => apiGET(API_ENPOINTS.filter_admin_action_data, params);
export const adminAgentTagsApi = (params) => apiGET(API_ENPOINTS.admin_agent_tags_api, params);
export const getUsers = (params) => apiGET(API_ENPOINTS.user_crud, params);
export const getCRONs = (params) => apiGET(API_ENPOINTS.crons, params);
export const getSettlementReport = (params) => apiGET(API_ENPOINTS.settlement_report, params);
export const getChatPanelReport = (params) => apiGET(API_ENPOINTS.chat_panel_report, params);

// ALL POST APIs
export const logoutUser = (body) => apiPOST(API_ENPOINTS.user_crud, body);
export const addCandidateInterviewInfo = (body) => apiPOST(API_ENPOINTS.dashboard_candidate_interview_info, body);
export const addGetGupshupAccountData = (body) => apiPOST(API_ENPOINTS.dashboard_get_gupshup_account_data, body);
export const addSendConsolidatedSetlRequestEmail = (body) => apiPOST(API_ENPOINTS.pre_litigation_send_consolidated_setl_request_email, body);
export const addSendExtractionDataEmailByAdmin = (body) => apiPOST(API_ENPOINTS.pre_litigation_send_extraction_data_email_by_admin, body);
export const addNewBorrowerPageAdminAction = (body) => apiPOST(API_ENPOINTS.pre_litigation_new_borrower_page_admin_action, body);
export const addMedia = (body) => apiPOST(API_ENPOINTS.dashboard_upload_media, body);
export const addGupshupTemplate = (body) => apiPOST(API_ENPOINTS.dashboard_gupshup_template, body);
export const addNewMisReport = (body) => apiPOST(API_ENPOINTS.dashboard_new_mis_report, body);
export const addSendMissingRetentionDataEmail = (body) => apiPOST(API_ENPOINTS.dashboard_send_missing_retention_data_email, body);
export const sendFirPoliceStatement = (body) => apiPOST(API_ENPOINTS.fir_send_police_statement, body);
export const uploadFirCopies = (body) => apiPOST(API_ENPOINTS.fir_upload_fir_copies, body);
export const addFirWorkingState = (body) => apiPOST(API_ENPOINTS.fir_working_state, body);
export const updateBatchCampaigningStatus = (body) => apiPOST(API_ENPOINTS.dashboard_update_batch_campaigning_status, body);
export const filterPaymentFile = (body, params, config) => apiPOST(API_ENPOINTS.filter_payment_file, body, params, config);
export const deleteCRON = (id) => apiPOST(`${API_ENPOINTS.crons}delete/${id}/`);
export const updateCRON = (id, body) => apiPOST(`${API_ENPOINTS.crons}${id}/`, body);
export const addCRON = (body) => apiPOST(`${API_ENPOINTS.crons}`, body);

// ALL DELETE APIs

// ALL PATCH APIs

// ALL PUT APIs
export const updateRtpExtractionDataEmailByAdmin = (noticeId, module = "", user) =>
  apiPOST(`${API_ENPOINTS.pre_litigation_send_extraction_data_email_by_admin_new}${noticeId}/`, { module, user });
export const updateFirWorkingState = (body) => apiPOST(API_ENPOINTS.fir_working_stateNew, body);

//
