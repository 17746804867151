import useClientStore from "../store/clientStore";
import useAuthStore from "../store/authStore";
import { createUpdateFileMappings, getFileMappings } from "../API/panelApis";
import { convertToMapValue } from "../utils/fileMappings";

export default function useMappings({ type = "" }) {
    // const fileMappings = useClientStore((state) => state.mappings);
    const removeMapping = useClientStore((state) => state.removeMapping);
    const addMapping = useClientStore((state) => state.addMapping);

    const fileMappingsObj = useClientStore((state) => state.mappingsObj);
    const addMappingObj = useClientStore((state) => state.addMappingObj);

    const userData = useAuthStore((state) => state.userData);

    // update mappings
    async function updateMappings(matchedHeaderNames) {
        // let mapping = { ...fileMappings };
        let mappingObj = { ...fileMappingsObj };

        for (const [key, value] of Object.entries(matchedHeaderNames)) {
            if (!!value && !!key) {
                // const oldKeyMapping = !!mapping?.[key] ? mapping?.[key] : [];
                // const textMapValue = convertToMapValue(value);

                // if (!oldKeyMapping?.find((el) => String(el) === textMapValue)) {
                //     mapping[key] = [...oldKeyMapping, textMapValue];
                // }

                mappingObj[key] = {
                    slug: convertToMapValue(value),
                    column: value,
                };
            }
        }

        const res = await createUpdateFileMappings({ client: userData?.client_id, type, mapping: mappingObj });

        if (!!res) {
            console.log("File Mapping Updated...");
            removeMapping();
        }
    }

    // fetch file mappings
    async function getMappings() {
        const res = await getFileMappings({ client: userData?.client_id, type });
        addMappingObj(!!res?.data?.mapping ? res?.data?.mapping : {});
        addMapping(convertToKeyMapping(res?.data?.mapping));
    }

    return { updateMappings, getMappings };
}

// convert slug mapping to column mapping for upload handler
function convertToKeyMapping(mapping) {
    if (!mapping) return {};

    /* convert slug format
        
        key1: {
            slug: "keyslug1",
            column: "Key Slug 123",
        }

        to

        key1: ["keyslug1"]

    */

    const newObj = {};
    for (const key in mapping) {
        // change here when notice and fir are also need to map only one type of column
        newObj[key] = !!mapping?.[key]?.slug ? [mapping?.[key]?.slug] : [];
    }

    return { ...newObj };
}
