import { Fragment, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { parseJson, snakeToNormal } from "../../../Agent/features/borrower-info/helpers";
import { getBorrowerReport } from "../../../apis";
import EmailHistory from "../../../Components/EmailHistory";
import ContentLoader from "../../../Components/ContentLoader";
import { IoCloseCircle } from "react-icons/io5";

export default function EmailResponse({ customerId, lan, batchStatus }) {
  const [isOpen, setIsOpen] = useState(false);
  const [showEmails, setShowEmails] = useState(false);

  // const userData = useAuthStore((state) => state.userData);
  // const customerId = useBorrowerInfo((state) => state.customerId);
  // const isLoading = useBorrowerInfo((state) => state.isAnalysisLoading);
  // const analysis = useBorrowerInfo((state) => state.analysis);

  const [analysis, setAnalysis] = useState(null);

  const fetchDetails = async () => {
    const params = {
      lan,
    };

    if (batchStatus === "completed") params.batch_type = batchStatus;

    const analysisResponse = await getBorrowerReport(params);
    if (analysisResponse.success) {
      setAnalysis(analysisResponse?.data?.data?.data[0] || {});
    }
  };

  useEffect(() => {
    if (isOpen) fetchDetails();
  }, [isOpen]);

  const toggle = () => setIsOpen((prev) => !prev);

  return (
    <>
      {/* <AnalysisCard
                title="Email Response"
                isLoading={isLoading}
                tag={analysis?.email_tag}
                content={getValueIfObject(analysis?.email_disposition, "scenario_name")}
                icon={FaEnvelope}
                onClick={toggle}
            /> */}
      <div className="d-flex align-items-center  justify-content-center" onClick={toggle}>
        <svg style={{ fill: "#0A7AFF" }} width="24" height="18" xmlns="http://www.w3.org/2000/svg">
          <g fillRule="nonzero">
            <path d="M0 0v12.96a4.32 4.32 0 0 0 4.32 4.32h14.4a4.32 4.32 0 0 0 4.32-4.32V0H0zm22.08 12.96a3.36 3.36 0 0 1-3.36 3.36H4.32a3.36 3.36 0 0 1-3.36-3.36v-12h21.12v12z" />
            <path d="m20.39 3.682-.643-.71-8.227 7.42-8.18-7.411-.642.71 8.822 7.997z" />
          </g>
        </svg>
      </div>

      <Offcanvas style={{ width: "28rem", paddingTop: "0.75rem" }} show={isOpen} onHide={toggle} backdropClassName="opacity-0" placement="end">
        <Offcanvas.Header>
          <Offcanvas.Title className="d-flex justify-content-between align-items-center w-100 px-2">
            <div className="fw-600 fs-4 text-black">Email </div>{" "}
            <button onClick={toggle} type="button" className="bg-white border-0">
              <IoCloseCircle fill="#999999" size={30} />
            </button>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="row border mx-3 my-2 p-1 rounded-1" style={{ boxShadow: "inset 0 0 10px #cbcacabd" }}>
            <a className="col-6 text-center p-2 fw-bold text-white rounded-3 fs-6" style={{ background: "#009EC1" }}>
              Summary
            </a>
            <a onClick={() => setShowEmails(true)} className="col-6 text-center p-2 fs-6 text-black tw-medium">
              Emails
            </a>
          </div>

          {/* Summary */}
          <div className="h-100">
            {!analysis ? (
              <div className="position-relative h-100">
                <ContentLoader />
              </div>
            ) : !analysis?.email_disposition ? (
              <p className="my-5 text-center text-black">Summary Not Found</p>
            ) : (
              <div className="m-3 mt-4 rounded-5 bg-body-tertiary px-2 py-3">
                {Object.entries(parseJson(analysis?.email_disposition))?.map(([key, value], i) => (
                  <Fragment key={i}>
                    {!!value && (
                      <p className="text-black px-3 mb-3">
                        <strong className="fs-5 fw-bold my-2 d-inline-block">{snakeToNormal(key)}</strong>
                        <br />
                        <span className="text-black fs-6">{value}</span>
                      </p>
                    )}
                  </Fragment>
                ))}
              </div>
            )}
          </div>

          <EmailHistory show={showEmails} setShow={setShowEmails} customerId={customerId} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
