import React, { useEffect, useMemo, useState } from "react";
import { RiCloseCircleFill } from "react-icons/ri";
import classes from "./style.module.css";
import ContentLoader from "../../../Components/ContentLoader";
import { Accordion } from "react-bootstrap";
import { format } from "date-fns";
import { getDisposition } from "../../../utils/dispositions";
import { fetchDispositionData } from "../../../API/agentApis";

const tabs = ["All", "Connected", "Not Connected"];

export default function DispositionHistory({ show, setShow = () => {}, customerMobileNo = null }) {
    const [activeTab, setActiveTab] = useState(0);

    const [data, setData] = useState([...Array(30).keys()]);
    const [loading, setLoading] = useState(false);

    const closeModal = () => {
        setShow(false);
    };

    const fetchDetails = async () => {
        if (!customerMobileNo) return;
        setLoading(true);

        setActiveTab(0);

        const res = await fetchDispositionData({ customer_mobile_number: customerMobileNo });
        if (!!res?.data?.length) setData(res?.data);
        else setData([]);
        setLoading(false);
    };

    useEffect(() => {
        if (!!customerMobileNo && !!show) fetchDetails();
    }, [customerMobileNo, show]);

    const filteredData = useMemo(() => {
        if (activeTab === 1) return data?.filter((el) => !!el?.is_connected_disposition);
        if (activeTab === 2) return data?.filter((el) => !el?.is_connected_disposition);

        return data;
    }, [data, activeTab]);

    return (
        <>
            <div onClick={() => closeModal()} className={`${classes.wrapper} ${show ? classes.show : classes.hidden}`} />

            <div className={`${classes.sidepanel} ${show ? classes.show : classes.hidden}`}>
                <div className={classes.sidepanel_header}>
                    <p>Disposition History {!!data?.length ? `(${data?.length})` : ""}</p>
                    <button onClick={() => closeModal()}>
                        <RiCloseCircleFill />
                    </button>
                </div>

                <div className={classes.tabs} style={{ gridTemplateColumns: `repeat(${tabs?.length},1fr)` }}>
                    {tabs?.map((tab, index) => (
                        <button key={index} onClick={() => setActiveTab(index)} className={activeTab === index ? classes.active_tab : classes.tab}>
                            {tab}
                        </button>
                    ))}

                    <span
                        style={{
                            left: `${(activeTab * 100) / tabs?.length}%`,
                            width: `${100 / tabs?.length}%`,
                        }}
                        className={classes.active_tab_indicator}
                    />
                </div>

                <div className={classes.scroll_content}>
                    {!!filteredData?.length && !loading && (
                        <div className={classes.list_wrapper}>
                            <Accordion defaultActiveKey="1" className="level-one">
                                {filteredData?.map((item, index) => (
                                    <DispItem data={item} eventKey={index + 1} key={index} />
                                ))}
                            </Accordion>
                        </div>
                    )}

                    {!filteredData?.length && !loading && (
                        <div>
                            <p style={{ textAlign: "center", padding: "20px" }}>No Disposition History found</p>
                        </div>
                    )}

                    {loading && <ContentLoader />}
                </div>
            </div>
        </>
    );
}

function DispItem({ data, eventKey }) {
    return (
        <Accordion.Item eventKey={eventKey} style={{ border: "none", paddingRight: "0.5rem" }}>
            <Accordion.Header className="p-0">
                <div className="disposition-row pb-3 w-100">
                    <div className="d-flex align-items-center gap-2 w-100">
                        <div>
                            <div className="dispo-date text-center" style={{ whiteSpace: "nowrap" }}>
                                {!!data?.contact_date ? format(new Date(data?.contact_date), "dd") : "DD"}
                                <br />
                                <span>{!!data?.contact_date ? format(new Date(data?.contact_date), "MMM yy") : "MMM YY"}</span>
                            </div>
                        </div>
                        <div>
                            <div className="dispo-note">
                                <p className="tx-15 text-black mb-1" style={{ fontWeight: "normal" }}>
                                    {!!data?.disposition ? getDisposition(data?.disposition) : ""}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Accordion.Header>
            <Accordion.Body className="p-0">
                <div className="dispositions-body grey-bg px-4 py-3">
                    <p className="m-0 p-0" style={{ lineHeight: "30px" }}>
                        {!!data?.paid_date && (
                            <>
                                <strong>Paid Date</strong> - {format(new Date(data?.paid_date), "dd-MM-yyyy")}
                                <br />
                            </>
                        )}
                        {(data?.paid_amount === 0 || !!data?.paid_amount) && (
                            <>
                                <strong>Paid Amount</strong> - {data?.paid_amount}
                                <br />
                            </>
                        )}
                        {!!data?.ptp_date && (
                            <>
                                <strong>PTP Date</strong> - {format(new Date(data?.ptp_date), "dd-MM-yyyy")}
                                <br />
                            </>
                        )}
                        {(data?.ptp_amount === 0 || !!data?.ptp_amount) && (
                            <>
                                <strong>PTP Amount</strong> - {data?.ptp_amount}
                                <br />
                            </>
                        )}
                        <strong>Reason of Disposition</strong> - {data?.disposition}
                        <br />
                        <strong>Agent</strong> - {data?.agent_name || "BOT"}
                        <br />
                        <strong>Channel</strong> - {data?.channel}
                        {!!data?.callback_date && (
                            <span>
                                <br />
                                <strong>Callback Date</strong> - {format(new Date(data?.callback_date), "dd-MM-yyyy")}
                            </span>
                        )}
                        {!!data?.remark && (
                            <span>
                                <br />
                                <strong>Remarks</strong> - {data?.remark}
                            </span>
                        )}
                    </p>
                </div>
            </Accordion.Body>
        </Accordion.Item>
    );
}
