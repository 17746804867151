import React, { useEffect, useMemo, useState } from "react";
import { RiCloseCircleFill } from "react-icons/ri";
import { FiChevronLeft } from "react-icons/fi";
import { format } from "date-fns";

import { getEmailReply, getMailHistory } from "../../API";
import ContentLoader from "../ContentLoader";
import classes from "./style.module.css";
import { maskEmail } from "../../Functions/mask";

export default function EmailHistory({ show, setShow = () => {}, customerId = "", isMasked = false }) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [selectedEmail, setSelectedEmail] = useState(null);
    const [showEmailReply, setShowEmailReply] = useState(false);
    const [emailReply, setEmailReply] = useState(null);
    const [replyLoading, setReplyLoading] = useState(false);
    const [showReplyOnly, setShowReplyOnly] = useState(false);

    const removeSelectedEmail = () => {
        setSelectedEmail(null);
    };

    const closeModal = () => {
        setShow(false);
        setReplyLoading(false);
        setShowReplyOnly(false);
        removeSelectedEmail();
    };

    const fetchEmailReply = async () => {
        if (!selectedEmail?.to_mail || !selectedEmail?.ticket) return;

        setReplyLoading(true);
        const res = await getEmailReply({
            mail: selectedEmail?.to_mail,
            ticket_id: selectedEmail?.ticket,
        });
        if (res?.data) {
            setEmailReply(res?.data?.reply);
        } else setEmailReply(null);

        setReplyLoading(false);
    };

    useEffect(() => {
        if (!!selectedEmail) fetchEmailReply();
        setShowEmailReply(false);
    }, [selectedEmail]);

    const fetchDetails = async () => {
        if (!customerId) return;
        setLoading(true);

        const res = await getMailHistory({
            customer_id: customerId,
            type: "prelitigation",
        });

        if (res) {
            setData(res?.data);
        }

        setLoading(false);
    };

    useEffect(() => {
        if (!!customerId && !!show) fetchDetails();
    }, [customerId, show]);

    const filterData = useMemo(() => {
        if (!showReplyOnly) return data;
        return data?.filter((item) => !!item?.ticket && item?.ticket !== "null");
    }, [data, showReplyOnly]);

    return (
        <>
            <div onClick={() => closeModal()} className={`${classes.wrapper} ${show ? classes.show : classes.hidden}`} />

            <div className={`${classes.sidepanel} ${show ? classes.show : classes.hidden}`}>
                <div className={classes.sidepanel_header}>
                    <div className="d-flex align-items-center gap-3">
                        <p>Email History</p>

                        <label style={{ cursor: "pointer" }} className="d-flex align-items-center gap-2">
                            <input type="checkbox" checked={showReplyOnly} onChange={(e) => setShowReplyOnly(e.target.checked)} />
                            <span style={{ userSelect: "none" }}>Show with Replies only</span>
                        </label>
                    </div>
                    <button onClick={() => closeModal()}>
                        <RiCloseCircleFill />
                    </button>
                </div>
                <div className={classes.scroll_content}>
                    {!!filterData?.length && !loading && (
                        <div className={classes.email_list_wrapper}>
                            {filterData?.map((item, index) => (
                                <div key={index} className={classes.email_wrapper} onClick={() => setSelectedEmail(item)}>
                                    <div className={classes.email_avatar}>{item?.from_mail?.slice(0, 1) || "M"}</div>
                                    <div className={classes.email_content}>
                                        <div className={classes.email_from_wrapper}>
                                            <p className={classes.email_from}>
                                                {item?.from_mail ?? "From"}
                                                <br />
                                                <small>To : {isMasked ? maskEmail(item?.to_mail) : item?.to_mail}</small>
                                            </p>
                                            <p className={classes.timestamp}>
                                                {!!item?.created_on ? format(new Date(item?.created_on), "do MMM yyyy") : "DD MM YYYY"}
                                            </p>
                                        </div>
                                        <p className={classes.subject}>{item?.subject}</p>
                                        {!!item?.ticket && item?.ticket !== "null" && (
                                            <p className={classes.reply_info}>
                                                Customer Replied
                                                <svg viewBox="0 0 18 14" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17.0008 14C16.7174 14 16.4801 13.904 16.2888 13.712C16.0968 13.5207 16.0008 13.2834 16.0008 13V10C16.0008 9.16669 15.7091 8.45836 15.1258 7.87502C14.5424 7.29169 13.8341 7.00002 13.0008 7.00002H3.80078L6.72578 9.92502C6.90911 10.1084 7.00078 10.3334 7.00078 10.6C7.00078 10.8667 6.90078 11.1 6.70078 11.3C6.51745 11.4834 6.28411 11.575 6.00078 11.575C5.71745 11.575 5.48411 11.4834 5.30078 11.3L0.700781 6.70002C0.600781 6.60002 0.530115 6.49169 0.488781 6.37502C0.446781 6.25836 0.425781 6.13336 0.425781 6.00002C0.425781 5.86669 0.446781 5.74169 0.488781 5.62502C0.530115 5.50836 0.600781 5.40002 0.700781 5.30002L5.32578 0.675025C5.50911 0.491691 5.73411 0.400024 6.00078 0.400024C6.26745 0.400024 6.50078 0.500024 6.70078 0.700024C6.88411 0.883357 6.97578 1.11669 6.97578 1.40002C6.97578 1.68336 6.88411 1.91669 6.70078 2.10002L3.80078 5.00002H13.0008C14.3841 5.00002 15.5634 5.48736 16.5388 6.46202C17.5134 7.43736 18.0008 8.61669 18.0008 10V13C18.0008 13.2834 17.9048 13.5207 17.7128 13.712C17.5214 13.904 17.2841 14 17.0008 14Z" />
                                                </svg>
                                            </p>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {!data?.length && !loading && (
                        <div>
                            <p style={{ textAlign: "center", padding: "20px" }}>No Email History found</p>
                        </div>
                    )}

                    {!!data?.length && !filterData?.length && !loading && (
                        <div>
                            <p style={{ textAlign: "center", padding: "20px" }}>No Email with a reply found.</p>
                        </div>
                    )}

                    {loading && <ContentLoader />}
                </div>
            </div>

            {/* Email Thread View */}
            <div onClick={() => removeSelectedEmail()} className={`${classes.wrapper} ${!!selectedEmail ? classes.show : classes.hidden}`} />

            <div className={`${classes.sidepanel} ${!!selectedEmail ? classes.show : classes.hidden}`}>
                <div className={classes.thread_header}>
                    <button className={classes.back_button} onClick={() => removeSelectedEmail()}>
                        <FiChevronLeft />
                    </button>

                    {!!selectedEmail?.ticket && selectedEmail?.ticket !== "null" && (
                        <button onClick={() => setShowEmailReply((prev) => !prev)} className={classes.reply_button}>
                            {!!showEmailReply ? "Hide Reply" : "View Reply"}
                            <svg viewBox="0 0 18 14" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.0008 14C16.7174 14 16.4801 13.904 16.2888 13.712C16.0968 13.5207 16.0008 13.2834 16.0008 13V10C16.0008 9.16669 15.7091 8.45836 15.1258 7.87502C14.5424 7.29169 13.8341 7.00002 13.0008 7.00002H3.80078L6.72578 9.92502C6.90911 10.1084 7.00078 10.3334 7.00078 10.6C7.00078 10.8667 6.90078 11.1 6.70078 11.3C6.51745 11.4834 6.28411 11.575 6.00078 11.575C5.71745 11.575 5.48411 11.4834 5.30078 11.3L0.700781 6.70002C0.600781 6.60002 0.530115 6.49169 0.488781 6.37502C0.446781 6.25836 0.425781 6.13336 0.425781 6.00002C0.425781 5.86669 0.446781 5.74169 0.488781 5.62502C0.530115 5.50836 0.600781 5.40002 0.700781 5.30002L5.32578 0.675025C5.50911 0.491691 5.73411 0.400024 6.00078 0.400024C6.26745 0.400024 6.50078 0.500024 6.70078 0.700024C6.88411 0.883357 6.97578 1.11669 6.97578 1.40002C6.97578 1.68336 6.88411 1.91669 6.70078 2.10002L3.80078 5.00002H13.0008C14.3841 5.00002 15.5634 5.48736 16.5388 6.46202C17.5134 7.43736 18.0008 8.61669 18.0008 10V13C18.0008 13.2834 17.9048 13.5207 17.7128 13.712C17.5214 13.904 17.2841 14 17.0008 14Z" />
                            </svg>
                        </button>
                    )}
                </div>

                <div className={classes.thread_scroll_content}>
                    <p className={classes.thread_subject}>{selectedEmail?.subject}</p>

                    {!!selectedEmail && (
                        <>
                            <div
                                onClick={() => (!!selectedEmail?.ticket && selectedEmail?.ticket !== "null" ? setShowEmailReply((prev) => !prev) : {})}
                                className={classes.thread_wrapper}
                            >
                                <div className={classes.email_avatar}>{selectedEmail?.from_mail?.slice(0, 1) || "M"}</div>
                                <div>
                                    <div className={classes.mail_id_timestamp}>
                                        <p className={classes.thread_email_from}>{selectedEmail?.from_mail || "From"}</p>
                                        <p className={classes.thread_timestamp}>
                                            {!!selectedEmail?.created_on ? format(new Date(selectedEmail?.created_on), "do MMM yyyy") : "DD MM YYYY"}
                                        </p>
                                    </div>
                                    <p className={classes.thread_email_from}>To: {isMasked ? maskEmail(selectedEmail?.to_mail) : selectedEmail?.to_mail}</p>
                                </div>
                            </div>

                            <div className={`${classes.email_body} ${showEmailReply && classes.hide_email_body}`}>
                                <iframe srcDoc={selectedEmail?.body} seamless="seamless">
                                    <p>{selectedEmail?.body}</p>
                                </iframe>
                            </div>

                            {/* Email Reply here */}
                            {!!selectedEmail?.ticket && selectedEmail?.ticket !== "null" && (
                                <>
                                    {replyLoading && <div className={classes.loading}>Loading...</div>}
                                    {!!emailReply && !replyLoading && (
                                        <>
                                            <div className={classes.reply_wrapper}>
                                                <div className={classes.reply_email_avatar}>{selectedEmail?.to_mail?.slice(0, 1) || "M"}</div>
                                                <div>
                                                    <p className={classes.thread_email_from}>
                                                        {!!selectedEmail?.to_mail
                                                            ? isMasked
                                                                ? maskEmail(selectedEmail?.to_mail)
                                                                : selectedEmail?.to_mail
                                                            : "Customer"}
                                                    </p>
                                                    <p className={classes.thread_timestamp}>Replied</p>
                                                </div>
                                            </div>

                                            <div className={classes.email_body}>
                                                <iframe srcDoc={emailReply} seamless="seamless">
                                                    <p>{emailReply}</p>
                                                </iframe>
                                            </div>
                                        </>
                                    )}
                                    {!emailReply && !replyLoading && (
                                        <div>
                                            <p style={{ textAlign: "center", padding: "20px" }}>No Reply Found</p>
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}

                    {!selectedEmail && (
                        <div>
                            <p style={{ textAlign: "center", padding: "20px" }}>No Email Selected</p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
