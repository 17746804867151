import React, { useRef, useState, useEffect } from "react";

function NotFound(props) {
    return (
        <>
            <div className="full-screen error-404 w-100 h-100">
                <div className="d-flex w-100 h-100 align-center justify-center">
                    <div className="w-100 text-center">
                        <svg width="563" height="551" xmlns="http://www.w3.org/2000/svg">
                            <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                                <g transform="translate(186.9 26.6)">
                                    <path
                                        d="M111.475 60.991c25.9-21.245 88.585-11.536 105.315 20.608 14.588 23.863 14.161 65.975-14.651 96.152-21.658 20.608-9.282 48.09-18.522 91.42-17.409 74.2-124.222 48.188-112.77-22.071 4.06-41.181 26.481-71.743 18.928-96.74-12.523-40.145 2.681-74.641 21.7-89.369z"
                                        fill="#D3D3D3"
                                        fillRule="nonzero"
                                    />
                                    <path
                                        d="M92.68 60.991c25.9-21.245 88.585-11.536 105.315 20.608 14.588 23.863 14.161 65.975-14.651 96.152-21.658 20.608-9.282 48.09-18.522 91.42-17.409 74.2-124.222 48.188-112.777-22.071 4.06-41.181 26.481-71.743 18.928-96.74-12.516-40.145 2.688-74.641 21.707-89.369z"
                                        stroke="#0E0E0E"
                                        strokeWidth="2.1"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M168.315 224.392c14.413 18.249 21.07 42.448 16.254 67.067-8.757 44.758-52.136 73.941-96.894 65.184-44.758-8.757-73.941-52.143-65.184-96.901 4.816-24.612 20.104-44.52 40.334-55.993"
                                        stroke="#0E0E0E"
                                        strokeWidth="2.1"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M166.866 224.105 64.288 204.036c-1.946-.378-3.227-2.289-2.849-4.235l1.505-7.707c.378-1.946 2.289-3.227 4.235-2.849l102.578 20.069c1.946.378 3.227 2.289 2.849 4.235l-1.505 7.707c-.385 1.946-2.289 3.234-4.235 2.849z"
                                        stroke="#0E0E0E"
                                        strokeWidth="2.1"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="m152.047 296.352 14.308 28.791-33.425-15.68c7.28-3.262 14.777-9.296 19.117-13.111z"
                                        fill="#ABABAB"
                                        fillRule="nonzero"
                                    />
                                    <path
                                        d="m151.473 296.016 14.749 27.65c.791 1.526-.826 3.178-2.366 2.408l-34.062-15.337M134.946 308.322l30.975 14.63"
                                        stroke="#0E0E0E"
                                        strokeWidth="2.1"
                                    />
                                    <path
                                        d="m54.887 277.34-23.926 20.349 36.687-1.001c-5.502-5.761-10.185-14.175-12.761-19.348z"
                                        fill="#ABABAB"
                                        fillRule="nonzero"
                                    />
                                    <path
                                        d="m55.16 277.172-24.087 20.055c-1.309 1.113-.434 3.255 1.281 3.129l37.772-1.666M66.22 294.875l-34.209 1.876M141.449 146.027l-33.082-38.843c-3.458-4.69.196-13.783 4.886-17.241s16.289-3.745 19.74.945l25.501 42.574c3.458 4.69 2.45 11.354-2.24 14.805-4.683 3.458-11.347 2.45-14.805-2.24z"
                                        stroke="#0E0E0E"
                                        strokeWidth="2.1"
                                    />
                                    <path
                                        d="m131.229 126.469-10.122-14.364c-2.233-3.171-1.47-7.602 1.701-9.835 3.171-2.233 7.602-1.47 9.835 1.701l10.122 14.364c2.233 3.171 1.47 7.602-1.701 9.835-3.171 2.24-7.602 1.477-9.835-1.701z"
                                        fill="#0E0E0E"
                                        fillRule="nonzero"
                                    />
                                    <path
                                        d="m154.679 136.381-2.835-1.211a1.548 1.548 0 0 1-.812-2.023 1.548 1.548 0 0 1 2.023-.812l2.835 1.211a1.548 1.548 0 0 1 .812 2.023 1.542 1.542 0 0 1-2.023.812zM145.474 140.161l-.189 3.08a1.547 1.547 0 0 1-1.638 1.442 1.547 1.547 0 0 1-1.442-1.638l.189-3.08a1.547 1.547 0 0 1 1.638-1.442 1.548 1.548 0 0 1 1.442 1.638zM148.89 137.893a1.517 1.517 0 0 1 2.1.357l1.743 2.457a1.517 1.517 0 0 1-.357 2.1 1.517 1.517 0 0 1-2.1-.357l-1.743-2.457a1.505 1.505 0 0 1 .357-2.1zM203.595 93.751c4.053-1.491 8.526-1.855 11.144-.014l37.688 32.284c4.767 3.346 5.922 9.989 2.576 14.749-3.346 4.767-9.989 5.922-14.749 2.576l-32.977-19.88"
                                        stroke="#0E0E0E"
                                        strokeWidth="2.1"
                                    />
                                    <path
                                        d="m224.357 128.044-14.154-10.409c-3.129-2.303-3.801-6.741-1.505-9.87 2.303-3.129 6.741-3.801 9.87-1.505l14.154 10.409c3.129 2.303 3.801 6.741 1.505 9.87-2.303 3.129-6.741 3.801-9.87 1.505z"
                                        fill="#0E0E0E"
                                        fillRule="nonzero"
                                    />
                                    <path
                                        d="m249.739 130.004-3.073-.245a1.547 1.547 0 0 1-1.414-1.659 1.547 1.547 0 0 1 1.659-1.414l3.073.245c.847.07 1.484.819 1.414 1.659a1.54 1.54 0 0 1-1.659 1.414zM242.207 136.5l.798 2.982a1.553 1.553 0 0 1-1.092 1.89 1.553 1.553 0 0 1-1.89-1.092l-.798-2.982a1.553 1.553 0 0 1 1.092-1.89 1.55 1.55 0 0 1 1.89 1.092zM244.727 133.273a1.522 1.522 0 0 1 2.107-.329l2.429 1.778c.665.49.812 1.435.329 2.107a1.522 1.522 0 0 1-2.107.329l-2.429-1.778a1.51 1.51 0 0 1-.329-2.107z"
                                        stroke="#0E0E0E"
                                        strokeWidth="2.1"
                                    />
                                    <path
                                        d="M155.19 269.675a85.244 85.244 0 0 1 16.632 6.426M129.521 266.651a98.154 98.154 0 0 1 6.755.091M157.339 262.255c8.358 1.246 16.296 3.703 23.87 7.224M130.431 262.395a99.422 99.422 0 0 1 5.026-.623M157.542 253.645c8.911-.049 17.57 1.316 26.005 3.892M130.942 257.894c1.351-.42 2.688-.805 4.025-1.162M62.524 251.51a85.045 85.045 0 0 0-18.326-.378M86.947 258.321a103.708 103.708 0 0 0-5.803-2.289M61.005 243.334c-7.609-1.673-15.281-2.261-22.995-1.883M87.71 254.037a105.834 105.834 0 0 0-4.872-2.709M61.719 234.115c-8.554-3.017-17.402-4.585-26.509-4.928M88.998 249.494a96.26 96.26 0 0 0-5.432-4.109"
                                        stroke="#0E0E0E"
                                        strokeWidth="2.1"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M71.561 207.529c-8.554 14.469-12.551 29.372-8.806 44.989 3.437 10.857 14.098 9.702 18.095 3.542 6.055-8.435-1.064-28.987 8.358-42.721M145.376 221.97c-8.554 14.469-12.551 29.372-8.806 44.989 3.437 10.857 14.098 9.702 18.095 3.542 6.055-8.435-1.064-28.987 8.358-42.721"
                                        stroke="#0E0E0E"
                                        strokeWidth="2.1"
                                        strokeLinecap="round"
                                    />
                                    <circle stroke="#0E0E0E" strokeWidth="2.1" cx="124.635" cy="289.856" r="10.976" />
                                    <circle fill="#231F20" fillRule="nonzero" cx="124.635" cy="289.856" r="7.889" />
                                    <path
                                        d="m112.294 271.327-6.405 4.312a1.002 1.002 0 0 1-1.386-.273l-4.312-6.405a1.002 1.002 0 0 1 .273-1.386l12.11 2.366a1.01 1.01 0 0 1-.28 1.386z"
                                        fill="#231F20"
                                        fillRule="nonzero"
                                    />
                                    <path
                                        stroke="#0E0E0E"
                                        strokeWidth="2.1"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="m118.139 247.415-7.966 2.632-6.09-6.713"
                                    />
                                    <circle fill="#FFF" fillRule="nonzero" cx="127.155" cy="294.497" r="1.848" />
                                    <circle stroke="#0E0E0E" strokeWidth="2.1" cx="81.816" cy="281.477" r="10.976" />
                                    <circle fill="#231F20" fillRule="nonzero" cx="81.816" cy="281.477" r="7.889" />
                                    <circle fill="#FFF" fillRule="nonzero" cx="84.336" cy="286.118" r="1.848" />
                                    <path
                                        d="M94.409 56.462c-1.519-5.173-4.718-10.206-9.982-14.658-15.911-11.508-30.765-21.77-44.968-26.285-19.6-6.713-39.445 6.524-20.573 28.847 8.008 8.022 16.058-1.946 21.385-3.808 4.606-2.548 7.294 2.912 5.355 9.674-4.739 11.984-20.776 15.33-31.976 5.054C-8.19 31.514-3.073-2.751 32.165.497c20.986 2.268 44.023 21.343 66.57 39.347 3.451 3.122 5.971 7.364 7.42 12.201"
                                        fill="#D3D3D3"
                                        fillRule="nonzero"
                                    />
                                    <path
                                        d="M99.596 56.462c-1.519-5.173-4.718-10.206-9.982-14.658-15.911-11.508-30.765-21.77-44.968-26.285-19.6-6.713-39.445 6.524-20.573 28.847 8.008 8.022 16.058-1.946 21.385-3.808 4.606-2.548 7.294 2.912 5.355 9.674-4.739 11.984-20.776 15.33-31.976 5.054C-3.003 31.514 2.114-2.751 37.352.497c20.986 2.268 44.023 21.343 66.57 39.347 3.451 3.122 5.971 7.364 7.42 12.201"
                                        stroke="#0E0E0E"
                                        strokeWidth="2.1"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </g>
                                <text fill="#0E0E0E" fillRule="nonzero" fontFamily="Helvetica" fontSize="223.105">
                                    <tspan x=".059" y="393.768">
                                        4
                                    </tspan>
                                </text>
                                <text fill="#0E0E0E" fillRule="nonzero" fontFamily="Helvetica" fontSize="223.105">
                                    <tspan x="438.206" y="393.768">
                                        4
                                    </tspan>
                                </text>
                                <circle fill="#E2E2E2" fillRule="nonzero" cx="41.916" cy="129.514" r="10.423" />
                                <circle fill="#E2E2E2" fillRule="nonzero" cx="23.443" cy="161.812" r="4.55" />
                                <circle fill="#E2E2E2" fillRule="nonzero" cx="106.568" cy="166.537" r="29.575" />
                                <circle fill="#E2E2E2" fillRule="nonzero" cx="406.77" cy="491.939" r="6.377" />
                                <circle fill="#E2E2E2" fillRule="nonzero" cx="357.868" cy="498.337" r="26.775" />
                                <circle fill="#E2E2E2" fillRule="nonzero" cx="404.593" cy="534.562" r="4.2" />
                                <path
                                    stroke="#0E0E0E"
                                    strokeWidth="2.1"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m490.693 17.962 8.4-17.339M471.093 16.562 465.437.623M494.893 40.362l8.4-1.4M311.535 506.562l-21.098 11.2M317.331 525.112l-5.796 23.45M317.331 485.562l-13.685-14"
                                />
                            </g>
                        </svg>
                        <h1>404 Error</h1>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NotFound;
