import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { sendPaymentLinkApi } from "../../../API/agentApis";
import { copyTextToClipboard } from "../../../Functions/copyToClipboard";
import { isEmpty } from "../../../Functions/isEmpty";
import useAuthStore from "../../../store/authStore";
import useGeneralStore from "../../../store/generalStore";
import { maskEmail, maskMobileNumber } from "../../../Functions/mask";

const PaymentLinkModal = ({ data }) => {
    const userData = useAuthStore((state) => state.userData);

    const [show, setShow] = useState(false);

    const [mobileNumber, setmobileNumber] = useState([{ number: "" }]);
    const [emailArr, setEmail] = useState([{ email: "" }]);
    const [sendingLink, setSendingLink] = useState(false);
    const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);
    const showAlert = useGeneralStore((state) => state.open_alert);

    const [amount, setAmount] = useState(0);
    const [emailOption, setEmailOption] = useState(false);
    const [whataApp, setWhatsApp] = useState(false);
    const [sms, setSms] = useState(false);

    const closeModal = () => {
        setmobileNumber([{ number: "" }]);
        setWhatsApp(false);
        setEmailOption(false);
        setSms(false);
        setShow(false);
        setAmount(0);
    };

    const handleAdd = () => {
        setmobileNumber([...mobileNumber, { number: "" }]);
    };

    const handleRemove = (i) => {
        let formValues = [...mobileNumber];
        formValues.splice(i, 1);
        setmobileNumber(formValues);
    };

    const handleNumberInfo = (number, index) => {
        let dataTemp = [...mobileNumber];
        dataTemp[index]["number"] = number;
        setmobileNumber(dataTemp);
    };

    const handleAddEmail = () => {
        setEmail([...emailArr, { email: "" }]);
    };

    const handleRemoveEmail = (i) => {
        let formValues = [...emailArr];
        formValues.splice(i, 1);
        setEmail(formValues);
    };

    const handleEmailInfo = (email, index) => {
        let dataTemp = [...emailArr];
        dataTemp[index]["email"] = email;
        setEmail(dataTemp);
    };

    const sendPaymentlink = async () => {
        setSendingLink(true);

        let communicationMode = [];

        let mobNumber = mobileNumber.map((item) => item.number);
        let EmailArray = emailArr.map((item) => item.email);
        if (whataApp) communicationMode.push("whatsapp");
        if (sms) communicationMode.push("sms");
        if (emailOption) communicationMode.push("email");

        let body = {
            mobile_number: mobNumber,
            emails: EmailArray,
            amount: amount,
            agent: userData?.user_id,
            client_id: data?.client_id,
            communication_mode: communicationMode,
        };
      
        let res = await sendPaymentLinkApi(body);
        if (res) {
            showAlert({
                variant: res?.variant === "yellow" ? "warning" : res?.variant === "red" ? "danger" : res?.variant === "green" ? "success" : "info",
                msg: res?.message,
            });
            closeModal();
        }
        // if (res && res.message === "Sent Successfully") {
        //     showAlert({ variant: "info", msg: res.message });
        //     closeModal();
        // } else if (res && res.message.info) {
        //     showAlert({ variant: "danger", msg: res.message.info });
        // }
        setSendingLink(false);
    };

    useEffect(() => {
        setAmount(!isEmpty(data?.data?.pos) ? data?.data?.pos : data?.data?.emi);
        setmobileNumber([{ number: data?.data?.customer_mobile_number }]);
        setEmail([{ email: data?.data?.email }]);
    }, [data, show]);

    useEffect(() => {
        if (!!show) {
            setWhatsApp(true);
            setSms(true);
            setEmailOption(true);
        }
    }, [show]);


    return (
        <>
            <a className="dropdown-item" onClick={() => setShow(true)}>
                Payment Link
            </a>

            <Modal show={show} onHide={closeModal} dialogClassName="modal-sendpayment">
                <Modal.Header>
                    <Modal.Title>Send Payment Link</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="mb-1 mt-2 row modal-search">
                            <div
                                className="col-sm-12 copy-link"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    !isEmpty(data?.data?.payment_link) &&
                                        copyTextToClipboard(data?.data?.payment_link)
                                            .then(() => {
                                                setIsCopiedToClipboard(true);
                                                setTimeout(() => {
                                                    setIsCopiedToClipboard(false);
                                                }, 1500);
                                            })
                                            .catch((err) => {
                                                console.log(err);
                                            });
                                }}
                            >
                                <input
                                    type="text"
                                    className="form-control"
                                    id="link"
                                    placeholder="TVS.Payemi.net/MH1234567890"
                                    value={data?.data?.payment_link}
                                    style={{ cursor: "pointer" }}
                                    readOnly
                                />
                                <div className="mdl-link">
                                    <svg width="23" height="23" xmlns="http://www.w3.org/2000/svg">
                                        <g fill="#727272" fillRule="evenodd">
                                            <path d="M9.245 12.32a.7.7 0 1 1 1.121-.838 2.802 2.802 0 0 0 4.225.302l2.093-2.091a2.8 2.8 0 0 0-3.954-3.967l-1.205 1.197a.7.7 0 0 1-.988-.993l1.212-1.204a4.202 4.202 0 0 1 5.934 5.948l-2.101 2.1a4.203 4.203 0 0 1-6.337-.453z" />
                                            <path d="M13.168 10.082a.7.7 0 1 1-1.122.839 2.802 2.802 0 0 0-4.225-.303L5.73 12.71a2.8 2.8 0 0 0 .034 3.925 2.8 2.8 0 0 0 3.919.043l1.197-1.197a.7.7 0 0 1 .99.99l-1.206 1.206a4.202 4.202 0 0 1-5.934-5.948l2.102-2.1a4.203 4.203 0 0 1 6.337.453z" />
                                        </g>
                                    </svg>
                                </div>
                                <div className="link-copy">
                                    <svg width="15" height="16" xmlns="http://www.w3.org/2000/svg">
                                        <g fill="#0A7AFF" fillRule="nonzero">
                                            <path d="M5.6 12.48c-1.5 0-2.72-1.22-2.72-2.72V3.2H1.76C.79 3.2 0 3.99 0 4.96v8.64c0 .97.79 1.76 1.76 1.76h8c.97 0 1.76-.79 1.76-1.76v-1.12H5.6z" />
                                            <path d="M14.08 1.76A1.76 1.76 0 0 0 12.32 0H5.6a1.76 1.76 0 0 0-1.76 1.76v8c0 .972.788 1.76 1.76 1.76h6.72a1.76 1.76 0 0 0 1.76-1.76v-8z" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <p
                                style={{ opacity: isCopiedToClipboard ? "1" : "0", transition: "all ease-in-out 200ms" }}
                                className="float-right text-end tx-12 mt-2 mb-0"
                            >
                                Copied !
                            </p>
                        </div>
                        <div style={{ marginTop: "-0.7rem" }}>
                            <div className="mb-2 row m-number">
                                <label htmlFor="amount" className="col-lg-12 col-form-label">
                                    Amount
                                </label>
                                <div className="col-8 pe-0">
                                    <input
                                        name="amount"
                                        type="number"
                                        className="form-control"
                                        min={0}
                                        value={amount}
                                        onChange={(e) => {
                                            setAmount(e?.target?.value);
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="mb-2 row m-number">
                                <label htmlFor="number" className="col-lg-12 col-form-label">
                                    Mobile Number #1
                                </label>
                                <div className="col-8 pe-0 me-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={!!userData?.remove_masking ? mobileNumber[0]?.number : maskMobileNumber(mobileNumber[0]?.number)}
                                        readOnly
                                    />
                                </div>

                                {mobileNumber.length === 1 && (
                                    <Button variant="light" className="col-2" onClick={handleAdd}>
                                        +Add
                                    </Button>
                                )}
                            </div>

                            {mobileNumber.slice(1).map((item, i) => (
                                <div className="mb-2 row m-number" key={i}>
                                    <label htmlFor="number" className="col-lg-12 col-form-label">
                                        Mobile Number {`#${i + 2}`}
                                    </label>
                                    <div className="col-8 pe-0">
                                        <input
                                            name={i + 1}
                                            type="text"
                                            className="form-control"
                                            value={item.number}
                                            onChange={(e) => {
                                                handleNumberInfo(e?.target?.value, i + 1);
                                            }}
                                        />
                                    </div>

                                    <Button variant="light" className="col-1 mx-2" onClick={() => handleRemove(i + 1)}>
                                        -
                                    </Button>
                                    {i === mobileNumber.length - 2 && (
                                        <Button variant="light" className="col-2" onClick={handleAdd}>
                                            +Add
                                        </Button>
                                    )}
                                </div>
                            ))}


                            {/* Email */}
                            <div className="mb-2 row m-email">
                                <label htmlFor="email" className="col-lg-12 col-form-label">
                                    Email #1
                                </label>
                                <div className="col-8 pe-0 me-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={!!userData?.remove_masking ? emailArr[0]?.email : maskEmail(emailArr[0]?.email)}
                                        readOnly={!!userData?.remove_masking ? false : true}
                                    />
                                </div>

                                {emailArr.length === 1 && (
                                    <Button variant="light" className="col-2" onClick={handleAddEmail}>
                                        +Add
                                    </Button>
                                )}
                            </div>

                            {emailArr.slice(1).map((item, i) => (
                                <div className="mb-2 row m-email" key={i}>
                                    <label htmlFor="email" className="col-lg-12 col-form-label">
                                        Email {`#${i + 2}`}
                                    </label>
                                    <div className="col-8 pe-0">
                                        <input
                                            name={i + 1}
                                            type="text"
                                            className="form-control"
                                            value={item.email}
                                            onChange={(e) => {
                                                handleEmailInfo(e?.target?.value, i + 1);
                                            }}
                                        />
                                    </div>

                                    <Button variant="light" className="col-1 mx-2" onClick={() => handleRemoveEmail(i + 1)}>
                                        -
                                    </Button>
                                    {i === emailArr.length - 2 && (
                                        <Button variant="light" className="col-2" onClick={handleAddEmail}>
                                            +Add
                                        </Button>
                                    )}
                                </div>
                            ))}
                        </div>

                        <div className="mb-4 row">
                            <label htmlFor="number" className="col-lg-12 col-form-label pb-0">
                                Communication Mode
                            </label>
                            <div className="col-12 d-flex items-center justify-content-start gap-4 mt-1">
                                <div className="d-flex">
                                    <label className="col-form-label pt-0">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={whataApp}
                                            onChange={(e) => setWhatsApp(e.target.checked)}
                                        />{" "}
                                        WhatsApp
                                    </label>
                                </div>

                                <div>
                                    <label className="col-form-label pt-0">
                                        <input type="checkbox" className="form-check-input" checked={sms} onChange={(e) => setSms(e.target.checked)} /> SMS
                                    </label>
                                </div>
                                <div>
                                    <label className="col-form-label pt-0">
                                        <input type="checkbox" className="form-check-input" checked={emailOption} onChange={(e) => setEmailOption(e.target.checked)} /> Email
                                    </label>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={sendPaymentlink}
                        disabled={sendingLink || !amount || mobileNumber[0].number === "" || (!whataApp && !sms)}
                    >
                        {sendingLink ? "Sending..." : "Send Payment Link"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PaymentLinkModal;
