import React from "react";

function PaidDispo() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="26" viewBox="0 0 35 26">
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <g>
                                <g>
                                    <g fill="#00BF9D" fillRule="nonzero">
                                        <path
                                            d="M28.8 0H3.6v3.6H0v15.6h25.2v-3.6h3.6V0zM24 18H1.2V4.8H24V18zm3.6-3.6h-2.4V3.6H4.8V1.2h22.8v13.2z"
                                            transform="translate(-1072.000000, -468.000000) translate(1065.000000, 293.000000) translate(0.000000, 48.000000) translate(0.000000, 116.000000) translate(7.000000, 11.000000)"
                                        />
                                        <path
                                            d="M12.6 15.6c2.32 0 4.2-1.88 4.2-4.2 0-2.32-1.88-4.2-4.2-4.2-2.32 0-4.2 1.88-4.2 4.2 0 2.32 1.88 4.2 4.2 4.2zm0-7.2c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3z"
                                            transform="translate(-1072.000000, -468.000000) translate(1065.000000, 293.000000) translate(0.000000, 48.000000) translate(0.000000, 116.000000) translate(7.000000, 11.000000)"
                                        />
                                    </g>
                                    <g>
                                        <path
                                            fill="#FFF"
                                            d="M2.4 4.8H12V10.8H2.4z"
                                            transform="translate(-1072.000000, -468.000000) translate(1065.000000, 293.000000) translate(0.000000, 48.000000) translate(0.000000, 116.000000) translate(7.000000, 11.000000) translate(20.000000, 11.000000)"
                                        />
                                        <path
                                            fill="#00BF9D"
                                            fillRule="nonzero"
                                            d="M7.2 0C3.23 0 0 3.23 0 7.2s3.23 7.2 7.2 7.2 7.2-3.23 7.2-7.2S11.17 0 7.2 0zm3.445 5.927l-3.709 3.71c-.175.175-.406.263-.636.263-.23 0-.46-.088-.636-.264l-1.91-1.909c-.35-.351-.35-.92 0-1.272.352-.352.922-.352 1.273 0L6.3 7.727l3.073-3.072c.351-.352.92-.352 1.272 0 .352.351.352.92 0 1.272z"
                                            transform="translate(-1072.000000, -468.000000) translate(1065.000000, 293.000000) translate(0.000000, 48.000000) translate(0.000000, 116.000000) translate(7.000000, 11.000000) translate(20.000000, 11.000000)"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default PaidDispo;
