import React from "react";
import { limits } from "../../hooks/usePagination";
import Paging from "./Paging";

export default function Pagination({ currentPage, pageTotal, handlePagination, limitPerPage, handleLimitPerPage, customLimit = false }) {
  return (
    <div className="my-2 d-flex align-items-start justify-content-between flex-wrap gap-4">
      {customLimit ? (
        <div className="d-flex align-items-center justify-content-start gap-2">
          <span style={{ whiteSpace: "nowrap" }}>Items per page</span>
          <select value={limitPerPage} onChange={(e) => handleLimitPerPage(e.target.value)} className="form-select pe-5 py-2">
            {limits.map((el, index) => (
              <option value={el} key={index}>
                {el}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <div />
      )}

      <Paging currentPage={currentPage} handlePagination={handlePagination} pageTotal={pageTotal} />
    </div>
  );
}
