import { Fragment } from "react";
import { commaFormat } from "../../../../Functions/formatNumber";
import { isEmpty } from "../../../../Functions/isEmpty";
import useAuthStore from "../../../../store/authStore";
import { checkValidNumber, roundNum } from "../helpers";
import useBorrowerInfo from "../store";
import { maskString } from "../../../../Functions/mask";

export default function LoanInfo() {
  const data = useBorrowerInfo((state) => state.data);
  const userData = useAuthStore((state) => state.userData);
  const borrowerDetails = data?.data;
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("en-GB"); 
  };
console.log(borrowerDetails)
  return (
    <>
      <div className="px-2">
        <p className="fw-bold text-black tx-16 mb-2">Loan Outstanding</p>

        {[
          ["Total Outstanding", checkValidNumber(borrowerDetails?.pos) ? `Rs. ${roundNum(borrowerDetails?.pos)}` : ""],
          ["POS", checkValidNumber(borrowerDetails?.principal_outstanding) ? `Rs. ${roundNum(borrowerDetails?.principal_outstanding)}` : ""],
          ["Due Since", !isEmpty(borrowerDetails?.due_date) ? formatDate(borrowerDetails?.due_date) : ""],
          [
            "Final Settlement Amount",
            !isEmpty(borrowerDetails?.new_settlement_amount)
              ? `Rs. ${commaFormat(parseInt(borrowerDetails?.new_settlement_amount))}`
              : !isEmpty(borrowerDetails?.pre_approved_sattlement_amount)
              ? `Rs. ${commaFormat(parseInt(borrowerDetails?.pre_approved_sattlement_amount))}`
              : "",
          ],
          [
            "EMI",
            !isEmpty(borrowerDetails?.minimum_due)
              ? `Rs. ${commaFormat(parseInt(borrowerDetails?.minimum_due))}`
              : `Rs. ${commaFormat(parseInt(borrowerDetails?.emi))}`,
          ],
          ["Email", !isEmpty(borrowerDetails?.email) ? `${borrowerDetails?.email}` : ""],
          ["Bucket", !isEmpty(borrowerDetails?.current_bucket) ? borrowerDetails?.current_bucket : ""],

          ["Last Paid Date", !isEmpty(borrowerDetails?.last_paid_date) ? borrowerDetails?.last_paid_date : ""],
          ["Last Paid Amount", !isEmpty(borrowerDetails?.last_paid_amount) ? `Rs. ${commaFormat(borrowerDetails?.last_paid_amount)}` : ""],
          ["Interest", !isEmpty(borrowerDetails?.interest) ? `Rs. ${commaFormat(borrowerDetails?.interest)}` : ""],
          ["Penalty", !isEmpty(borrowerDetails?.
            penalilty_overdue) ? `Rs. ${commaFormat(borrowerDetails?.
              penalilty_overdue)}` : ""],
        ].map(([label, value], index) => (
          <Fragment key={index}>
            {!!value && (
              <p className="d-flex justify-content-between gap-3 mb-2">
                <span>{label}</span>
                <span className="text-black fw-medium">{value}</span>
              </p>
            )}
          </Fragment>
        ))}
      </div>

      <div className="h-0 w-full my-3" style={{ borderTop: "1px dashed #ddd" }} />

      <div className="px-2">
        <p className="fw-bold text-black tx-16 mb-2">Loan Details</p>
        {[
          [("Total Loan Amount", !isEmpty(borrowerDetails?.loan_amount) ? `Rs. ${commaFormat(borrowerDetails?.loan_amount)}` : "")],
          ["Loan Tenure", !isEmpty(borrowerDetails?.tenor) ? borrowerDetails?.tenor : ""],
          ["Disbursal Date", !isEmpty(borrowerDetails?.disbursal_date) ? borrowerDetails?.disbursal_date : ""],
          ["Loan Taken For (Product)", !isEmpty(borrowerDetails?.product_name) ? borrowerDetails?.product_name : ""],
          ["LAN", !isEmpty(userData?.remove_masking) ? borrowerDetails?.loan_account_no : maskString(borrowerDetails?.loan_account_no)],
          ["Co-lender", !isEmpty(borrowerDetails?.lender_name) ? borrowerDetails?.lender_name : ""],
          [
            "Credit Card No.",
            !isEmpty(borrowerDetails?.card_no) ? (!!userData?.remove_masking ? borrowerDetails?.card_no : maskString(borrowerDetails?.card_no)) : "",
          ],
          ["Region", !isEmpty(borrowerDetails?.region_name) ? borrowerDetails?.region_name : ""],
          ["Zone", !isEmpty(borrowerDetails?.zone_name) ? borrowerDetails?.zone_name : ""],
          ["Bike", !isEmpty(borrowerDetails?.product_name === "TW" || borrowerDetails?.product_name === "Two Wheeler") ? borrowerDetails?.model : ""],
          [
            "Registration",
            !isEmpty(borrowerDetails?.product_name) &&
            (borrowerDetails.product_name === "TW" || borrowerDetails.product_name === "Two Wheeler") &&
            borrowerDetails?.reg_number !== "None"
              ? borrowerDetails?.reg_number
              : "",
          ],
        ].map(([label, value], index) => (
          <Fragment key={index}>
            {!!value && (
              <p className="d-flex justify-content-between gap-3 mb-2">
                <span style={{ minWidth: "max-content", display: "inline-block" }}>{label}</span>
                <span className="text-black fw-medium">{value}</span>
              </p>
            )}
          </Fragment>
        ))}
      </div>

      <div className="h-0 w-full my-3" style={{ borderTop: "1px dashed #ddd" }} />

      <div className="px-2">
        <p className="fw-bold text-black tx-16 mb-2">Loan History</p>
        {[
          ["POS", !isEmpty(borrowerDetails?.principal_outstanding) ? `Rs. ${roundNum(borrowerDetails?.principal_outstanding)}` : ""],
          ["Interest", !isEmpty(borrowerDetails?.interest_outstanding) ? `Rs. ${commaFormat(borrowerDetails?.interest_outstanding)}` : ""],
          ["Penalty", !isEmpty(borrowerDetails?.penalilty_overdue) ? `Rs. ${commaFormat(borrowerDetails?.penalilty_overdue)}` : ""],
        ].map(([label, value], index) => (
          <Fragment key={index}>
            {!!value && (
              <p className="d-flex justify-content-between gap-3 mb-2">
                <span>{label}</span>
                <span className="text-black fw-medium">{value}</span>
              </p>
            )}
          </Fragment>
        ))}
      </div>
    </>
  );
}
