import { create } from "zustand";
import { devtools } from "zustand/middleware";

const store = (set) => ({
    // sidebar
    is_sidebar_open: true,
    toggle_sidebar: () => set((state) => ({ is_sidebar_open: !state.is_sidebar_open })),

    // total result fetch count
    total_result_fetched: 0,
    update_fetched_count: (newCount) => set(() => ({ total_result_fetched: newCount })),

    // alert Msg
    alert_msg: "",
    alert_variant: "",
    show_alert: false,
    persist_alert: false,
    open_alert: ({ msg, variant, persist = false }) => set(() => ({ alert_msg: msg, alert_variant: variant, show_alert: true, persist_alert: persist })),
    close_alert: () => set(() => ({ alert_msg: "", alert_variant: "", show_alert: false, persist_alert: false })),
});

const useGeneralStore = create(devtools(store));

export default useGeneralStore;
