import React, { useEffect, useState } from "react";
import SelectDropdown from "../../ui/forms/SelectDropdown";

const repeatedColumns = [
    { label: "Status", value: "status" },
    { label: "Product", value: "product" },
    { label: "Account Number", value: "account_number" },
    { label: "Total Outstanding", value: "total_outstanding" },
];

export default function MappingRepeatedCols({ onChange = () => {}, columns = [] }) {
    const [data, setData] = useState({});

    useEffect(() => {
        onChange(convertArr(Object.values(data)));
    }, [data]);

    useEffect(() => {
        setData(
            repeatedColumns?.reduce((acc, { value }) => {
                acc[value] = { key: value, column: "", count: "" };
                return acc;
            }, {})
        );
    }, []);

    return (
        <div className="mt-5">
            <p className="mb-2 tx-18 text-center">
                <strong style={{ textDecoration: "underline" }}>Repeated Columns</strong>
            </p>

            <div style={{ height: "100%", maxHeight: "300px", overflow: "auto", padding: "2rem 0" }}>
                {repeatedColumns.map(({ label, value }, index) => (
                    <div key={`repeated_${index}`} className="mb-2 d-flex align-items-center justify-content-center">
                        <div className="text-start" style={{ width: "30%", marginRight: "2rem" }}>
                            <p className="mb-0 form-control">{label}</p>
                        </div>

                        <div className="text-start" style={{ width: "30%", marginRight: "2rem" }}>
                            <SelectDropdown
                                options={columns?.map((el) => ({ label: el?.label, value: el?.value, disabled: !el?.available }))}
                                value={data?.[value]?.column || ""}
                                onChange={(val) => {
                                    setData((prev) => {
                                        const newData = { ...prev };
                                        newData[value].column = val;
                                        return newData;
                                    });
                                }}
                                placeholder="Select From Options"
                            />
                        </div>

                        <div style={{ width: "10%", marginRight: "2rem" }}>
                            <select
                                className="form-control text-center"
                                style={{ background: data?.[value]?.count <= 0 ? "#f5f5f5" : "#fff" }}
                                value={data?.[value]?.count}
                                onChange={(e) => {
                                    // if (isNaN(e.target.value) || e.target.value < 0) return;

                                    setData((prev) => {
                                        const newData = { ...prev };
                                        newData[value].count = e.target.value;
                                        return newData;
                                    });
                                }}
                            >
                                <option value="">Count</option>
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((el, indx) => (
                                    <option value={el} key={`select_count_${indx}`}>
                                        {el}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <button
                                onClick={() => {
                                    setData((prev) => {
                                        const newData = { ...prev };
                                        newData[value].count = "";
                                        newData[value].column = "";
                                        return newData;
                                    });
                                }}
                                style={{ visibility: !!data?.[value]?.column || !!data?.[value]?.count ? "visible" : "hidden" }}
                                className="btn btn-sm btn-secondary"
                            >
                                Clear
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

function convertArr(arr) {
    const result = {};
    arr.forEach((item) => {
        const { key, column, count } = item;
        if (!!column && !!count) {
            if (Number(count) === 1) {
                result[column] = `${key}_1`;
            } else {
                for (let i = 1; i <= count; i++) {
                    result[column?.replace(/.$/, `${i}`)] = `${key}_${i}`;
                }
            }
        }
    });
    return result;
}
