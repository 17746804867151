import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { uploadWithdrawData } from "../../../../API/panelApis";
import useAuthStore from "../../../../store/authStore";
import useGeneralStore from "../../../../store/generalStore";

export default function UploadWithdrawData({ show, setShow }) {
    const showAlert = useGeneralStore((state) => state.open_alert);
    const userData = useAuthStore((state) => state.userData);

    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);

    const closeModal = () => {
        setShow(false);
        setLoading(false);
        setFile(null);
    };

    const uploadWithdrawDataFile = async () => {
        setLoading(true);

        let formData = new FormData();
        formData.append("file", file);
        formData.append("client_id", userData?.client_id);

        const res = await uploadWithdrawData(formData);

        if (res) {
            showAlert({ variant: "success", msg: res?.message });
            closeModal();
        } else showAlert({ variant: "danger", msg: "Failed to upload" });

        setLoading(false);
    };

    return (
        <Modal size="sm" show={show} backdrop="static">
            <Modal.Header>
                <Modal.Title>Upload Withdraw Data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="py-4">
                    <div className="alert alert-warning text-center d-block mb-4 tx-16">
                        <strong>File should contain</strong> column labelled as <strong>LAN</strong>
                    </div>

                    <div className="row align-items-center mb-3">
                        <div className="col-4">Choose File</div>
                        <div className="col-8">
                            <input type="file" className="form-control" onChange={(e) => setFile(e.target.files[0])} />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" disabled={loading} onClick={() => uploadWithdrawDataFile()}>
                    {loading ? "Uploading" : "Upload"}
                </Button>
                <Button variant="secondary" onClick={() => closeModal()}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
