import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { fetchProducts } from "../../../API";
import SelectDropdown from "../../../ui/forms/SelectDropdown";
import useAuthStore from "../../../store/authStore";

const overdueOption = [
  { label: "Select", value: "" },
  { label: "0-30 Days", value: "1" },
  { label: "30-60 Days", value: "2" },
  { label: "60-90 Days", value: "3" },
  { label: "90-180 Days", value: "4" },
  { label: "180+ Days", value: "5" },
];
const lastDispOption = [
  { label: "Wrong Number", value: "WN" },
  { label: "Switched Off", value: "SW" },
  { label: "Ringing, No Response", value: "RNR" },
  { label: "Promise To Pay", value: "PTP" },
  { label: "Broken PTP", value: "BPTP" },
  { label: "Dispute", value: "DIS" },
  { label: "Surender", value: "SUR" },
  { label: "Paid", value: "PAID" },
  { label: "Call Back", value: "CB" },
  { label: "Want Settlement", value: "SETL" },
  { label: "Do Not Disturb", value: "DND" },
  { label: "Not Yet Captured", value: "None" },
];
const statusOption = [
  { label: "Select", value: "" },
  { label: "High", value: "high" },
  { label: "Medium", value: "medium" },
  { label: "Low", value: "low" },
];

const filterDefaults = {
  search: "",
  batch_no: "",
  product: "",
  //   overdue_bucket: "",
  last_disposition: "",
  //   loan_amount_from: "",
  //   loan_amount_to: "",
  //   status: "",
};

function BatchFilter2({ show, close, prevFilter, setPrevFilter }) {
  const userData = useAuthStore((state) => state.userData);

  const [filter, setFilter] = useState({ ...filterDefaults });

  // handler
  const handleFilter = (name, val) => setFilter((prev) => ({ ...prev, [name]: val }));

  const clearFilter = () => {
    setFilter({ ...filterDefaults });
    setPrevFilter({ ...filterDefaults });
  };

  const closeFilter = () => {
    close();
  };

  const applyFilter = () => {
    setPrevFilter({ ...filter });
    closeFilter();
  };

  useEffect(() => {
    !!show && setFilter({ ...prevFilter });
  }, [show]);

  // -----------------------------------
  const [products, setProducts] = useState([]);

  const getProducts = useCallback(async () => {
    const res = await fetchProducts(userData?.client_id, "collection");
    if (res) setProducts(res);
  }, []);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  return (
    <Modal size="sm" show={show} onHide={close} backdrop="static">
      <Modal.Header>
        <Modal.Title>Filter</Modal.Title>
      </Modal.Header>
      <Modal.Body onKeyDown={(e) => e.key === "Enter" && applyFilter()}>
        {/* Search Filter */}
        <div className="mb-2 row modal-search">
          <div className="col-md-12 pt-3 pb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search by Customer name or Loan Account Number"
              value={filter?.search}
              onChange={(e) => handleFilter("search", e.target.value)}
            />
          </div>
        </div>

        <div className="grey-bg mb-2">
          {/* Search by Batch Number */}
          <div className="mb-2 row">
            <label htmlFor="search_by_batch_number" className="col-md-8 col-form-label pt-2 mt-1">
              Search by Batch Number
            </label>
            <div className="col-md-4">
              <input
                id="search_by_batch_number"
                type="text"
                className="form-control"
                placeholder="Batch No."
                value={filter?.batch_no}
                onChange={(e) => handleFilter("batch_no", e.target.value)}
              />
            </div>
          </div>

          {/* Product/Portfolio filter */}
          <div className="mb-2 row" style={{ zIndex: "2" }}>
            <label className="col-sm-5 col-form-label">Product/ Portfolio</label>
            <div className="col-sm-7">
              <SelectDropdown
                options={[...products.map((val) => ({ label: val, value: val }))]}
                value={filter?.product}
                onChange={(val) => handleFilter("product", val)}
                placeholder="Select"
              />
            </div>
          </div>

          {/* Overdue Bucket */}
          {/* <div className="mb-2 row" style={{ zIndex: "2" }}>
                        <label className="col-sm-5 col-form-label">Overdue Bucket</label>
                        <div className="col-sm-7">
                            <SelectDropdown
                                options={overdueOption}
                                value={filter?.overdue_bucket}
                                onChange={(val) => handleFilter("overdue_bucket", val)}
                                placeholder="Select"
                            />
                        </div>
                    </div> */}

          {/* Last Disposition */}
          <div className="mb-2 row" style={{ zIndex: "2" }}>
            <label className="col-sm-5 col-form-label">Last Disposition</label>
            <div className="col-sm-7">
              <SelectDropdown
                options={lastDispOption}
                value={filter?.last_disposition}
                onChange={(val) => handleFilter("last_disposition", val)}
                placeholder="Select"
              />
            </div>
          </div>
        </div>

        {/* Loan Amount Range */}
        {/* <div className="mb-2 row">
                    <label htmlFor="from" className="col-sm-5 col-form-label">
                        Loan Amount Range
                    </label>
                    <div className="col-sm-7">
                        <div className="row">
                            <div className="col-sm-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="From"
                                    value={filter?.loan_amount_from}
                                    onChange={(e) => handleFilter("loan_amount_from", e.target.value)}
                                />
                            </div>
                            <div className="col-sm-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="To"
                                    value={filter?.loan_amount_to}
                                    onChange={(e) => handleFilter("loan_amount_to", e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div> */}

        {/* Status */}
        {/* <div className="mb-2 row">
                    <label htmlFor="inputState" className="col-sm-5 col-form-label">
                        Status
                    </label>
                    <div className="col-sm-7">
                        <SelectDropdown options={statusOption} value={filter?.status} onChange={(val) => handleFilter("status", val)} placeholder="Select" />
                    </div>
                </div> */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={clearFilter}>
          Clear Filter
        </Button>
        <Button variant="secondary" onClick={closeFilter}>
          Close
        </Button>
        <Button variant="primary" onClick={applyFilter}>
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default BatchFilter2;
