export const findSourceOfLead = (source) => {
    if (source === "called_on_helpline") return "Called on Helpline";
    if (source === "helpline_number") return "Called on Helpline";
    if (source === "ivr_missed_call") return "Missed Call";
    if (source === "pressed_1") return "Promised to Pay on IVR";
    if (source === "whatsapp_reply") return "Replied on Whatsapp";
    if (source === "email_reply") return "Replied on Email";
    if (source === "failed_payment") return "Tried Making Payment";
    if (source === "email_open") return "Opened Email";
    if (source === "via_payment_link") return "Opened Payment Link";
    if (source === "via_notice_link") return "Opened Legal Notice";
    if (source === "via_link") return "Via Link";
    if (source === "physical_notice_delivered") return "Physical notice Delivered";

    //
    if (source === "ptp") return "PTP";

    return source;
};
