import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { BsFileEarmarkTextFill } from "react-icons/bs";
import classes from "./action.module.css";
import { showToast } from "../../../utils/handleToast";

export default function SendDocuments({ loading, sendMessage }) {
    const [show, setShow] = useState(false);
    const [file, setFile] = useState(null);

    const closeModal = () => {
        setShow(false);
        setFile(null);
    };

    const onSend = async () => {
        if (!file) {
            showToast({ variant: "warning", message: "Please select a file" });
            return;
        }

        await sendMessage({ msg: null, file });
        closeModal();
    };

    return (
        <>
            <button onClick={() => setShow(true)} disabled={loading} className={classes.action_item}>
                <span className={classes.action_item_icon}>
                    <BsFileEarmarkTextFill color="#7B67F6" />
                </span>
                <span className={classes.action_item_label}>Document</span>
            </button>

            <Modal style={{ zIndex: "2222" }} size="sm" show={show}>
                <Modal.Header>
                    <Modal.Title>Add Attachment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="my-4">
                        <input className="form-control mb-3" type="file" onChange={(e) => setFile(e.target.files?.[0])} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => closeModal()}>
                        Cancel
                    </Button>

                    <Button onClick={onSend} variant="primary" disabled={loading || !file}>
                        {loading ? "Sending..." : "Send"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
