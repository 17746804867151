import React, { useEffect, useMemo, useState } from "react";
import useGeneralStore from "../../../../../store/generalStore";
import FixedHeader from "../../../../Components/FixedHeader";
import SortButton from "../../../../../ui/buttons/SortButton";
import Loader from "../../../../../ui/Loader";
import NoRecord from "../../../../../Components/NoRecord";
import Arrow from "../../../../../Static/RawImages/arrow.png";
import { Dropdown } from "react-bootstrap";
import classes from "./style.module.css";
import { getCustomerSettelmentManagementReport } from "../../../../../API/panelApis";
import useAuthStore from "../../../../../store/authStore";
import { showToast } from "../../../../../utils/handleToast";
import { format } from "date-fns";
import Filter from "../../../../Components/settlementTracker/Filter";
import DispositionHistory from "../../../../Components/DispositionHistory";

import { downloadMediaUrl, sendLetterEmail, updateDailySettlementData } from "../../../../../API";
import { commaFormat } from "../../../../../Functions/formatNumber";
import { isEmpty } from "../../../../../Functions/isEmpty";
import EmailHistory from "../../../../../Components/EmailHistory";
import usePagination from "../../../../../hooks/usePagination";
import Pagination from "../../../../../Components/Pagination";
import WhatsappMsg from "../../../../../Components/WhatsappMsg";
import getBaseUrl from "../../../../../apis/getBaseUrl";

export default function SetlTracker() {
    const userData = useAuthStore((state) => state.userData);
    const updateFetchedCount = useGeneralStore((state) => state.update_fetched_count);

    const [filter, setFilter] = useState({});
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [selected, setSelected] = useState(null);
    const [showEmailHistory, setShowEmailHistory] = useState(false);
    const [showWhatsappHistory, setShowWhatsappHistory] = useState(false);
    const [showDispositionHistory, setShowDispositionHistory] = useState(false);

    const { currentPage, handleTotal, limitPerPage, paginationCallBack, paginationProps } = usePagination({ defaultLimit: 20 });

    const getData = async () => {
        setLoading(true);

        const res = await getCustomerSettelmentManagementReport({
            client_id: userData?.client_id,
            from_date: !!filter?.from_date ? format(new Date(filter?.from_date), "yyyy-MM-dd") : null,
            to_date: !!filter?.to_date ? format(new Date(filter?.to_date), "yyyy-MM-dd") : null,
            payment_received: filter?.payment_received === "yes" ? true : filter?.payment_received === "no" ? false : null,
            letter_received: filter?.letter_received === "yes" ? true : filter?.letter_received === "no" ? false : null,
            product: !!filter?.product ? filter?.product : null,
            // pagination
            page: currentPage,
            size: limitPerPage,
        });

        if (!!res) {
            updateFetchedCount(res?.data?.total);
            setData(res?.data?.data);
            handleTotal(Number(res?.data?.total));
        } else {
            showToast({ variant: "danger", message: "Failed to fetch details" });
        }

        setLoading(false);
    };

    const fetchData = () => paginationCallBack(getData);

    useEffect(() => {
        fetchData();
    }, [filter, currentPage]);

    const updateRejectedStatus = async (id) => {
        if (!id) {
            showToast({ variant: "warning", message: "Id Not Found" });
            return;
        }
        const res = await updateDailySettlementData({ letter_rejected: true }, id);

        if (res) {
            showToast({ variant: "success", message: res?.message });
            fetchData();
        } else showToast({ variant: "danger", message: "Action Failed" });
    };

    async function sendLetter({ type, number }) {
        const res = sendLetterEmail({ numbers: number, type });

        if (!!res) showToast({ variant: "success", message: res?.message });
        else showToast({ variant: "danger", message: "Action Failed" });
    }

    const downloadUrl = useMemo(() => {
        let queryString = [];
        queryString.push(`client_id=${userData?.client_id}`);
        if (!!filter?.from_date) queryString.push(`from_date=${format(new Date(filter?.from_date), "yyyy-MM-dd")}`);
        if (!!filter?.to_date) queryString.push(`to_date=${format(new Date(filter?.to_date), "yyyy-MM-dd")}`);

        return `${getBaseUrl().baseUrl}/dashboard/api/download_customer_settlement_management_report/?` + queryString?.join("&");
    }, [filter]);

    return (
        <div>
            <FixedHeader>
                <div className="d-flex align-items-center justify-content-start">
                    <Filter prevFilter={filter} setPrevFilter={setFilter} />
                    <SortButton
                        // onClick={() => {
                        //     setSortAsc(!SortAsc);
                        //     let sort = SortAsc ? "asc" : "dsc";
                        //     setSearchParameter({ ...filterMapping(), sort });
                        // }}
                        label="Sort By"
                        // active={!SortAsc}
                    />
                </div>

                {/* <div>
                    <a href={downloadUrl} target="_blank" rel="noreferrer" className="btn btn-primary btn-lg">
                        <svg width="16" height="17" xmlns="http://www.w3.org/2000/svg">
                            <g fill="#fff" fillRule="evenodd">
                                <path d="M.202 11.702a.7.7 0 0 1 1.401 0v2.1a.7.7 0 0 0 .7.7h9.806a.7.7 0 0 0 .7-.7v-2.1a.7.7 0 0 1 1.401 0v2.1a2.1 2.1 0 0 1-2.1 2.1H2.303a2.1 2.1 0 0 1-2.102-2.1v-2.1z" />
                                <path d="M9.513 7.706a.7.7 0 0 1 .99.99l-2.801 2.8a.7.7 0 0 1-.99 0l-2.802-2.8a.7.7 0 1 1 .99-.99l2.306 2.306 2.307-2.306z" />
                                <path d="M6.506 1.2a.7.7 0 0 1 1.4 0v9.802a.7.7 0 0 1-1.4 0V1.2z" />
                            </g>
                        </svg>
                        Download Settlment File
                    </a>
                </div> */}
            </FixedHeader>

            {!!data?.length && !loading && (
                <>
                    {data?.map((item, index) => (
                        <div key={index} className="row">
                            <div className="col-4">
                                <p className="mb-1">
                                    <strong>
                                        {item?.customer_name || "..."} ({item?.loan_account_no || "..."})
                                    </strong>
                                </p>
                                <p className="mb-1 text-black">{item?.customer__product_name || "..."}</p>
                                {!isEmpty(item?.customer__opening_dpd) && (
                                    <p className="mb-1 text-muted">
                                        DPD - <span className="text-black">{item?.customer__opening_dpd}</span>
                                    </p>
                                )}

                                <hr className="my-3 w-50" />

                                <p className="mb-1 text-muted">
                                    TOS - <span className="text-black">{!!item?.customer__pos ? `Rs. ${commaFormat(item?.customer__pos)}` : "..."}</span>
                                </p>
                                <p className="mb-1 text-muted">
                                    POS -{" "}
                                    <span className="text-black">
                                        {!!item?.customer__principal_outstanding ? `Rs. ${commaFormat(item?.customer__principal_outstanding)}` : "..."}
                                    </span>
                                </p>
                                <p className="mb-1 text-muted">
                                    Date -{" "}
                                    <span className="text-black">{!!item?.contact_date ? format(new Date(item?.contact_date), "dd-MM-yyyy") : "..."}</span>
                                </p>
                            </div>

                            <div className="col-4">
                                <table className={classes.table}>
                                    <tbody>
                                        <tr>
                                            <td colSpan={2}>
                                                <p className="mb-1">Settlement</p>
                                                <p className="mb-0">
                                                    <strong>
                                                        {!isEmpty(item?.customer__pre_approved_sattlement_amount) &&
                                                            `₹ ${commaFormat(item?.customer__pre_approved_sattlement_amount)}`}
                                                        {!isEmpty(item?.number_of_emi) && `(${item?.number_of_emi} inst.)`}
                                                    </strong>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Waiver of POS</td>
                                            <td>{item?.percent_waiver || "..."}</td>
                                        </tr>
                                        <tr>
                                            <td>Letter</td>
                                            <td>{item?.customer__settlement_letter_sent || "..."}</td>
                                        </tr>
                                        <tr>
                                            <td>Payment</td>
                                            <td>{item?.payment_status || "..."}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="col-4">
                                <div className="action-box">
                                    <label htmlFor="dropdown-basic" className="col-form-label mb-0">
                                        Action
                                    </label>
                                    <Dropdown className="dropdown-s2">
                                        <Dropdown.Toggle id="dropdown-basic" className="w-100 text-start select-style">
                                            Select
                                            <img src={Arrow} alt="Sort" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="select-style-dropdown">
                                            <Dropdown.Item
                                                disabled={!item?.settlement_letter}
                                                href={downloadMediaUrl(item?.settlement_letter)}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Download Setl Letter
                                            </Dropdown.Item>

                                            <Dropdown.Item onClick={() => updateRejectedStatus(item?.id)}>Mark as Rejected</Dropdown.Item>

                                            {/* <Dropdown.Item
                                                disabled={!item?.mobile_number}
                                                onClick={() => sendLetter({ number: item?.mobile_number, type: "client_letter" })}
                                            >
                                                Send request for Letter
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                disabled={!item?.mobile_number}
                                                onClick={() => sendLetter({ number: item?.mobile_number, type: "revise_letter" })}
                                            >
                                                Request Revise Letter
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                disabled={!item?.mobile_number}
                                                onClick={() => sendLetter({ number: item?.mobile_number, type: "borrower_letter" })}
                                            >
                                                Letter to Borrower
                                            </Dropdown.Item> */}
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    {/* View History Hidden for INDUSIND */}
                                    {userData?.client_id !== 56 && (
                                        <>
                                            <hr className="mt-4 mb-3" />

                                            <p className="mb-2">View History</p>

                                            <div className="d-flex align-items-end gap-1 history-box">
                                                <li>
                                                    <p
                                                        onClick={() => {
                                                            setSelected(item);
                                                            setShowEmailHistory(true);
                                                        }}
                                                        className="al-popup-button cursor-pointer mb-0"
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <svg width="24" height="18" xmlns="http://www.w3.org/2000/svg">
                                                            <g fill="#0A7AFF" fillRule="nonzero">
                                                                <path d="M0 0v12.96a4.32 4.32 0 0 0 4.32 4.32h14.4a4.32 4.32 0 0 0 4.32-4.32V0H0zm22.08 12.96a3.36 3.36 0 0 1-3.36 3.36H4.32a3.36 3.36 0 0 1-3.36-3.36v-12h21.12v12z" />
                                                                <path d="m20.39 3.682-.643-.71-8.227 7.42-8.18-7.411-.642.71 8.822 7.997z" />
                                                            </g>
                                                        </svg>
                                                        <span className="mt-1 tx-12" style={{ display: "block" }}>
                                                            Email
                                                        </span>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p
                                                        onClick={() => {
                                                            setSelected(item);
                                                            setShowWhatsappHistory(true);
                                                        }}
                                                        className="al-popup-button cursor-pointer mb-0"
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <svg
                                                            width="22"
                                                            height="22"
                                                            style={{ objectFit: "contain" }}
                                                            viewBox="0 0 24 24"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <g fill="#0A7AFF" fillRule="nonzero">
                                                                <path d="M11.52 0A11.52 11.52 0 0 0 1.44 17.074L0 23.04l5.822-1.507A11.52 11.52 0 1 0 11.52 0zm0 22.08a10.56 10.56 0 0 1-5.38-1.474l-.174-.105-4.608 1.18 1.1-4.742-.101-.173a10.56 10.56 0 1 1 9.163 5.314z" />
                                                                <path d="M7.632 13.239a14.745 14.745 0 0 0 4.905 3.842c.717.34 1.676.743 2.744.812.067.003.13.006.196.006.717 0 1.293-.248 1.763-.758.003-.002.008-.008.011-.014.167-.202.357-.383.556-.576.136-.13.274-.265.406-.403.614-.64.614-1.452-.005-2.07l-1.731-1.732c-.294-.305-.645-.466-1.014-.466s-.723.161-1.025.463l-1.031 1.031a5.48 5.48 0 0 0-.286-.15 3.561 3.561 0 0 1-.316-.172c-.94-.596-1.792-1.374-2.607-2.373-.412-.522-.688-.96-.881-1.406.27-.245.524-.5.769-.751.086-.09.176-.179.265-.268.31-.311.478-.671.478-1.037 0-.366-.164-.726-.478-1.037l-.858-.858c-.101-.1-.196-.199-.294-.3-.19-.195-.389-.397-.585-.578C8.318 4.153 7.97 4 7.6 4c-.365 0-.717.153-1.025.446L5.498 5.524a2.213 2.213 0 0 0-.66 1.416c-.054.689.073 1.42.4 2.304.505 1.368 1.265 2.639 2.394 3.995zm-2.09-6.238c.034-.383.18-.703.457-.98l1.072-1.07c.167-.162.35-.245.53-.245.175 0 .354.083.518.25.193.179.374.366.57.565l.3.305.858.858c.178.179.27.36.27.539 0 .178-.092.36-.27.538l-.268.271c-.268.27-.518.527-.795.772l-.014.014c-.24.24-.202.467-.144.64.003.008.006.014.008.023.222.532.53 1.04 1.011 1.644.864 1.066 1.774 1.892 2.777 2.529.123.08.256.144.38.207.115.058.222.112.317.173l.031.017a.602.602 0 0 0 .28.072.61.61 0 0 0 .429-.196l1.077-1.077c.167-.167.348-.256.527-.256.219 0 .397.135.51.256l1.736 1.734c.346.346.343.72-.008 1.086-.121.13-.248.253-.383.383-.202.196-.412.397-.602.625-.332.357-.726.524-1.236.524-.049 0-.1-.003-.15-.006-.944-.06-1.823-.429-2.482-.743a14.008 14.008 0 0 1-4.669-3.657c-1.074-1.294-1.797-2.497-2.275-3.788-.296-.792-.409-1.428-.363-2.007z" />
                                                            </g>
                                                        </svg>
                                                        <span className="mt-1 tx-12" style={{ display: "block" }}>
                                                            WhatsApp
                                                        </span>
                                                    </p>
                                                </li>
                                                <li>
                                                    <p
                                                        onClick={() => {
                                                            setSelected(item);
                                                            setShowDispositionHistory(true);
                                                        }}
                                                        className="al-popup-button cursor-pointer mb-0"
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <svg width="18" height="24" viewBox="0 0 19 25" xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="m16.852 19.622-3.525-1.961a1.079 1.079 0 0 1-.594-.97l-.002-.982a9.482 9.482 0 0 0 1.63-3.33c.007-.03.01-.06.01-.091v-.82H15.6a1.23 1.23 0 0 0 1.229-1.228V7.782a1.227 1.227 0 0 0-.82-1.153v-.075a6.554 6.554 0 0 0-13.107 0v.075c-.49.172-.817.634-.819 1.153v2.458c.002.519.33.98.82 1.153v.895a1.64 1.64 0 0 0 1.638 1.638h.563c.299.628.659 1.224 1.075 1.78v.944c.002.397-.215.763-.565.951l-3.652 1.995a3.686 3.686 0 0 0-1.927 3.243v1.327a.41.41 0 0 0 .41.41h18.022a.41.41 0 0 0 .41-.41v-1.245a3.673 3.673 0 0 0-2.025-3.299zm-.842-11.84v2.458a.41.41 0 0 1-.41.41h-1.229V7.373H15.6a.41.41 0 0 1 .41.41zM3.312 10.65a.41.41 0 0 1-.41-.41V7.782a.41.41 0 0 1 .41-.41h1.229v3.278H3.312zm1.229 2.457a.82.82 0 0 1-.82-.819v-.82h.82v.82c0 .03.003.062.01.092.059.246.131.489.217.727H4.54zm-.002-6.553h-.817A5.74 5.74 0 0 1 9.456.819a5.74 5.74 0 0 1 5.734 5.735h-.817a3.664 3.664 0 0 0-.858-2.65c-.841-.96-2.207-1.446-4.059-1.446-1.852 0-3.218.486-4.059 1.446a3.664 3.664 0 0 0-.858 2.65zM.854 23.757v-.918a2.868 2.868 0 0 1 1.5-2.524l3.65-1.993c.615-.332.996-.974.994-1.672v-.944a.808.808 0 0 0-.166-.494 9.738 9.738 0 0 1-.813-1.286h1.874c.173.49.634.818 1.153.82h1.23a1.229 1.229 0 0 0 0-2.458h-1.23c-.519.002-.98.33-1.153.82H5.641a6.369 6.369 0 0 1-.281-.867V6.554a2.842 2.842 0 0 1 .653-2.11c.679-.775 1.837-1.167 3.443-1.167 1.6 0 2.756.39 3.436 1.158a2.88 2.88 0 0 1 .66 2.119v5.687a8.666 8.666 0 0 1-1.472 2.97.808.808 0 0 0-.166.495v.985a1.886 1.886 0 0 0 1.03 1.693l3.525 1.962a2.866 2.866 0 0 1 1.589 2.575v.836H.854zm7.783-10.24a.41.41 0 0 1 .41-.41h1.228a.41.41 0 0 1 0 .82H9.046a.41.41 0 0 1-.41-.41z"
                                                                fill="#0A7AFF"
                                                                fillRule="nonzero"
                                                            />
                                                        </svg>
                                                        <span className="mt-1 tx-12" style={{ display: "block" }}>
                                                            Dispositions
                                                        </span>
                                                    </p>
                                                </li>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <hr className="my-4" />
                        </div>
                    ))}

                    <Pagination {...paginationProps} />
                </>
            )}

            {loading && <Loader />}
            {!loading && !data?.length && <NoRecord />}

            <EmailHistory show={showEmailHistory} setShow={setShowEmailHistory} customerId={selected?.customer_id} />
            <WhatsappMsg
                show={showWhatsappHistory}
                setShow={setShowWhatsappHistory}
                name={selected?.customer_name}
                mobileNo={selected?.mobile_number}
                isCandidate={false}
                withSendMessage={false}
            />

            <DispositionHistory show={showDispositionHistory} setShow={setShowDispositionHistory} customerMobileNo={selected?.mobile_number} />
        </div>
    );
}
