import { useEffect, useRef, useState } from "react";
import { postManualCallInitiate } from "../API/agentApis";
import useGeneralStore from "../store/generalStore";

const btnText = {
    CALL: "Call",
    CALLING: "Calling...",
};

const callTypes = {
    AGENT: "agent",
    AUTOMATIC: "automatic",
};

export default function useCallCustomer() {
    const showAlert = useGeneralStore((state) => state.open_alert);

    const [loading, setLoading] = useState("");
    const [buttonText, setButtonText] = useState(btnText.CALL);

    const timeOutRef = useRef();

    const callCustomer = async ({ call_type = callTypes.AGENT, customer_mobile_number = "", contact_type = "" }) => {
        if (!call_type || !customer_mobile_number) {
            showAlert({ variant: "warning", msg: "Unable to make a Call" });
            return;
        }

        setLoading(true);
        try {
            const res = await postManualCallInitiate({ to_number: customer_mobile_number, call_type, contact_type: !!contact_type ? contact_type : null });
            if (res?.status === 400) {
                showAlert({ variant: "warning", msg: res?.resp });
            } else if (res?.status === 201 || res?.status === 200) {
                showAlert({
                    variant: res?.variant === "yellow" ? "warning" : res?.variant === "red" ? "danger" : res?.variant === "green" ? "success" : "info",
                    msg: res?.resp || res?.success_message,
                });
            }
            setButtonText(btnText.CALLING);
        } catch (err) {
            console.log("Calling failed : ", err);
            showAlert({ variant: "danger", msg: "Calling Failed, Unknown Error Occured" });
        }

        setLoading(false);
    };

    useEffect(() => {
        if (buttonText === btnText.CALLING) {
            if (!!timeOutRef.current) clearTimeout(timeOutRef.current);

            timeOutRef.current = setTimeout(() => {
                setButtonText(btnText.CALL);
                clearTimeout(timeOutRef.current);
            }, 2000);
        }

        return () => {
            if (!!timeOutRef.current) clearTimeout(timeOutRef.current);
        };
    }, [buttonText]);

    return { callCustomer, loading, callButtonText: buttonText, callTypes: { ...callTypes } };
}
