// --------------

// constants
const ACTION_TEXT = "action_to_be_taken";

// notification types
const DISPOSITION_ADDED_RNR = "disposition_added_rnr";
const DISPOSITION_ADDED_WN_SW = "disposition_added_wn_sw";
const CALL_AGAIN_NOW = "call_again_now";
const CALL_AGAIN = "call_again";

// --------------

export const handleNotifications = ({ customer_mobile_number, notification_type, message, callCustomer = () => {} }) => {
    if (!(message?.indexOf(ACTION_TEXT) > -1)) {
        return message;
    }

    let msgArr = message?.split(ACTION_TEXT);
    const link = `https://wa.me/${customer_mobile_number}?text=Hi%2C%20I'm%20from%20KS%20Legal.%20This%20is%20regarding%20your%20outstanding%20loan.%20`;

    if (notification_type === DISPOSITION_ADDED_RNR) {
        if (msgArr?.length > 1)
            return (
                <>
                    {msgArr?.[0]}
                    <span style={{ cursor: "pointer" }}>{`[CALL ON ALTERNATE NUMBER NOW]`}</span>
                    {msgArr?.[1]}
                    <a href={link} target="_blank" rel="noreferrer">
                        {`[CLICK TO SEND MESSAGE]`}
                    </a>
                </>
            );
    }

    if (notification_type === DISPOSITION_ADDED_WN_SW) {
        if (msgArr?.length > 0)
            return (
                <>
                    {msgArr?.[0]}
                    <a href={link} target="_blank" rel="noreferrer">
                        {`[CLICK TO SEND MESSAGE]`}
                    </a>
                </>
            );
    }

    if (notification_type === CALL_AGAIN_NOW) {
        if (msgArr?.length > 0)
            return (
                <>
                    {msgArr?.[0]}
                    <span style={{ cursor: "pointer" }} onClick={() => callCustomer()}>{`[CALL AGAIN NOW]`}</span>
                </>
            );
    }

    if (notification_type === CALL_AGAIN) {
        if (msgArr?.length > 0)
            return (
                <>
                    {msgArr?.[0]}
                    <span style={{ cursor: "pointer" }} onClick={() => callCustomer()}>{`[CALL NOW]`}</span>
                </>
            );
    }
};
