import { format } from "date-fns";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { fetchWhatsappData } from "../../../../API";
import ContentLoader from "../../../../Components/ContentLoader";
import { isEmpty } from "../../../../Functions/isEmpty";
import useGeneralStore from "../../../../store/generalStore";

export default function WhatsappResponseModal({ mobile = "", setMobile = () => {} }) {
    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(true);

    const showAlert = useGeneralStore((state) => state.open_alert);

    const prevMsgRef = useRef(null);

    const closeModal = () => {
        setShow(false);
        setMobile(null);
        setData([]);
    };

    const fetchData = async () => {
        setLoading(true);
        const res = await fetchWhatsappData(mobile);
        if (res) {
            setData(res?.data);
        } else
            showAlert({
                variant: "danger",
                msg: "Failed to load responses",
            });

        setLoading(false);
    };

    useEffect(() => {
        if (!!mobile) {
            setShow(true);
            fetchData();
        }
    }, [mobile]);

    return (
        <Modal size="lg" show={show} backdrop="static">
            <Modal.Header>
                <Modal.Title>Whatsapp Responses</Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-3">
                <div style={{ height: "400px", overflow: "scroll" }}>
                    {!!data?.length && !loading && (
                        <div className="account_extra_whatsapp">
                            {data?.slice(0, 30)?.map((item, i) => {
                                prevMsgRef.current = i <= 0 ? null : !isEmpty(data[i - 1]?.created) ? format(new Date(data[i - 1]?.created), "ddMMyyyy") : null;
                                return (
                                    <React.Fragment key={i}>
                                        {prevMsgRef.current !== format(new Date(item?.created), "ddMMyyyy") && (
                                            <div className="account_extra_date">
                                                <p className="title-hr mt-2 mb-4">
                                                    <span>{!isEmpty(item?.created) ? format(new Date(item?.created), "do MMM yyyy") : ""}</span>
                                                </p>
                                            </div>
                                        )}
                                        <div className={item.owner === "Wati" ? "account_extra_whatsapp_owner" : "account_extra_whatsapp_user"}>
                                            <div className="account_extra_whatsapp_text">{item.text}</div>
                                            <span style={{ textAlign: "right" }}>
                                                {!isEmpty(item?.created) ? format(new Date(item?.created), "hh:mm aaa") : ""}
                                            </span>
                                        </div>
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    )}

                    {!data?.length && !loading && <div className="d-flex align-items-center justify-content-center py-5 tx-18">No Data Found</div>}
                    {loading && <ContentLoader />}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => closeModal()} variant="secondary">
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
