import React from "react";
import { Pagination } from "react-bootstrap";

export default function Paging({ currentPage = 1, pageTotal = 0, handlePagination = () => {} }) {
    if (pageTotal <= 1) return null;

    const Page = ({ page }) => (
        <Pagination.Item active={currentPage === page} onClick={() => handlePagination(page)}>
            {page}
        </Pagination.Item>
    );

    if (pageTotal <= 6)
        return (
            <Pagination>
                {Array.from({ length: pageTotal }, (_, i) => i + 1).map((page) => (
                    <Page key={page} page={page} />
                ))}
            </Pagination>
        );

    return (
        <Pagination>
            <Pagination.First disabled={currentPage <= 1} onClick={() => handlePagination(Math.max(currentPage - 10, 1))} />
            <Pagination.Prev disabled={currentPage <= 1} onClick={() => handlePagination(currentPage - 1)} />

            <Page page={1} />

            {currentPage <= 2 && (
                <>
                    <Page page={2} />
                    <Page page={3} />
                    <Pagination.Ellipsis />
                </>
            )}

            {currentPage > 2 && currentPage < pageTotal - 1 && (
                <>
                    <Pagination.Ellipsis />
                    <Page page={currentPage - 1} />
                    <Page page={currentPage} />
                    <Page page={currentPage + 1} />
                    <Pagination.Ellipsis />
                </>
            )}

            {currentPage >= pageTotal - 1 && (
                <>
                    <Pagination.Ellipsis />
                    <Page page={pageTotal - 2} />
                    <Page page={pageTotal - 1} />
                </>
            )}

            <Page page={pageTotal} />

            <Pagination.Next disabled={currentPage >= pageTotal} onClick={() => handlePagination(currentPage + 1)} />
            <Pagination.Last disabled={currentPage >= pageTotal} onClick={() => handlePagination(Math.min(currentPage + 10, pageTotal))} />
        </Pagination>
    );
}
