import axios from "axios";

export const apiGET = async (endPoint, params, cancelToken) => {
  try {
    if (!!cancelToken) {
      const res = await axios.get(endPoint, {
        params,
        cancelToken,
      });
      return res.data;
    } else {
      const res = await axios.get(endPoint, {
        params,
      });
      return res.data;
    }
  } catch (err) {
    handleApiError(err);
    throw err;
  }
};

export const apiPOST = async (endPoint, body, params) => {
  try {
    const res = await axios.post(endPoint, body, { params });
    return res.data;
  } catch (err) {
    console.log(err);
    handleApiError(err);
  }
};

export const apiPUT = async (endPoint, body, params) => {
  try {
    const res = await axios.put(endPoint, body, { params });
    return res.data;
  } catch (err) {
    handleApiError(err);
  }
};

export const apiPATCH = async (endPoint, body, params) => {
  try {
    const res = await axios.patch(endPoint, body, { params });
    return res.data;
  } catch (err) {
    handleApiError(err);
  }
};
export const apiDELETE = async (endPoint, body, params) => {
  try {
    const res = await axios.delete(endPoint, {
      data: body,
      params,
    });
    return res.data;
  } catch (err) {
    handleApiError(err);
  }
};

export function handleApiError(error) {
  if (Number(error?.response?.status) === 401) {
    localStorage.clear();
    window.location.reload(false);
  }

  // if (error.response) {
  //     // Request made and server responded
  //     console.log(error.response.data);
  //     console.log(error.response.status);
  //     console.log(error.response.headers);
  // } else if (error.request) {
  //     // The request was made but no response was received
  //     console.log(error.request);
  // } else {
  //     // Something happened in setting up the request that triggered an Error
  //     console.log("Error", error.message);
  // }

  console.log(error);
}
