import React from "react";
import { MultiSelect } from "react-multi-select-component";

// Sorting by disabled
const sortByDisabled = () => (a, b) => {
    if (!!a?.disabled) return 1;
    else if (!!b?.disabled) return -1;
    return 0;
};

export default function MultiSelectDropDown({ name = "", disabled = false, options, value, onChange, disableSearch = false, hasSelectAll = true }) {
    return (
        <MultiSelect
            options={options?.sort(sortByDisabled())}
            disabled={disabled}
            value={options?.filter((item) => value?.includes(item?.value))}
            disableSearch={disableSearch}
            hasSelectAll={hasSelectAll}
            className="bg-white"
            onChange={(val) =>
                !!name
                    ? onChange(
                          name,
                          val?.map(({ value }) => value)
                      )
                    : onChange(val?.map(({ value }) => value))
            }
            labelledBy="Select"
        />
    );
}
