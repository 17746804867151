import React from "react";
import Sort from "../../Static/RawImages/sort.png";

export default function SortButton({ onClick, disabled = false, active = false, label = "Sort" }) {
    return (
        <button
            onClick={onClick}
            disabled={disabled}
            style={{ position: "relative" }}
            className={active ? "font-weight-bold btn btn-secondary flex-shrink-0" : "btn btn-secondary flex-shrink-0"}
        >
            <img src={Sort} alt="Sort" />
            {label}
        </button>
    );
}
