import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { postPoliceInquiryInitiated } from "../../../../API/panelApis";
import useForm from "../../../../hooks/useForm";
import useGeneralStore from "../../../../store/generalStore";

export default function PoliceInquiryInitiatedModal({ show, closeModal, complaintId }) {
    const [formFields, handleFormFields, resetFormFields] = useForm({
        mobile_1: "",
        mobile_2: "",
        remarks: "",
    });
    const [loading, setLoading] = useState(false);
    const showAlert = useGeneralStore((state) => state.open_alert);

    const handleCloseModal = () => {
        closeModal();
        resetFormFields();
    };

    const handleSend = async () => {
        if (!complaintId) return;
        setLoading(true);

        try {
            const res = await postPoliceInquiryInitiated({
                mobile_1: formFields?.mobile_1,
                mobile_2: formFields?.mobile_2,
                remarks: formFields?.remarks,
                complaint_id: complaintId,
            });

            if (res) {
                showAlert({
                    variant: "success",
                    msg: res?.message,
                });

                handleCloseModal();
            }
        } catch (error) {
            showAlert({
                variant: "danger",
                msg: "Sending failed",
            });
            console.log(error);
        }
        setLoading(false);
    };

    return (
        <>
            <Modal size="sm" show={show} onHide={() => handleCloseModal()} backdrop="static">
                <Modal.Header>
                    <Modal.Title>Police Inquiry Intiated</Modal.Title>
                </Modal.Header>
                <Modal.Body className="tab-m-body">
                    <div className="py-5">
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSend();
                            }}
                            className="d-flex flex-column align-items-center justify-content-center gap-2"
                        >
                            <label className="col-form-label w-75">
                                <p className="mb-1">Police Mobile Number #1</p>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Police Mobile Number #1"
                                    value={formFields?.mobile_1}
                                    onChange={(e) => handleFormFields("mobile_1", e.target.value)}
                                />
                            </label>
                            <label className="col-form-label w-75">
                                <p className="mb-1">Police Mobile Number #2</p>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Police Mobile Number #2"
                                    value={formFields?.mobile_2}
                                    onChange={(e) => handleFormFields("mobile_2", e.target.value)}
                                />
                            </label>
                            <label className="col-form-label w-75">
                                <p className="mb-1">Additional Remarks</p>
                                <textarea
                                    rows={8}
                                    className="form-control"
                                    placeholder="Additional Remarks"
                                    value={formFields?.remarks}
                                    onChange={(e) => handleFormFields("remarks", e.target.value)}
                                />
                            </label>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleCloseModal()}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        disabled={loading || (!formFields?.mobile_1 && !formFields?.mobile_2 && !formFields?.remarks)}
                        onClick={() => handleSend()}
                    >
                        {loading ? "Sending..." : "Send"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
