export const dispositionOptions = [
  {
    label: "",
    options: [
      { value: "PTP", label: "Promise To Pay (PTP)" },
      { value: "SETL", label: "Want Settlement (SETL)" },
      { value: "PAID", label: "Fully PAID" },
      { value: "P-PAID", label: "Partially PAID" },
      { value: "RTP", label: "Refuse To Pay (RTP)" },
      { value: "WN", label: "Wrong Number (WN)" },
    ],
  },
  {
    label: "",
    options: [
      { value: "SW", label: "Switch Off (SW)" },
      { value: "RNR", label: "Ringing No Response (RNR)" },
    ],
  },
  {
    label: "",
    options: [
      // { value: "DND", label: "Do Not Disturb (DND)" },
      { value: "BPTP", label: "Broken PTP (BPTP)" },
      { value: "DIS", label: "Dispute (DIS)" },
      { value: "CB", label: "Call Back (CB)" },
      { value: "LANG", label: "Language Barrier (LANG)" },
      { value: "DECEASED", label: "Deceased (DECEASED)" },
      { value: "SUR", label: "Surrender (SUR)" },
    ],
  },
];

export const vehicleDisp = [
  {
    label: "",
    options: [
      { value: "VEHICLE_LOST", label: "VEHICLE LOST (VEHICLE_LOST)" },
      { value: "VEHICLE_ALREADY_SURRENDERED", label: "VEHICLE ALREADY SURRENDERED" },
      { value: "READY_TO_SURRENDER_VEHICLE", label: "READY TO SURRENDER VEHICLE" },
    ],
  },
];


// SETTLEMENT PROCESS
export const settlementProcess = Object.freeze({
  INTENTION_RECEIVED: "intention_received",
  CONFIRMATION_RECEIVED: "confirmation_received",
  LETTER_RECEIVED: "letter_received",
});
export const flattenedDispositionOptions = [
  ...dispositionOptions,
  ...vehicleDisp
].map(({ options }) => options).flat();
