import React, { useEffect, useRef, useState } from "react";
import classes from "./style.module.css";
import { getCandidateGupshupConversation, getGupshupConversation } from "../../API";
import InfiniteScroll from "react-infinite-scroll-component";
import { VscRefresh } from "react-icons/vsc";
import { TbLoader2 } from "react-icons/tb";
import { format } from "date-fns";
import SendMessage from "./SendMessage";
import Attachment from "./Attachment";
import { FaChevronLeft } from "react-icons/fa6";
import { IoCheckmark, IoCheckmarkDone } from "react-icons/io5";

const escapeHtml = (text) => text?.replace(/&/g, "&amp;")?.replace(/</g, "&lt;")?.replace(/>/g, "&gt;");

function formatText(text) {
    let bold = /\*(.*?)\*/gm;
    let italic = /\_(.*?)\_/gm;
    let strikeThrough = /\~(.*?)\~/gm;
    let urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

    text = escapeHtml(text);

    return text
        ?.replace(bold, "<strong>$1</strong>")
        ?.replace(italic, "<em>$1</em>")
        ?.replace(strikeThrough, '<span style="text-decoration:line-through;">$1</span>')
        ?.replace(urlRegex, (url) => '<a href="' + url + '" target="_blank" rel="noreferrer" >' + url + "</a>");
}

function checkIfReplyExceed24Hours(arr) {
    let isDisabled = true;
    const lastReply = arr?.find((item) => item?.owner === "User");
    if (!!lastReply) {
        const lastReplyTime = new Date(lastReply?.created);
        const currentTime = new Date();
        const diff = Math.ceil(Math.abs(currentTime - lastReplyTime) / 1000);

        if (diff < 24 * 60 * 60) isDisabled = false;
    }

    return isDisabled;
}

const LIMIT_PER_PAGE = 15;

export default function WhatsappMsg({
    show,
    setShow = () => {},
    mobileNo = "",
    name = "",
    isCandidate = true,
    withSendMessage = true,
    attachments = [],
    isMasked = false,
}) {
    const [fullChatData, setFullChatData] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isTabChanging, setIsTabChanging] = useState(false);
    const [sendMessageDisabled, setSendMessageDisabled] = useState(true);
    const [displatChats, setDisplayChats] = useState([]);

    const [activeTab, setActiveTab] = useState(0);

    const totalPageRef = useRef(1);
    const currentPageRef = useRef(1);

    const closeModal = () => {
        setShow(false);
        setDisplayChats([]);
        setData([]);
        setActiveTab(0);

        totalPageRef.current = 1;
        currentPageRef.current = 1;
    };

    const prevDateRef = useRef(null);

    const manageData = (dataArr = [], tab) => {
        const filteredArr = tab === 1 ? dataArr?.filter((item) => item?.owner === "User") ?? [] : dataArr;

        currentPageRef.current = 1;
        totalPageRef.current = Math.ceil(filteredArr?.length / LIMIT_PER_PAGE);

        setData(filteredArr);
        setDisplayChats(filteredArr?.slice(0, LIMIT_PER_PAGE));
    };

    const fetchDetails = async () => {
        if (!mobileNo) return;
        setLoading(true);

        const res = !isCandidate ? await getGupshupConversation({ number: mobileNo }) : await getCandidateGupshupConversation({ number: mobileNo });

        if (res?.data?.length > 0) {
            const sortedArr = res?.data?.sort((a, b) => new Date(b?.created) - new Date(a?.created));
            setFullChatData(sortedArr);
            manageData(sortedArr, activeTab);

            // disable send session message if last reply is more than 24 hours
            setSendMessageDisabled(() => checkIfReplyExceed24Hours(sortedArr));
        } else setData([]);

        setLoading(false);
    };

    useEffect(() => {
        if (!!mobileNo && !!show) fetchDetails();
    }, [mobileNo, show]);

    const handleNext = () => {
        if (currentPageRef.current >= totalPageRef.current) return;
        currentPageRef.current = currentPageRef.current + 1;
        const start = (currentPageRef.current - 1) * LIMIT_PER_PAGE;
        const end = start + LIMIT_PER_PAGE;
        setDisplayChats((prev) => [...prev, ...data?.slice(start, end)]);
    };

    const onTabChange = (tab) => {
        setIsTabChanging(true);
        manageData(fullChatData, tab);
        setActiveTab(tab);
        const tabChangeTimeout = setTimeout(() => {
            setIsTabChanging(false);
            clearTimeout(tabChangeTimeout);
        }, 200);
    };

    return (
        <>
            <div onClick={() => closeModal()} className={`${classes.wrapper} ${show ? classes.show : classes.hidden}`} />

            <div className={`${classes.sidepanel} ${show ? classes.show : classes.hidden}`}>
                <div className={classes.sidepanel_header}>
                    <div className={classes.profile_container}>
                        <button className={classes.back_btn} onClick={() => closeModal()}>
                            <FaChevronLeft />
                        </button>

                        <div className={classes.profile_icon_name}>
                            <svg viewBox="0 0 212 212" style={{ width: "2.5rem", objectFit: "contain" }}>
                                <path
                                    fill="#DFE5E7"
                                    d="M106.251,0.5C164.653,0.5,212,47.846,212,106.25S164.653,212,106.25,212C47.846,212,0.5,164.654,0.5,106.25 S47.846,0.5,106.251,0.5z"
                                />
                                <path
                                    fill="#FFFFFF"
                                    d="M173.561,171.615c-0.601-0.915-1.287-1.907-2.065-2.955c-0.777-1.049-1.645-2.155-2.608-3.299 c-0.964-1.144-2.024-2.326-3.184-3.527c-1.741-1.802-3.71-3.646-5.924-5.47c-2.952-2.431-6.339-4.824-10.204-7.026 c-1.877-1.07-3.873-2.092-5.98-3.055c-0.062-0.028-0.118-0.059-0.18-0.087c-9.792-4.44-22.106-7.529-37.416-7.529 s-27.624,3.089-37.416,7.529c-0.338,0.153-0.653,0.318-0.985,0.474c-1.431,0.674-2.806,1.376-4.128,2.101 c-0.716,0.393-1.417,0.792-2.101,1.197c-3.421,2.027-6.475,4.191-9.15,6.395c-2.213,1.823-4.182,3.668-5.924,5.47 c-1.161,1.201-2.22,2.384-3.184,3.527c-0.964,1.144-1.832,2.25-2.609,3.299c-0.778,1.049-1.464,2.04-2.065,2.955 c-0.557,0.848-1.033,1.622-1.447,2.324c-0.033,0.056-0.073,0.119-0.104,0.174c-0.435,0.744-0.79,1.392-1.07,1.926 c-0.559,1.068-0.818,1.678-0.818,1.678v0.398c18.285,17.927,43.322,28.985,70.945,28.985c27.678,0,52.761-11.103,71.055-29.095 v-0.289c0,0-0.619-1.45-1.992-3.778C174.594,173.238,174.117,172.463,173.561,171.615z"
                                />
                                <path
                                    fill="#FFFFFF"
                                    d="M106.002,125.5c2.645,0,5.212-0.253,7.68-0.737c1.234-0.242,2.443-0.542,3.624-0.896 c1.772-0.532,3.482-1.188,5.12-1.958c2.184-1.027,4.242-2.258,6.15-3.67c2.863-2.119,5.39-4.646,7.509-7.509 c0.706-0.954,1.367-1.945,1.98-2.971c0.919-1.539,1.729-3.155,2.422-4.84c0.462-1.123,0.872-2.277,1.226-3.458 c0.177-0.591,0.341-1.188,0.49-1.792c0.299-1.208,0.542-2.443,0.725-3.701c0.275-1.887,0.417-3.827,0.417-5.811 c0-1.984-0.142-3.925-0.417-5.811c-0.184-1.258-0.426-2.493-0.725-3.701c-0.15-0.604-0.313-1.202-0.49-1.793 c-0.354-1.181-0.764-2.335-1.226-3.458c-0.693-1.685-1.504-3.301-2.422-4.84c-0.613-1.026-1.274-2.017-1.98-2.971 c-2.119-2.863-4.646-5.39-7.509-7.509c-1.909-1.412-3.966-2.643-6.15-3.67c-1.638-0.77-3.348-1.426-5.12-1.958 c-1.181-0.355-2.39-0.655-3.624-0.896c-2.468-0.484-5.035-0.737-7.68-0.737c-21.162,0-37.345,16.183-37.345,37.345 C68.657,109.317,84.84,125.5,106.002,125.5z"
                                />
                            </svg>
                            <div className={classes.profile_info}>
                                <p className={classes.profile_name}>{!!name ? name : !!mobileNo ? mobileNo : "Whatsapp Messages"}</p>
                                {!!name && !!mobileNo && !isMasked && (
                                    <p className={classes.profile_number}>{`+${mobileNo?.slice(0, 2) ?? ""} ${mobileNo?.slice(2) ?? ""}`}</p>
                                )}
                            </div>
                        </div>
                    </div>
                    <button disabled={loading} onClick={fetchDetails} className={`${classes.refresh_btn} ${loading && classes.loading}`}>
                        <VscRefresh />
                    </button>
                </div>

                {/* Loader */}
                <div
                    style={{
                        opacity: loading || isTabChanging ? 1 : 0,
                        pointerEvents: loading || isTabChanging ? "all" : "none",
                        zIndex: isTabChanging ? "9" : loading ? "998" : "-1",
                    }}
                    className={classes.loader}
                >
                    <TbLoader2 />
                </div>

                <div style={{ zIndex: isTabChanging ? "999" : "0" }} className={classes.tab_wrapper}>
                    <div className={classes.tabs}>
                        {["Full Chat", "Replies Only"].map((item, index) => (
                            <button
                                disabled={loading || isTabChanging || activeTab === index}
                                key={index}
                                onClick={() => onTabChange(index)}
                                className={activeTab === index ? classes.active_tab : classes.tab}
                            >
                                {item}
                            </button>
                        ))}
                        <span style={{ left: `${activeTab * 50}%` }} className={classes.active_tab_indicator} />
                    </div>
                </div>

                <div className={classes.scroll_content_wrapper}>
                    {!!displatChats?.length && !loading && !isTabChanging && (
                        <InfiniteScroll
                            dataLength={displatChats.length}
                            next={handleNext}
                            className={classes.whatsapp_chat_wrapper}
                            hasMore={totalPageRef.current !== currentPageRef.current}
                            loader={
                                <p className="text-muted text-center m-0 py-3" style={{ fontSize: "0.8rem" }}>
                                    Loading...
                                </p>
                            }
                            endMessage={
                                <p className="text-muted text-center m-0 pt-3" style={{ fontSize: "0.8rem" }}>
                                    ...... No more messages ......
                                </p>
                            }
                            height={
                                withSendMessage
                                    ? sendMessageDisabled
                                        ? "calc(100vh - 3.5rem - 5rem - 3rem)"
                                        : "calc(100vh - 3.5rem - 7rem - 3rem)"
                                    : "calc(100vh - 3.5rem - 3rem)"
                            }
                            scrollThreshold={0.5}
                        >
                            {displatChats?.map((item, i) => {
                                let nextMsg = !!displatChats?.[i + 1] ? displatChats?.[i + 1] : {};

                                const hideTail =
                                    nextMsg?.owner !== item?.owner
                                        ? false
                                        : !!nextMsg.created && !!item?.created
                                        ? format(new Date(nextMsg.created), "yyyy-MM-dd") === format(new Date(item?.created), "yyyy-MM-dd")
                                        : false;

                                const showDate =
                                    !!prevDateRef.current && !!item?.created && i !== 0
                                        ? format(new Date(prevDateRef.current), "yyyy-MM-dd") !== format(new Date(item?.created), "yyyy-MM-dd")
                                        : true;

                                prevDateRef.current = item?.created;
                                return (
                                    <React.Fragment key={i}>
                                        {showDate && (
                                            <p className={classes.chat_date}>{!!item?.created ? format(new Date(item?.created), "eee, dd MMM yyyy") : ""}</p>
                                        )}
                                        <div
                                            className={`${item?.owner === "Wati" ? classes.from_me : classes.from_them} ${
                                                hideTail && `${classes.no_tail} ${classes.margin_b_none}`
                                            }`}
                                        >
                                            <Attachment file={item?.file_name} />
                                            <div dangerouslySetInnerHTML={{ __html: formatText(item?.text) }} className={classes.chat_content} />
                                            <p className={classes.chat_time}>
                                                {!!item?.created ? format(new Date(item?.created), "hh:mm aaa") : ""}      
                                                {""}{" "}                                    
                                                {item?.owner == "Wati" && (item?.wati_status == "2" ? <IoCheckmarkDone color="blue" /> : item?.wati_status == "1" ? <IoCheckmarkDone /> : <IoCheckmark />)}
                                            </p>
                                        </div>
                                    </React.Fragment>
                                );
                            })}
                        </InfiniteScroll>
                    )}

                    {!displatChats?.length && !loading && (
                        <div>
                            <p style={{ textAlign: "center", padding: "20px" }}>No Chat History found</p>
                        </div>
                    )}
                </div>

                {!!withSendMessage && show && (
                    <SendMessage number={mobileNo} onSend={fetchDetails} isCandidate={isCandidate} isDisabled={sendMessageDisabled} attachments={attachments} />
                )}
            </div>
        </>
    );
}
