import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { RiCloseCircleFill } from "react-icons/ri";

import { getSmsHistory } from "../../API";
import ContentLoader from "../ContentLoader";
import classes from "./style.module.css";

export default function SmsHistory({ show, setShow = () => {}, customerMobileNo }) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const closeModal = () => {
        setShow(false);
    };

    const fetchDetails = async () => {
        if (!customerMobileNo) return;
        setLoading(true);

        const res = await getSmsHistory({ mobile_number: customerMobileNo });

        if (res?.data?.length > 0) {
            setData(res?.data?.slice(0, 30));
        } else setData([]);
        setLoading(false);
    };

    useEffect(() => {
        if (!!customerMobileNo && !!show) fetchDetails();
    }, [customerMobileNo, show]);

    return (
        <>
            <div onClick={() => closeModal()} className={`${classes.wrapper} ${show ? classes.show : classes.hidden}`} />

            <div className={`${classes.sidepanel} ${show ? classes.show : classes.hidden}`}>
                <div className={classes.sidepanel_header}>
                    <p>SMS History</p>
                    <button onClick={() => closeModal()}>
                        <RiCloseCircleFill />
                    </button>
                </div>
                <div className={classes.scroll_content}>
                    {!!data?.length && !loading && (
                        <div className={classes.message_list_wrapper}>
                            {data?.map((item, index) => (
                                <div key={index} className={classes.message_wrapper}>
                                    <p className={classes.time}>{!!item?.created_date ? format(new Date(item?.created_date), "PPp") : ""}</p>
                                    <p className={classes.message}>{item?.message_body}</p>
                                </div>
                            ))}
                        </div>
                    )}

                    {!data?.length && !loading && (
                        <div>
                            <p style={{ textAlign: "center", padding: "20px" }}>No SMS History found</p>
                        </div>
                    )}

                    {loading && <ContentLoader />}
                </div>
            </div>
        </>
    );
}
