export default function getFullForm(shortform) {
    if (shortform === "WN") return "Wrong Number";
    if (shortform === "SW") return "Switched Off";
    if (shortform === "RNR") return "Ringing, No Response";
    if (shortform === "PTP") return "Promise To Pay";
    if (shortform === "BPTP") return "Broken PTP";
    if (shortform === "DIS") return "Dispute";
    if (shortform === "SUR") return "Surender";
    if (shortform === "Paid") return "Paid";
    if (shortform === "PN") return "Paying Now";
    if (shortform === "CB") return "Call Back";
    if (shortform === "SETL") return "Want Settlement";
    if (shortform === "LANG") return "Language Barrier";
    if (shortform === "DND") return "Do Not Disturb";
    if (shortform === "RTP") return "Refuse To Pay";
    if (shortform === "NONE" || shortform === "NCY") return "Not Captured Yet";
    if (shortform === "CBN") return "Call Back Notice";
    if (shortform === "ITP") return "Interested To Pay";
    return shortform;
}
