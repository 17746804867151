import React from "react";
import classes from "./style.module.css";

/*
    // STEPS
    const steps = [
        { 
            title: "Step #1", 
            completed: false, 
            key: "unique_key",
            is_disabled: false (optional)
        },
        .....
    ]

    // ACTIVE STEP
    activeStep = "unique_key"

    // SET ACTIVE STEP
    stateUpdationFunction

*/

export default function Steps({ activeStep = null, setActiveStep = () => {}, steps = [] }) {
    return (
        <div className={classes.steps} style={{ gridTemplateColumns: `repeat(${3 * steps?.length - 1}, 3.25rem)` }}>
            {steps?.map(({ title, completed, key, is_disabled }, index) => {
                const disabled = (index > 0 && !steps[index - 1].completed) || !!is_disabled;

                return (
                    <React.Fragment key={index}>
                        {index !== 0 && <div className={classes.step_separator} />}

                        <div
                            onClick={() => (!disabled ? setActiveStep(key) : {})}
                            className={`${classes.step} ${activeStep === key && classes.active_step} ${disabled && classes.step_disabled}`}
                        >
                            <div className={classes.step_count}>
                                <svg
                                    className={completed ? classes.step_completed : classes.step_not_completed}
                                    fill={activeStep === key ? "#0a7aff" : "#409E7B"}
                                    viewBox="0 0 34 34"
                                >
                                    <path
                                        d="M17 0C7.626 0 0 7.626 0 17c0 9.374 7.626 17 17 17 9.374 0 17-7.626 17-17 0-9.374-7.626-17-17-17zm8.135 13.995-8.757 8.757a2.118 2.118 0 0 1-1.503.623 2.118 2.118 0 0 1-1.502-.623l-4.508-4.507a2.124 2.124 0 1 1 3.005-3.005l3.005 3.005 7.255-7.255a2.124 2.124 0 1 1 3.005 3.005z"
                                        fillRule="nonzero"
                                    />
                                </svg>

                                <svg
                                    className={!completed ? classes.step_completed : classes.step_not_completed}
                                    fill={activeStep === key ? "#0a7aff" : "#727272"}
                                    viewBox="0 0 38 38"
                                >
                                    <text x="0" y="32" style={{ fontSize: "32px", fontWeight: "bold", lineHeight: "0" }}>
                                        {String(index + 1)?.padStart(2, "0")}
                                    </text>
                                </svg>
                            </div>

                            <p className={classes.step_title}>{title}</p>
                        </div>
                    </React.Fragment>
                );
            })}
        </div>
    );
}
