import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Table, Dropdown, Pagination, Spinner } from 'react-bootstrap';
import { FaSearch, FaInfoCircle, FaEllipsisV } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { getMasterCampaign } from '../../../API/adminApis';
import { format } from 'date-fns';
import ContentLoader from '../../../Components/ContentLoader';

const filterDefaults = {
  campaign_id: "",
  is_completed: "",
  client_id: "",
  channel: "",
  campaign_status: "",
  campaign_type: "notice",
  category: "",
  data_selection_criteria: "batch",
  start_date: null,
  end_date: null,
};

const ScheduledBroadcast = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10); // Rows per page
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [data, setData] = useState([]); // Data fetched from API
  const [filter, setFilter] = useState(filterDefaults);
  const [loading, setLoading] = useState(false);

  const getCampaigns = async () => {
    try {
      setLoading(true);
      const res = await getMasterCampaign({
        ...filter,
        start_date: filter?.start_date ? format(new Date(filter?.start_date), 'yyyy-MM-dd') : null,
        end_date: filter?.end_date ? format(new Date(filter?.end_date), 'yyyy-MM-dd') : null,
        page: currentPage,
      });
      if (res && res.data) {
        setData(res.data);
      } else {
        console.error('Unable to fetch de`tails');
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCampaigns();
  }, [filter, currentPage]);

  // Filter the data based on the search term
  const filteredBroadcasts = data.filter(broadcast =>
    broadcast.channel_template.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination logic
  const totalPages = Math.ceil(filteredBroadcasts.length / rowsPerPage);
  const currentBroadcasts = filteredBroadcasts.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when rows per page change
  };

  return (
    <Container fluid className="py-4 bg-light">
      <Row className="mb-4 align-items-center">
        <Col>
          <h2 className="mb-0">Scheduled Broadcast</h2>
        </Col>
        <Col xs="auto" className="d-flex align-items-center">
          <Form.Group className="mb-0 me-3 position-relative">
            <Form.Control
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <FaSearch className="position-absolute top-50 end-0 translate-middle-y me-2 text-muted" />
          </Form.Group>
          <div className="d-flex align-items-center me-3">
            <span className="me-2">Messaging-Limit:</span>
            <FaInfoCircle className="text-muted" />
          </div>
          <Link to="/whatsapp/new-broadcast">
            <Button variant="primary">New Broadcast</Button>
          </Link>
        </Col>
      </Row>

      {loading ? (
        <ContentLoader/>
      ) : (
        <>
          <Table striped bordered hover className="bg-white">
            <thead>
              <tr>
                <th>Broadcast Name</th>
                <th>Scheduled</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentBroadcasts.length > 0 ? (
                currentBroadcasts.map((item) => (
                  <tr key={item.id}>
                    <td>{item.channel_template}</td>
                    <td>{format(new Date(item.created_at), "dd MMM yyyy | p")}</td>
                    <td>
                      <Dropdown align="end">
                        <Dropdown.Toggle variant="light" id={`dropdown-${item.id}`}>
                          <FaEllipsisV />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item href="#/edit">Edit</Dropdown.Item>
                          <Dropdown.Item href="#/delete">Delete</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="text-center">No broadcasts found</td>
                </tr>
              )}
            </tbody>
          </Table>

          <Row className="mt-4 align-items-center">
            <Col xs="auto">
              <Form.Group className="d-flex align-items-center">
                <Form.Label className="me-2 mb-0">Rows per page:</Form.Label>
                <Form.Select
                  value={rowsPerPage}
                  onChange={handleRowsPerPageChange}
                  style={{ width: '80px' }}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Pagination className="justify-content-end">
                <Pagination.Prev
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                />
                {[...Array(totalPages).keys()].map((number) => (
                  <Pagination.Item
                    key={number + 1}
                    active={number + 1 === currentPage}
                    onClick={() => paginate(number + 1)}
                  >
                    {number + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default ScheduledBroadcast;
