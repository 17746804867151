import { format } from "date-fns";
import AudioPlayer from "material-ui-audio-player";
import React, { useEffect, useState } from "react";
import { RiCloseCircleFill } from "react-icons/ri";
import { getCallHistory } from "../../../../API/agentApis";
import ContentLoader from "../../../../Components/ContentLoader";
import classes from "./style.module.css";
import { isEmpty } from "../../../../Functions/isEmpty";
import { downloadMediaUrl } from "../../../../API";

export default function CallHistory({ show, setShow = () => {}, customerMobileNo }) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const closeModal = () => {
        setShow(false);
    };

    const fetchDetails = async () => {
        if (!customerMobileNo) return;
        setLoading(true);

        const res = await getCallHistory({ to_number: customerMobileNo });

        if (res?.length > 0) {
            setData(res);
        } else setData([]);
        setLoading(false);
    };

    useEffect(() => {
        if (!!customerMobileNo) fetchDetails();
    }, [customerMobileNo]);

    return (
        <>
            <div onClick={() => closeModal()} className={`${classes.wrapper} ${show ? classes.show : classes.hidden}`} />

            <div className={`${classes.sidepanel} ${show ? classes.show : classes.hidden}`}>
                <div className={classes.sidepanel_header}>
                    <p>Call History</p>
                    <button onClick={() => closeModal()}>
                        <RiCloseCircleFill />
                    </button>
                </div>
                <div className={classes.scroll_content}>
                    {!!data?.length && !loading && (
                        <div className={classes.message_list_wrapper}>
                            <div className="account_extra_call">
                                {data?.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <p className="title-hr mt-2 mb-4">
                                            <span>{!!item?.StartTime ? format(new Date(item?.StartTime), "do MMM yyyy") : ""}</span>
                                        </p>

                                        <div
                                            className={`audio-player ${
                                                index % 3 === 0 ? classes.record_orange : index % 3 === 1 ? classes.record_yellow : classes.record_purple
                                            }`}
                                        >
                                            <AudioPlayer
                                                elevation={1}
                                                width="100%"
                                                variation="default"
                                                spacing={3}
                                                autoplay={false}
                                                order="standart"
                                                preload="auto"
                                                volume={false}
                                                src={!isEmpty(item?.RecordingUrl) ? downloadMediaUrl(item.RecordingUrl) : ""}
                                            />
                                        </div>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    )}

                    {!data?.length && !loading && (
                        <div>
                            <p style={{ textAlign: "center", padding: "20px" }}>No Call Records found</p>
                        </div>
                    )}

                    {loading && <ContentLoader />}
                </div>
            </div>
        </>
    );
}
