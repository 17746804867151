import React from "react";
import { format } from "date-fns";
import { IoCloseCircle } from "react-icons/io5";

import InTransitFalse from "../../../../Static/RawImages/delivery_statuses/in_transit_false.svg";
import InTransitTrue from "../../../../Static/RawImages/delivery_statuses/in_transit_true.svg";
import DeliveredFalse from "../../../../Static/RawImages/delivery_statuses/delivered_false.svg";
import DeliveredTrue from "../../../../Static/RawImages/delivery_statuses/delivered_true.svg";
import DispatchedFalse from "../../../../Static/RawImages/delivery_statuses/dispatched_false.svg";
import DispatchedTrue from "../../../../Static/RawImages/delivery_statuses/dispatched_true.svg";

// status keys
const DELIVERED = "Delivered";
const IN_TRANSIT = "In Transit";
const OUT_FOR_DELIVERY = "Out for Delivery";
const DISPATCHED = "Dispatched";

const truckIcon = (
    <svg width="33" height="33" viewBox="0 0 33 33" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <circle fill="#68ADDC" cx="16.5" cy="16.5" r="16.5" />
            <path
                d="M27.126 17.113V17.1a.484.484 0 0 0-.487-.476h-.128l-1.972-3.813a.484.484 0 0 0-.432-.261H20.74l.128-1.019a1.398 1.398 0 0 0-.354-1.082 1.388 1.388 0 0 0-1.04-.45H9.024a.516.516 0 0 0-.511.465l-.105.84h5.954c.386 0 .692.313.681.7a.723.723 0 0 1-.718.7H12.34l.001.002H6.508c-.273 0-.5.222-.508.495a.48.48 0 0 0 .482.495h7.721a.726.726 0 0 1 .616.74.774.774 0 0 1-.763.75H8.733a.516.516 0 0 0-.513.5c-.007.277.21.5.487.5h5.26c.364.051.637.363.627.742a.774.774 0 0 1-.77.75H7.283a.516.516 0 0 0-.513.5c-.008.277.21.5.487.5h.437l-.171 1.792c-.037.407.09.79.354 1.082.265.29.634.45 1.04.45h.378a2.181 2.181 0 0 0 2.159 1.806c1.088 0 2.02-.778 2.253-1.806h4.71c.382 0 .742-.146 1.025-.384.259.248.601.384.976.384h.077a2.181 2.181 0 0 0 2.159 1.806c1.088 0 2.02-.778 2.253-1.806h.31c.81 0 1.53-.659 1.602-1.468l.305-3.376v-.013l.002-.02v-.013zM11.48 22.809c-.69 0-1.235-.561-1.217-1.25.018-.69.593-1.25 1.282-1.25.69 0 1.236.56 1.218 1.25a1.292 1.292 0 0 1-1.283 1.25zm11.2 0c-.69 0-1.235-.561-1.217-1.25.018-.69.593-1.25 1.282-1.25.69 0 1.236.56 1.218 1.25a1.292 1.292 0 0 1-1.283 1.25zm3.143-2.338a.602.602 0 0 1-.579.532h-.336a2.181 2.181 0 0 0-2.136-1.694 2.33 2.33 0 0 0-2.225 1.694h-.103a.425.425 0 0 1-.32-.135.43.43 0 0 1-.105-.333l.63-6.985h1.449l-.23 2.543c-.037.407.09.791.354 1.082.265.29.635.45 1.04.45h2.818l-.257 2.846z"
                fill="#FFF"
                fillRule="nonzero"
            />
        </g>
    </svg>
);

const tickIcon = (
    <svg width="33" height="33" viewBox="0 0 33 33" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.5 0C7.402 0 0 7.402 0 16.5 0 25.598 7.402 33 16.5 33 25.598 33 33 25.598 33 16.5 33 7.402 25.598 0 16.5 0zm7.896 13.625-8.5 8.753c-.403.415-.931.622-1.459.622a2.026 2.026 0 0 1-1.458-.622l-4.375-4.505a2.168 2.168 0 0 1 0-3.003 2.02 2.02 0 0 1 2.917 0l2.916 3.003 7.042-7.25a2.02 2.02 0 0 1 2.917 0c.805.829.805 2.173 0 3.002z"
            fill="#4BBC85"
            fillRule="nonzero"
        />
    </svg>
);

const otherIcon = (
    <svg width="33" height="33" viewBox="0 0 33 33" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
            <circle fill="#F3A455" cx="16.5" cy="16.5" r="16.5" />
            <g fill="#FFF" fillRule="nonzero">
                <path d="M8.48 24.32h15.36a.48.48 0 0 0 .48-.48V8.48a.48.48 0 0 0-.48-.48h-4.8v8.16a.48.48 0 0 1-.727.411L16.16 15.28l-2.153 1.291a.48.48 0 0 1-.727-.411V8h-4.8a.48.48 0 0 0-.48.48v15.36c0 .265.215.48.48.48z" />
                <path d="M15.913 14.309a.48.48 0 0 1 .494 0l1.673 1.003V8h-3.84v7.312l1.673-1.003z" />
            </g>
        </g>
    </svg>
);

export default function PostalDeliveryTracking({ show, setShow, data, loading = true }) {
    const getCurrentState = () => {
        let status = data?.current_status;
        if (status === DELIVERED) return { dispatched: true, inTransit: true, delivered: true };
        if (status === IN_TRANSIT || status === OUT_FOR_DELIVERY) return { dispatched: true, inTransit: true, delivered: false };
        if (status === DISPATCHED) return { dispatched: true, inTransit: false, delivered: false };
    };

    return (
        <div
            className="al-popup"
            style={{
                transform: show ? "translateX(0)" : "translateX(110%)",
                pointerEvents: show ? "auto" : "none",
            }}
        >
            {/* header */}
            <div className="al-close">
                <IoCloseCircle fill="#999999" size={50} onClick={() => setShow(false)} />
            </div>
            <div className="al-head">
                <h2>Tracking of the letters</h2>
            </div>

            {/* body */}

            {/* Tracking states */}
            <div className="traking-step d-flex justify-between">
                <TrackingItem
                    activeImage={DispatchedTrue}
                    inActiveImage={DispatchedFalse}
                    label="Dispatched"
                    className={data?.current_status === DISPATCHED ? "traking-current" : getCurrentState()?.dispatched ? "traking-done" : "traking-pending"}
                    isDone={getCurrentState()?.dispatched}
                />
                <TrackingStep isDone={getCurrentState()?.inTransit} />
                <TrackingItem
                    activeImage={InTransitTrue}
                    inActiveImage={InTransitFalse}
                    label="In Transit"
                    className={data?.current_status === IN_TRANSIT ? "traking-current" : getCurrentState()?.inTransit ? "traking-done" : "traking-pending"}
                    isDone={getCurrentState()?.inTransit}
                />
                <TrackingStep isDone={getCurrentState()?.delivered} />
                <TrackingItem
                    activeImage={DeliveredTrue}
                    inActiveImage={DeliveredFalse}
                    label="Delivered"
                    className={data?.current_status === DELIVERED ? "traking-current" : "traking-pending"}
                    isDone={getCurrentState()?.delivered}
                />
            </div>

            {/* Expected Delivery Date */}
            <div className="traking-info">
                <p className="traking-info-text-1">Excepted Delivery Date</p>
                {/* Aug 19th, 2021  |  Wednesday */}
                <p className="traking-info-text-2">
                    {!!data?.expected_delivery_date ? format(new Date(data?.expected_delivery_date), "MMM do, yyyy  |  EEEE") : ""}
                </p>
            </div>

            {/* Tracking Info */}
            {!!data?.scan_data && !loading && (
                <div className="traking-time-stamp">
                    {Object.values(data?.scan_data).map((item, index) => (
                        <div className="traking-time-date" key={index}>
                            <div className="row d-flex align-center">
                                <div className="col-4 traking-time-one">
                                    {format(new Date(item?.time), "do MMM")}
                                    <br />
                                    {format(new Date(item?.time), "p")}
                                </div>
                                <div className="col-2 traking-time-two">
                                    {(index === 0 && item?.status_detail?.indexOf("Deliver") !== -1) || item?.status_detail === "Item Delivery Confirmed"
                                        ? tickIcon
                                        : item?.status_detail === "Out for Delivery"
                                        ? truckIcon
                                        : otherIcon}
                                </div>
                                <div className="col-6 traking-time-three">
                                    {item?.status_detail}
                                    <br />
                                    {item?.location}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {!data?.scan_data && !loading && <div style={{ textAlign: "center", marginTop: "32px" }}> No Data Found </div>}
            {!!loading && <div style={{ textAlign: "center", marginTop: "32px" }}>Loading...</div>}
        </div>
    );
}

function TrackingItem({ isDone = false, activeImage, inActiveImage, label = "", className = "" }) {
    return (
        <div className={`text-center trak-step ${className}`}>
            <div className="traking-img">
                <img src={isDone ? activeImage : inActiveImage} alt={label} />
            </div>
            <p className="mb-0">{label}</p>
        </div>
    );
}
function TrackingStep({ isDone = false }) {
    return <div className={isDone ? "traking-line traking-line-active" : "traking-line"} />;
}
