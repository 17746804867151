import { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa6";
import { getCampaignsCallDetail } from "../../../apis";
import ContentLoader from "../../../Components/ContentLoader";
import ReactAudioPlayer from "react-audio-player";

export default function CallDetails({ show, toggleShow, sid, callStatus, ...props }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getCampaignsCallDetail({
        call_sid: sid || "",
      });

      if (res.success) {
        setData(res.data?.data || {});
      }
    };
    if (!sid) setData({});
    else fetchData();

    return () => setData(null);
  }, []);

  return (
    <Offcanvas show={show} onHide={toggleShow} {...props} style={{ width: "28rem", paddingTop: "0.75rem" }}>
      <Offcanvas.Header>
        <FaArrowLeft size={"1.5rem"} fontWeight={"800"} style={{ marginRight: "1rem" }} role="button" onClick={toggleShow} />
        <Offcanvas.Title style={{ fontWeight: "600", fontSize: "calc(1.275rem + .3vw)" }} className="fw-600 fs-4">
          Call Record
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!data ? (
          <div style={{ position: "relative", flex: 1 }}>
            <ContentLoader />
          </div>
        ) : !data.id ? (
          <p style={{ textAlign: "center", fontWeight: 500, padding: "10rem 0" }}>No Data Found! </p>
        ) : (
          <Details data={data} callStatus={callStatus} />
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
}

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

function Details({ data, callStatus }) {
  const date = new Date(data.call_date);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center", padding: "1rem 0", alignContent: "center" }}>
        <div style={{ textAlign: "center", padding: "0 1rem", borderRight: "3px solid #e0e0e0" }}>
          <p style={{ fontWeight: "bold", margin: 0 }}>
            {date.getDate()} {months[date.getMonth()]}
          </p>
          <p style={{ margin: 0 }}>
            {date.toLocaleTimeString("en-us", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </p>
        </div>
        <div style={{ flex: "1", textTransform: "uppercase" }}>
          <p style={{ fontWeight: "800", padding: "0 1rem", color: statusColor[callStatus] || "#f29567" }}>{callStatus}</p>
        </div>
      </div>
      <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", margin: "1.5rem 0" }}>
        <ReactAudioPlayer src={data.call_recording} autoPlay controls />
      </div>
      <div
        style={{
          margin: "1rem",
          background: "#eceaea",
          fontWeight: "600",
          fontSize: "1.05rem",
          padding: "0.8rem",
          borderRadius: "10px",
        }}
      >
        {data.agent_name && <p> Agent Name: {data.agent_name}</p>}
        {data.call_quality && <p> Call Quality: {data.call_quality}</p>}
        {data.agent_disposition && <p> Agent Disposition: {data.agent_disposition}</p>}

        <div>
          {data.audio_analysis && (
            <>
              <hr style={{ margin: "2rem 0" }} />
              <h5 style={{ fontWeight: "lighter", color: "gray" }}>AI Disposition Analysis</h5>
              <p>{data.audio_analysis}</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const statusColor = {
  busy: "#ff8616",
  "no-answer": "#f83131",
  completed: "#609b47",
  "failed-no-attempt": "#f29567",
  failed: "#f29567",
};

function PlaySvg({ props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} xmlSpace="preserve" fill="#ff7b00" stroke="#ff7b00" viewBox="0 0 60 60" {...props}>
      <path d="m45.563 29.174-22-15A1 1 0 0 0 22 15v30a.999.999 0 0 0 1.563.826l22-15a1 1 0 0 0 0-1.652zM24 43.107V16.893L43.225 30 24 43.107z" />
      <path d="M30 0C13.458 0 0 13.458 0 30s13.458 30 30 30 30-13.458 30-30S46.542 0 30 0zm0 58C14.561 58 2 45.439 2 30S14.561 2 30 2s28 12.561 28 28-12.561 28-28 28z" />
    </svg>
  );
}
