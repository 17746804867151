import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { postAddAltContactInfo } from "../../../API/adminApis";
import useForm from "../../../hooks/useForm";
import { isValidEmail, isValidMobileNumber } from "../../../Functions/validations";
import { showToast } from "../../../utils/handleToast";
import useAuthStore from "../../../store/authStore";

export default function AddAlternateContact({ lan, client_id }) {
    const userData = useAuthStore((state) => state.userData);

    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const [params, handleParams, resetParams] = useForm({
        email: "",
        mobile_number: "",
    });

    const closeModal = () => {
        resetParams();
        setShowModal(false);
    };

    const addDetails = async () => {
        if (!lan) {
            showToast({ variant: "warning", msg: "Loan account number not found for this customer" });
            return;
        }
        if (!userData?.user_id) {
            showToast({ variant: "warning", msg: "User not found" });
            return;
        }

        if (!client_id) {
            showToast({ variant: "warning", msg: "Client Not Found" });
            return;
        }

        if (!params?.email && !params?.mobile_number) {
            showToast({ variant: "warning", msg: "Please enter either email or mobile number" });
            return;
        }

        if (!!params?.email && !isValidEmail(params?.email)) {
            showToast({ variant: "warning", msg: "Please enter a valid email" });
            return;
        }

        if (!!params?.mobile_number && !isValidMobileNumber(params?.mobile_number)) {
            showToast({ variant: "warning", msg: "Please enter a valid mobile number" });
            return;
        }

        setLoading(true);

        const body = {
            client_id,
            reference: "alternate",
            email: !!params?.email ? params.email : null,
            mobile_number: !!params?.mobile_number ? params.mobile_number : null,
            loan_account_no: lan,
            user_id: userData?.user_id,
            source: "agent",
        };

        const res = await postAddAltContactInfo(body);
        if (res) {
            showToast({ variant: "success", msg: res?.message ?? "Action Successful" });
            closeModal();
        }

        setLoading(false);
    };

    return (
        <>
            <a className="dropdown-item" onClick={() => setShowModal(true)}>
                Add Alternate Contact
            </a>

            <Modal size="sm" show={showModal} backdrop="static">
                <Modal.Header>
                    <Modal.Title>Add Alternate Contact Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-0">
                    <div className="py-3 px-4">
                        <p className="mb-1">Email ID</p>
                        <input
                            className="form-control"
                            type="email"
                            placeholder="Email ID"
                            value={params?.email}
                            onChange={(e) => handleParams("email", e.target.value)}
                        />
                    </div>
                    <div className="py-3 px-4 bg-light">
                        <p className="mb-1 tx-12">Mobile Number</p>
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Enter 10 digit Mobile Number"
                            maxLength={10}
                            value={params?.mobile_number}
                            onChange={(e) => {
                                if (!!e.target.value && isNaN(e.target.value)) return;
                                handleParams("mobile_number", e.target.value.trim());
                            }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={closeModal}>
                        Close
                    </button>
                    <button disabled={loading} className="btn btn-primary" onClick={addDetails}>
                        {loading ? "Please wait..." : "Add"}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
