import React, { useEffect, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import LineChart from "../../Components/Charts/LineChart";
import MultiBarCircularChart from "../../Components/Charts/MultiBarCircularChart";
import StackedHorizontalBarChart from "../../Components/Charts/StackedHorizontalBarChart";
import MultiCircularMixedChart from "../../Components/Charts/MultiCircularMixedChart";
import BarChart from "../../Components/Charts/BarChart";
import BubbleChart from "../../Components/Charts/BubbleChart";
import Dropdown from "react-bootstrap/Dropdown";
import Arrow from "../../Static/RawImages/arrow.png";
import ContentLoader from "../../Components/ContentLoader";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import DatePicker from "../../ui/forms/DatePicker";

import moment from "moment";
import { getProductList } from "../../API/panelApis";
import {
    getLiveFeed,
    getTotalCollection,
    getDataWiseBatchData,
    getDispositionWiseReport,
    getRiskAssessment,
    getBatchWiseAalysisData,
    getAgingReportAnalysisData,
    fetchCollectionBatch,
    getDispMapping,
} from "../../API";
import getFullForm from "../../Functions/getFullForm";
import roundFigure from "../../Functions/roundFigureAmount";
import dateFormat from "../../Functions/getDashboardCollectionDateFormat";
import NcDispo from "../../Static/Images/NcDispo";
import PaidDispo from "../../Static/Images/PaidDispo";
import PtpDispo from "../../Static/Images/PtpDispo";
import SwDispo from "../../Static/Images/SwDispo";
import GeneDispo from "../../Static/Images/GeneDispo";
import BptpDispo from "../../Static/Images/BptpDispo";

import Sort from "../../Static/RawImages/sort.png";
import { TiArrowSortedUp, TiArrowSortedDown, TiChevronLeft, TiChevronRight } from "react-icons/ti";
import NoRecord from "../../Components/NoRecord";
import useAuthStore from "../../store/authStore";
import { compactFormat } from "../../Functions/formatNumber";

function Dashboard() {
    const userData = useAuthStore((state) => state.userData);

    const [collectionData, setCollectionData] = useState(null);
    const [liveFeed, setLiveFeed] = useState([]);
    const [duration, setDuration] = useState("7D");
    const [dispoAtype, setDispoAtype] = useState("amount");
    const [dispoAProd, setDispoAProd] = useState("");
    const [ndispoAProd, setnDispoAProd] = useState("");
    const [dispoAId, setDispoAId] = useState("");
    const [dispoALabel, setDispoALabel] = useState("");

    const [agingType, setAgingType] = useState("amount");
    const [batchAType, setBatchAType] = useState("amount");
    const [batchAProd, setBatchAProd] = useState("");
    const [nbatchAProd, setNBatchAProd] = useState("");
    const [batchAId, setBatchAId] = useState("");
    const [batchALabel, setBatchALabel] = useState("");
    const [agingAId, setAgingAId] = useState("");
    const [agingALabel, setAgingALabel] = useState("");
    const [dispoVal, setDispoVal] = useState(null);
    const [riskAtype, setRiskAtype] = useState("amount");
    const [riskVal, setRiskVal] = useState(null);
    const [riskAProd, setRiskAProd] = useState("");
    const [nriskAProd, setnRiskAProd] = useState("");
    const [riskAId, setRiskAId] = useState("");
    const [riskALabel, setRiskALabel] = useState("");
    const [liveFeedSortOrderUp, setLiveFeedSortOrderUp] = useState(true);
    const [totalLivePages, setTotalLivePages] = useState(0);
    const [livePageNum, setLivePageNum] = useState(1);
    const [BatchIds, setBatchIds] = useState(null);
    // const [productList, setProductList] = useState(null);
    const [dispData, setDisp] = useState(null);

    const [loading, setLoading] = useState(true);
    const [smShow, setSmShow] = useState(false);
    const [products, setProducts] = useState([]);
    const currentYearStart = moment().startOf("year").toDate();

    const [from, setFrom] = React.useState(currentYearStart);
    const [to, setTo] = React.useState(new Date());

    const getName = (val, data1) => {
        let required = data1.find((item) => item.name === val);

        if (required) {
            return required.value;
        }
        return val;
    };

    const getDisp = useCallback(async () => {
        const params = { client_id: userData?.client_id };
        const res = await getDispMapping();
        if (res && res?.data) {
            const data = res?.data.map((item) => {
                const key = Object.keys(item)[0];
                const value = item[key];
                return { name: key, value: value };
            });
            const res2 = await getProductList(params);
            if (res2) {
                let data1 = res2.map((item) => {
                    const name = getName(item, data);
                    return { name: name, val: item };
                });
                setProducts(data1);
            }
            setDisp(data);
        }
    }, []);

    const getData_collection = useCallback(async () => {
        const res = await getTotalCollection();
        setCollectionData(res);
        setLoading(false);
    }, []);
    //fix
    const getData_live = useCallback(async () => {
        let order;
        let page = 1;
        if (liveFeedSortOrderUp === true) {
            order = "id";
        }
        if (liveFeedSortOrderUp === false) {
            order = "-id";
        }
        if (livePageNum >= 1 && livePageNum <= totalLivePages) {
            page = livePageNum;
        }

        const res = await getLiveFeed(order, page);

        if (res) {
            setTotalLivePages(res.count / 20);
            setLiveFeed(res.results);
        }
    }, [liveFeedSortOrderUp, livePageNum, totalLivePages]);

    const getData_dispo = useCallback(async () => {
        let type = null;
        let value = null;

        if (dispoVal) {
            type = dispoVal["type"];
            value = dispoVal["value"];
        }
        const res = await getDispositionWiseReport(dispoAtype, dispoAProd, dispoAId);
        if (res) {
            let data;
            let lableData;
            if (dispoAtype === "account") {
                lableData = Object.values(res).map((val) => {
                    return val.customer_count;
                });
                data = Object.values(res).map((val) => {
                    return { category: getFullForm(val.disposition), value: val.customer_count };
                });
            }
            if (dispoAtype === "amount") {
                lableData = Object.values(res).map((val) => {
                    return val.amount;
                });
                data = Object.values(res).map((val) => {
                    return { category: getFullForm(val.disposition), value: val.amount };
                });
            }

            let lableDataMax = Math.max(...lableData);
            let lableDataMaxRound = roundFigure(lableDataMax);
            let yAxisData = [
                0,
                parseInt((1 / 8) * lableDataMaxRound),
                parseInt((2 / 8) * lableDataMaxRound),
                parseInt((3 / 8) * lableDataMaxRound),
                parseInt((4 / 8) * lableDataMaxRound),
                parseInt((5 / 8) * lableDataMaxRound),
                parseInt((6 / 8) * lableDataMaxRound),
                parseInt((7 / 8) * lableDataMaxRound),
                parseInt(lableDataMaxRound),
            ];

            const parentChart = document.getElementById("dispositionChart");
            ReactDOM.unmountComponentAtNode(parentChart);

            ReactDOM.render(
                <BarChart
                    data={{
                        color: "#1dc4e9",
                        color1: "#1de9b6",
                        maxValue: lableDataMaxRound,
                        data,
                        yAxisData,
                    }}
                    width="800"
                />,
                parentChart
            );
        } else {
            const parentChart = document.getElementById("dispositionChart");
            ReactDOM.unmountComponentAtNode(parentChart);

            ReactDOM.render(<div className="fs-5 text-center fw-bold text-secondary">NO DATA FOUND</div>, parentChart);
        }
    }, [dispoAtype, dispoVal, dispoAProd, dispoAId]);

    const getData_risk = useCallback(async () => {
        function setColor(clr) {
            let color;
            if (clr.toLowerCase() === "low") {
                color = "#d5f6ef";
            }
            if (clr.toLowerCase() === "medium") {
                color = "#9be2dc";
            }
            if (clr.toLowerCase() === "high") {
                color = "#aae5f7";
            }
            return color;
        }
        let type = null;
        let value = null;

        if (riskVal) {
            type = riskVal["type"];
            value = riskVal["value"];
        }
        const res = await getRiskAssessment(riskAtype, riskAProd, riskAId);
        if (res && res !== "No assessment found") {
            let dataAry;
            if (riskAtype === "amount") {
                dataAry = Object.values(res).map((item) => {
                    return item?.amount;
                });
            }
            if (riskAtype === "account") {
                dataAry = Object.values(res).map((item) => {
                    return item?.customer_count;
                });
            }

            let total = 0;
            for (const ele of dataAry) {
                total = ele + total;
            }

            let data;
            if (riskAtype === "amount") {
                data = Object.keys(res).map((item) => {
                    return { name: item, color: setColor(item), value: ((100 * res[item].amount) / total).toFixed(2) };
                });
            }
            if (riskAtype === "account") {
                data = Object.keys(res).map((item) => {
                    return { name: item, color: setColor(item), value: ((100 * res[item].customer_count) / total).toFixed(2) };
                });
            }
            const parentChart = document.getElementById("riskChart");
            ReactDOM.unmountComponentAtNode(parentChart);
            ReactDOM.render(<BubbleChart data={{ data }} />, parentChart);
        } else {
            const parentChart = document.getElementById("riskChart");
            ReactDOM.unmountComponentAtNode(parentChart);

            ReactDOM.render(<div className="fs-5 text-center fw-bold text-secondary">No Assessment found</div>, parentChart);
        }
    }, [riskAtype, riskVal, riskAId, riskAProd]);

    // const getCurrentWeek = () => {
    //     var currentDate = moment();

    //     var weekStart = currentDate.clone().startOf("isoWeek");
    //     var weekEnd = currentDate.clone().endOf("isoWeek");

    //     var days = [];

    //     for (var i = 0; i <= 6; i++) {
    //         days.push(moment(weekStart).add(i, "days").format("YYYY-MM-DD"));
    //     }
    //     return days;
    // };

    const getData_batchReport = useCallback(async () => {
        let ytd = {
            from: moment(from).format("YYYY-MM-DD"),
            to: moment(to).format("YYYY-MM-DD"),
        };
        if (duration !== "") {
            const res = await getDataWiseBatchData(duration, ytd);

            if (res && res?.result && res?.result?.length > 0 && (duration === "7D" || duration === "YTD")) {
                let result = res?.result?.map((item) => {
                    let date = Object.values(item)[0];
                    return { date: date, paid_amount: item.paid_amount };
                });

                result?.reverse();
                let data = result?.map((val) => ({ name: dateFormat(val?.date), value: val?.paid_amount || 0 }));
                const parentChart = document.getElementById("collectionChart");
                ReactDOM.unmountComponentAtNode(parentChart);

                ReactDOM.render(
                    <LineChart
                        data={{
                            data: data,
                            tooltipLabel: "Total Collections ",
                            width: 800,
                            heigth: 300,
                            padding: {
                                left: 40,
                                right: 40,
                                top: 20,
                                bottom: 20,
                            },
                        }}
                    />,
                    parentChart
                );
            } else {
                let result = !!res?.result?.length ? res?.result : [];
                result?.reverse();

                let data = result?.map((val) => ({ name: `${dateFormat(val.date2)}-${dateFormat(val.date1)}`, value: val?.paid_amount || 0 }));

                const parentChart = document.getElementById("collectionChart");
                ReactDOM.unmountComponentAtNode(parentChart);

                ReactDOM.render(
                    !!result?.length ? (
                        <LineChart
                            data={{
                                data: data,
                                tooltipLabel: "Total Collections ",
                                width: 800,
                                heigth: 300,
                                padding: {
                                    left: 40,
                                    right: 40,
                                    top: 20,
                                    bottom: 20,
                                },
                            }}
                        />
                    ) : (
                        <div style={{ height: "400px", overflow: "hidden" }}>
                            <div style={{ transform: "translateY(-30%) scale(0.8)" }}>
                                <NoRecord />
                            </div>
                        </div>
                    ),
                    parentChart
                );
            }
        }
    }, [duration]);

    const getData_BatchWise = useCallback(async () => {
        const resData = await fetchCollectionBatch();
        setBatchIds(
            resData?.data.map((item) => {
                return { value: item?.batch_id, label: item?.batch_name };
            })
        );
        const res = await getBatchWiseAalysisData(batchAType, batchAProd, batchAId);

        if (res) {
            if (batchAType === "amount") {
                const dat = res?.data.dpd_breakup;
                const det = res?.product_breakup;
                const dot = res?.graphs;
                let stackedHorizontalChartData = [
                    {
                        rowData: [
                            {
                                tooltipStartText: "0-30 Days",
                                value: dat.amount_prcnt_of_0_30 ? dat.amount_prcnt_of_0_30 : 0,
                                tooltipEndText: dat.amount_value_of_0_30 ? compactFormat(dat.amount_value_of_0_30) : "0",
                                color: "#b2f1e4",
                            },
                            {
                                tooltipStartText: "30-60 Days",
                                value: dat.amount_prcnt_of_30_60 ? dat.amount_prcnt_of_30_60 : 0,
                                tooltipEndText: dat.amount_value_of_30_60 ? compactFormat(dat.amount_value_of_30_60) : "0",
                                color: "#6bd0bd",
                            },
                            {
                                tooltipStartText: "60-90 Days",
                                value: dat.amount_prcnt_of_60_90 ? dat.amount_prcnt_of_60_90 : 0,
                                tooltipEndText: dat.amount_value_of_60_90 ? compactFormat(dat.amount_value_of_60_90) : "0",
                                color: "#1e897f",
                            },
                            {
                                tooltipStartText: "90-180 Days",
                                value: dat.amount_prcnt_of_90_180 ? dat.amount_prcnt_of_90_180 : 0,
                                tooltipEndText: dat.amount_value_of_90_180 ? compactFormat(dat.amount_value_of_90_180) : "0",
                                color: "#4fbdce",
                            },
                            {
                                tooltipStartText: "180+ Days",
                                value: dat.amount_prcnt_of_180_plus ? dat.amount_prcnt_of_180_plus : 0,
                                tooltipEndText: dat.amount_value_of_180_plus ? compactFormat(dat.amount_value_of_180_plus) : "0",
                                color: "#b2f1e4",
                            },
                        ],
                        color: "#1dc4e9",
                        color1: "#1de9b6",
                        tooltipLabel: "1 ",
                        rowName: "DPD Break-up",
                    },
                    {
                        rowData: [
                            {
                                tooltipStartText: "Auto",
                                tooltipEndText: det.Auto ? compactFormat(det.Auto) : "0",
                                value: det.Auto ? det.Auto : 0,
                                color: "#b2f1e4",
                            },
                            {
                                tooltipStartText: "Business",
                                tooltipEndText: det.Business ? compactFormat(det.Business) : "0",
                                value: det.Business ? det.Business : 0,
                                color: "#6bd0bd",
                            },
                            {
                                tooltipStartText: "Commercial Vehicle",
                                tooltipEndText: det["Commercial Vehicle"] ? compactFormat(det["Commercial Vehicle"]) : "0",
                                value: det["Commercial Vehicle"] ? det["Commercial Vehicle"] : 0,
                                color: "#1e897f",
                            },
                            {
                                tooltipStartText: "Consumer Durable",
                                tooltipEndText: det["Consumer Durable"] ? compactFormat(det["Consumer Durable"]) : "0",
                                value: det["Consumer Durable"] ? det["Consumer Durable"] : 0,
                                color: "#4fbdce",
                            },
                            {
                                tooltipStartText: "Education",
                                tooltipEndText: det.Education ? compactFormat(det.Education) : "0",
                                value: det.Education ? det.Education : 0,
                                color: "#b2f1e4",
                            },
                            {
                                tooltipStartText: "Healthcare",
                                tooltipEndText: det.Healthcare ? compactFormat(det.Healthcare) : "0",
                                value: det.Healthcare ? det.Healthcare : 0,
                                color: "#6bd0bd",
                            },
                            {
                                tooltipStartText: "Two Wheeler",
                                tooltipEndText: res?.product_breakup["Two Wheeler"] ? compactFormat(res.product_breakup["Two Wheeler"]) : "0",
                                value: res?.product_breakup["Two Wheeler"] ? res?.product_breakup["Two Wheeler"] : 0,
                                color: "#1e897f",
                            },
                            {
                                tooltipStartText: "Auto",
                                tooltipEndText: det.Auto ? compactFormat(det.Auto) : "0",
                                value: det.Auto ? det.Auto : 0,
                                color: "#b2f1e4",
                            },
                            {
                                tooltipStartText: "Business",
                                tooltipEndText: det.Business ? compactFormat(det.Business) : "0",
                                value: det.Business ? det.Business : 0,
                                color: "#6bd0bd",
                            },
                            {
                                tooltipStartText: "Personal Loan",
                                tooltipEndText: det["Personal Loan"] ? compactFormat(det["Personal Loan"]) : "0",
                                value: det["Personal Loan"] ? det["Personal Loan"] : 0,
                                color: "#1e897f",
                            },

                            {
                                tooltipStartText: "Bike",
                                tooltipEndText: det.Bike ? compactFormat(det.Bike) : "0",
                                value: det.Bike ? det.Bike : 0,
                                color: "#b2f1e4",
                            },

                            {
                                tooltipStartText: "Car",
                                tooltipEndText: det.Car ? compactFormat(det.Car) : "0",
                                value: det.Car ? det.Car : 0,
                                color: "#1e897f",
                            },
                            {
                                tooltipStartText: "Tractor",
                                tooltipEndText: det.Tractor ? compactFormat(det.Tractor) : "0",
                                value: det.Tractor ? det.Tractor : 0,
                                color: "#6bd0bd",
                            },
                        ],
                        color: "#1dc4e9",
                        color1: "#1de9b6",
                        tooltipLabel: "2 ",
                        rowName: "Product Breakup",
                    },
                ];
                let multiBarCircularChartData = {
                    data: [
                        { name: "Calls-to-Contacts", value: dot.connected_percentage, color: "#359aba" },
                        { name: "Contacts-to-PTP", value: dot.ptp_percentage, color: "#4fbcce" },
                        { name: "Paid", value: dot.paid_percentage, color: "#9debdc" },
                    ],
                    title: "Connected",
                    // subTitle: "Connected Call",
                    tooltipText: "Connected Call",
                };

                const parentChart = document.getElementById("stackedBar");
                const parentChart1 = document.getElementById("multiBar");
                ReactDOM.unmountComponentAtNode(parentChart);
                ReactDOM.unmountComponentAtNode(parentChart1);

                ReactDOM.render(<StackedHorizontalBarChart data={stackedHorizontalChartData} scale={1} />, parentChart);
                ReactDOM.render(<MultiBarCircularChart data={multiBarCircularChartData} />, parentChart1);
            } else if (batchAType === "account") {
                const dat = res?.data.dpd_breakup;
                const det = res?.product_breakup;
                const dot = res?.graphs;
                let stackedHorizontalChartData = [
                    {
                        rowData: [
                            {
                                tooltipStartText: "0-30 Days",
                                value: dat.account_prcnt_of_0_30 ? dat.account_prcnt_of_0_30 : 0,
                                tooltipEndText: dat.account_count_of_0_30 ? compactFormat(dat.account_count_of_0_30) : "0",
                                color: "#b2f1e4",
                            },
                            {
                                tooltipStartText: "30-60 Days",
                                value: dat.account_prcnt_of_30_60 ? dat.account_prcnt_of_30_60 : 0,
                                tooltipEndText: dat.account_count_of_30_60 ? compactFormat(dat.account_count_of_30_60) : "0",
                                color: "#6bd0bd",
                            },
                            {
                                tooltipStartText: "60-90 Days",
                                value: dat.account_prcnt_of_60_90 ? dat.account_prcnt_of_60_90 : 0,
                                tooltipEndText: dat.account_count_of_60_90 ? compactFormat(dat.account_count_of_60_90) : "0",
                                color: "#1e897f",
                            },
                            {
                                tooltipStartText: "90-180 Days",
                                value: dat.account_prcnt_of_90_180 ? dat.account_prcnt_of_90_180 : 0,
                                tooltipEndText: dat.account_count_of_90_180 ? compactFormat(dat.account_count_of_90_180) : "0",
                                color: "#4fbdce",
                            },
                            {
                                tooltipStartText: "180+ Days",
                                value: dat.account_prcnt_of_180_plus ? dat.account_prcnt_of_180_plus : 0,
                                tooltipEndText: dat.account_count_of_180_plus ? compactFormat(dat.account_count_of_180_plus) : "0",
                                color: "#b2f1e4",
                            },
                        ],
                        color: "#1dc4e9",
                        color1: "#1de9b6",
                        tooltipLabel: "1 ",
                        rowName: "DPD Break-up",
                    },
                    {
                        rowData: [
                            {
                                tooltipStartText: "Auto",
                                tooltipEndText: det.Auto ? compactFormat(det.Auto) : "0",
                                value: det.Auto ? det.Auto : 0,
                                color: "#b2f1e4",
                            },
                            {
                                tooltipStartText: "Business",
                                tooltipEndText: det.Business ? compactFormat(det.Business) : "0",
                                value: det.Business ? det.Business : 0,
                                color: "#6bd0bd",
                            },
                            {
                                tooltipStartText: "Commercial Vehicle",
                                tooltipEndText: det["Commercial Vehicle"] ? compactFormat(det["Commercial Vehicle"]) : "0",
                                value: det["Commercial Vehicle"] ? det["Commercial Vehicle"] : 0,
                                color: "#1e897f",
                            },
                            {
                                tooltipStartText: "Consumer Durable",
                                tooltipEndText: det["Consumer Durable"] ? compactFormat(det["Consumer Durable"]) : "0",
                                value: det["Consumer Durable"] ? det["Consumer Durable"] : 0,
                                color: "#4fbdce",
                            },
                            {
                                tooltipStartText: "Education",
                                tooltipEndText: det.Education ? compactFormat(det.Education) : "0",
                                value: det.Education ? det.Education : 0,
                                color: "#b2f1e4",
                            },
                            {
                                tooltipStartText: "Healthcare",
                                tooltipEndText: det.Healthcare ? compactFormat(det.Healthcare) : "0",
                                value: det.Healthcare ? det.Healthcare : 0,
                                color: "#6bd0bd",
                            },
                            {
                                tooltipStartText: "Two Wheeler",
                                tooltipEndText: res?.product_breakup["Two Wheeler"] ? compactFormat(res.product_breakup["Two Wheeler"]) : "0",
                                value: res?.product_breakup["Two Wheeler"] ? res?.product_breakup["Two Wheeler"] : 0,
                                color: "#1e897f",
                            },
                            {
                                tooltipStartText: "Auto",
                                tooltipEndText: det.Auto ? compactFormat(det.Auto) : "0",
                                value: det.Auto ? det.Auto : 0,
                                color: "#b2f1e4",
                            },
                            {
                                tooltipStartText: "Business",
                                tooltipEndText: det.Business ? compactFormat(det.Business) : "0",
                                value: det.Business ? det.Business : 0,
                                color: "#6bd0bd",
                            },
                            {
                                tooltipStartText: "Personal Loan",
                                tooltipEndText: det["Personal Loan"] ? compactFormat(det["Personal Loan"]) : "0",
                                value: det["Personal Loan"] ? det["Personal Loan"] : 0,
                                color: "#1e897f",
                            },

                            {
                                tooltipStartText: "Bike",
                                tooltipEndText: det.Bike ? compactFormat(det.Bike) : "0",
                                value: det.Bike ? det.Bike : 0,
                                color: "#b2f1e4",
                            },

                            {
                                tooltipStartText: "Car",
                                tooltipEndText: det.Car ? compactFormat(det.Car) : "0",
                                value: det.Car ? det.Car : 0,
                                color: "#1e897f",
                            },
                            {
                                tooltipStartText: "Tractor",
                                tooltipEndText: det.Tractor ? compactFormat(det.Tractor) : "0",
                                value: det.Tractor ? det.Tractor : 0,
                                color: "#6bd0bd",
                            },
                        ],
                        color: "#1dc4e9",
                        color1: "#1de9b6",
                        tooltipLabel: "2 ",
                        rowName: "Product Breakup",
                    },
                ];

                let multiBarCircularChartData = {
                    data: [
                        { name: "Calls-to-Contacts", value: dot.connected_percentage, color: "#359aba" },
                        { name: "Contacts-to-PTP", value: dot.ptp_percentage, color: "#4fbcce" },
                        { name: "Paid", value: dot.paid_percentage, color: "#9debdc" },
                    ],
                    title: "Connected",
                    // subTitle: "Connected Call",
                    tooltipText: "Connected Call",
                };

                const parentChart = document.getElementById("stackedBar");
                const parentChart1 = document.getElementById("multiBar");
                ReactDOM.unmountComponentAtNode(parentChart);
                ReactDOM.unmountComponentAtNode(parentChart1);

                ReactDOM.render(<StackedHorizontalBarChart data={stackedHorizontalChartData} scale={1} />, parentChart);
                ReactDOM.render(<MultiBarCircularChart data={multiBarCircularChartData} />, parentChart1);
            }
        } else {
            const parentChart = document.getElementById("stackedBar");
            ReactDOM.unmountComponentAtNode(parentChart);

            ReactDOM.render(<div className="fs-5 text-center fw-bold text-secondary">NO DATA FOUND</div>, parentChart);
        }
    }, [batchAType, batchAProd, batchAId]);

    const getData_AgingReport = useCallback(async () => {
        const res = await getAgingReportAnalysisData(agingType, agingAId);

        if (res) {
            if (agingType === "amount") {
                const multiCircularMixedChartData = {
                    data: {
                        name: "Connected",
                        value: res?.data.collected_amount_percent ? res?.data.collected_amount_percent : 0,
                        color: "#00777c",
                        title: res?.data.collected_amount ? compactFormat(res.data.collected_amount) : "0",
                    },
                    tooltipText: "Connected Call",
                    pieData: [
                        {
                            value: res?.data.amount_prcnt_of_0_30 ? res?.data.amount_prcnt_of_0_30 : 0,
                            color: "#b2f1e4",
                            labelBottom: "0-30 days",
                        },
                        {
                            value: res?.data.amount_prcnt_of_60_90 ? res?.data.amount_prcnt_of_60_90 : 0,
                            color: "#6bd0bd",
                            labelBottom: "60-90 days",
                        },
                        {
                            value: res?.data.amount_prcnt_of_90_180 ? res?.data.amount_prcnt_of_90_180 : 0,
                            color: "#1e897f",
                            labelBottom: "90-180 days",
                        },
                        {
                            value: res?.data.amount_prcnt_of_180_plus ? res?.data.amount_prcnt_of_180_plus : 0,
                            color: "#4fbdce",
                            labelBottom: "180+ days",
                        },
                        {
                            value: res?.data.amount_prcnt_of_30_60 ? res?.data.amount_prcnt_of_30_60 : 0,
                            color: "#b2f1e4",
                            labelBottom: "30-60 days",
                        },
                    ],
                };
                const parentChart = document.getElementById("agingChart");
                ReactDOM.unmountComponentAtNode(parentChart);

                ReactDOM.render(<MultiCircularMixedChart scale={1} data={multiCircularMixedChartData} />, parentChart);
            } else if (agingType === "account") {
                const multiCircularMixedChartData = {
                    data: {
                        name: "Connected",
                        value: res?.data.collected_amount_percent ? res?.data.collected_amount_percent : 0,
                        color: "#00777c",
                        title: res?.data.collected_amount ? compactFormat(res.data.collected_amount) : "0",
                    },
                    tooltipText: "Connected Call",
                    pieData: [
                        {
                            value: res?.data.account_prcnt_of_0_30 ? res?.data.account_prcnt_of_0_30 : 0,
                            color: "#b2f1e4",
                            labelBottom: "0-30 days",
                        },
                        {
                            value: res?.data.account_prcnt_of_60_90 ? res?.data.account_prcnt_of_60_90 : 0,
                            color: "#6bd0bd",
                            labelBottom: "60-90 days",
                        },
                        {
                            value: res?.data.account_prcnt_of_90_180 ? res?.data.account_prcnt_of_90_180 : 0,
                            color: "#1e897f",
                            labelBottom: "90-180 days",
                        },
                        {
                            value: res?.data.account_prcnt_of_180_plus ? res?.data.account_prcnt_of_180_plus : 0,
                            color: "#4fbdce",
                            labelBottom: "180+ days",
                        },
                        {
                            value: res?.data.account_prcnt_of_30_60 ? res?.data.account_prcnt_of_30_60 : 0,
                            color: "#b2f1e4",
                            labelBottom: "30-60 days",
                        },
                    ],
                };
                const parentChart = document.getElementById("agingChart");
                ReactDOM.unmountComponentAtNode(parentChart);

                ReactDOM.render(<MultiCircularMixedChart scale={1} data={multiCircularMixedChartData} />, parentChart);
            }
        } else {
            const parentChart = document.getElementById("agingChart");
            ReactDOM.unmountComponentAtNode(parentChart);

            ReactDOM.render(<div className="fs-5 text-center fw-bold text-secondary">NO DATA FOUND</div>, parentChart);
        }
    }, [agingType, agingAId]);

    useEffect(() => {
        getData_collection();
    }, [getData_collection]);

    useEffect(() => {
        getDisp();
    }, [getDisp]);

    useEffect(() => {
        getData_risk();
    }, [getData_risk]);

    useEffect(() => {
        getData_live();
    }, [getData_live]);

    useEffect(() => {
        getData_batchReport();
    }, [getData_batchReport]);

    useEffect(() => {
        getData_dispo();
    }, [getData_dispo]);

    useEffect(() => {
        getData_BatchWise();
    }, [getData_BatchWise]);

    useEffect(() => {
        getData_AgingReport();
    }, [getData_AgingReport]);

    return (
        <>
            {loading && <ContentLoader />}
            <Tab.Container defaultActiveKey="collection">
                <Nav className="content-tab mb-5 mt-4">
                    <Nav.Item>
                        <Nav.Link eventKey="collection">Collection</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                        <Nav.Link eventKey="agents">
                            Agents
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="geoanalysis">
                            Geo Analysis
                        </Nav.Link>
                    </Nav.Item> */}
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="collection">
                        <div className="dashboard-status mb-5">
                            <div className="row">
                                <div className="col-lg-4 border-right-solid">
                                    <div className="d-flex justify-between text-black pe-4">
                                        <div>
                                            <h3 className="mb-0">{collectionData ? compactFormat(collectionData?.total_collection) : "0"}</h3>
                                            <p>Total Collections</p>
                                        </div>
                                        <div>
                                            <h3 className="mb-0">{collectionData ? compactFormat(collectionData?.today_collection) : "0"}</h3>
                                            <p>Today's Collection</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 border-right-solid">
                                    <div className="d-flex justify-between text-black ps-4 pe-4">
                                        <div>
                                            <h3 className="mb-0">{collectionData ? collectionData?.ptp_ratio + "%" : "0%"}</h3>
                                            <p>Total PTP</p>
                                        </div>
                                        <div>
                                            <h3 className="mb-0">{collectionData ? collectionData?.broken_ptp_ratio + "%" : "0%"}</h3>
                                            <p>Broken PTP</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="d-flex justify-between text-black ps-4">
                                        <div>
                                            <h3 className="mb-0">{collectionData ? collectionData?.notices_sent : 0}</h3>
                                            <p>Notices sent</p>
                                        </div>
                                        <div>
                                            <h3 className="mb-0">{collectionData ? collectionData?.documents_generated : 0}</h3>
                                            <p>Case Documents Generated</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard-content">
                            <div className="row">
                                <div className="col-lg-8 border-right-dash">
                                    <div className="d-flex justify-between text-black collections-chart mb-3">
                                        <div>
                                            <h2 className="mb-0 text-black tx-18">Collections</h2>
                                        </div>
                                        <div>
                                            <p className="text-muted">
                                                <span
                                                    className={duration === "7D" ? "text-primary cursorPointer" : "cursorPointer"}
                                                    onClick={() => setDuration("7D")}
                                                >
                                                    7D
                                                </span>{" "}
                                                |{" "}
                                                <span
                                                    className={duration === "1M" ? "text-primary cursorPointer" : "cursorPointer"}
                                                    onClick={() => setDuration("1M")}
                                                >
                                                    1M
                                                </span>{" "}
                                                |{" "}
                                                <span
                                                    className={duration === "6M" ? "text-primary cursorPointer" : "cursorPointer"}
                                                    onClick={() => setDuration("6M")}
                                                >
                                                    6M
                                                </span>{" "}
                                                |{" "}
                                                <span
                                                    className={duration === "YTD" ? "text-primary cursorPointer" : "cursorPointer"}
                                                    onClick={() => {
                                                        setSmShow(true);
                                                        setDuration("");
                                                    }}
                                                >
                                                    YTD
                                                </span>{" "}
                                                |{" "}
                                                <span
                                                    className={duration === "1Y" ? "text-primary cursorPointer" : "cursorPointer"}
                                                    onClick={() => setDuration("1Y")}
                                                >
                                                    1Y
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="chart-box" id="collectionChart"></div>
                                </div>
                                <div className="col-lg-4 border-right-solid ps-4 live-feed">
                                    <div className="row">
                                        <div className="col-lg-12 mb-3 d-inline-flex justify-content-between align-items-center">
                                            <h2 className="mb-0 text-black tx-18 ">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" className="me-3" viewBox="0 0 20 20">
                                                    <g fill="none" fillRule="evenodd">
                                                        <g fill="#E02020">
                                                            <g>
                                                                <g>
                                                                    <g transform="translate(-1065.000000, -296.000000) translate(1065.000000, 293.000000) translate(0.000000, 0.000000) translate(0.000000, 3.000000)">
                                                                        <circle cx="10" cy="10" r="10" opacity=".16" />
                                                                        <circle cx="10" cy="10" r="6" />
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                                <span>Live Feed</span>
                                            </h2>
                                            <button
                                                className="me-2 btn btn-secondary w-auto align-items-center ps-3 pe-1"
                                                onClick={() => {
                                                    setLiveFeedSortOrderUp(!liveFeedSortOrderUp);
                                                    setLivePageNum(1);
                                                }}
                                            >
                                                <img src={Sort} alt="Sort" />
                                                <span>Sort</span>
                                                {liveFeedSortOrderUp ? <TiArrowSortedUp className="ms-1" /> : <TiArrowSortedDown className="ms-1" />}
                                            </button>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12 mb-3 d-inline-flex justify-content-between align-items-center  justify-content-center">
                                            <button
                                                className="me-2 btn btn-secondary align-items-center ps-3 pe-3"
                                                disabled={livePageNum - 1 === 0}
                                                onClick={() => setLivePageNum(livePageNum - 1)}
                                            >
                                                <TiChevronLeft />
                                                <span>Prev</span>
                                            </button>

                                            <button className="me-2 btn btn-secondary align-items-center ps-3 pe-3" disabled>
                                                <span>{livePageNum}</span>
                                            </button>

                                            <button
                                                className="me-2 btn btn-secondary align-items-center ps-3 pe-1 justify-content-center"
                                                disabled={livePageNum + 1 > totalLivePages}
                                                onClick={() => setLivePageNum(livePageNum + 1)}
                                            >
                                                <span className="me-2">Next</span>
                                                <TiChevronRight />
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            overflow: "scroll",
                                            minHeight: "320px",
                                            maxHeight: "320px",
                                        }}
                                    >
                                        {liveFeed && liveFeed.length > 0 ? (
                                            liveFeed.map((val, i) => {
                                                let disp;
                                                let imgToPrint;
                                                if (val.disposition) {
                                                    disp = val.disposition.toLowerCase();
                                                    if (disp === "ptp") {
                                                        imgToPrint = <PtpDispo />;
                                                    } else if (disp === "bptp") {
                                                        imgToPrint = <BptpDispo />;
                                                    } else if (disp === "sw") {
                                                        imgToPrint = <SwDispo />;
                                                    } else if (disp === "paid") {
                                                        imgToPrint = <PaidDispo />;
                                                    } else if (disp === "nc") {
                                                        imgToPrint = <NcDispo />;
                                                    } else {
                                                        imgToPrint = <GeneDispo />;
                                                    }
                                                }
                                                return (
                                                    <div className="d-flex align-center justify-between text-black pe-4 live-feed-row" key={i}>
                                                        <div className="d-flex align-center justify-between">
                                                            <div className="me-3">{imgToPrint}</div>
                                                            <p className="m-0">
                                                                {val.disposition ? getFullForm(val.disposition) : "-"}
                                                                <br />
                                                                <small>Loan No.{val.loan_account_no ? val.loan_account_no : "NaN"}</small>
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p className="c-505050">
                                                                <strong>&#8377; {val.due_amount ? val.due_amount : "0"} </strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div className="fs-5 text-center fw-bold text-secondary">NO DATA FOUND</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="border-bottom-dash mt-5 mb-5"></div>
                                </div>
                            </div>

                            {/* Batch Wise Analysis */}
                            <div className="row">
                                <div className="col-lg-8 border-right-dash batch-analysis-box">
                                    <div className="row batch-analysis-chart mb-5 text-black">
                                        <div className="col-lg-6">
                                            <div>
                                                <h2 className="mb-0 text-black tx-18">Batch Wise Analysis</h2>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 ">
                                            <div className="d-flex justify-end">
                                                <Dropdown className="me-3">
                                                    <Dropdown.Toggle
                                                        variant="primary"
                                                        id="dropdown-basic"
                                                        className="w-100 dropdown-nostyle text-start select-style "
                                                        style={{
                                                            minWidth: "90px",
                                                            display: "flex",
                                                        }}
                                                    >
                                                        {batchAProd === "" ? "All Products" : nbatchAProd} <img src={Arrow} alt="Sort" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="select-style-dropdown">
                                                        <Dropdown.Item
                                                            onClick={(e) => {
                                                                setBatchAProd("");
                                                            }}
                                                        >
                                                            All Products
                                                        </Dropdown.Item>
                                                        {products &&
                                                            products.length > 0 &&
                                                            products.map((val, i) => {
                                                                return (
                                                                    <Dropdown.Item
                                                                        onClick={(e) => {
                                                                            setBatchAProd(val.val);
                                                                            setNBatchAProd(val.name);
                                                                        }}
                                                                        key={i}
                                                                    >
                                                                        {val.name}
                                                                    </Dropdown.Item>
                                                                );
                                                            })}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <Dropdown className="me-4">
                                                    <Dropdown.Toggle
                                                        variant="primary"
                                                        id="dropdown-basic"
                                                        className="w-100 dropdown-nostyle text-start select-style"
                                                        style={{
                                                            minWidth: "90px",
                                                            maxWidth: "auto",
                                                            display: "flex",
                                                        }}
                                                    >
                                                        {batchALabel === "" ? "All Batches" : batchALabel} <img src={Arrow} alt="Sort" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu
                                                        className="select-style-dropdown"
                                                        style={{
                                                            minHeight: "180px",
                                                            maxHeight: "180px",
                                                            minWidth: "250px",
                                                            maxWidth: "250px",
                                                            overflow: "scroll",
                                                        }}
                                                    >
                                                        <Dropdown.Item
                                                            onClick={(e) => {
                                                                setBatchAId("");
                                                                setBatchALabel("");
                                                            }}
                                                        >
                                                            All Batches
                                                        </Dropdown.Item>
                                                        {BatchIds &&
                                                            BatchIds.length > 0 &&
                                                            BatchIds.map((val, i) => {
                                                                return (
                                                                    <Dropdown.Item
                                                                        onClick={(e) => {
                                                                            setBatchAId(val.value);
                                                                            setBatchALabel(val.label);
                                                                        }}
                                                                        key={i}
                                                                    >
                                                                        {val.label}
                                                                    </Dropdown.Item>
                                                                );
                                                            })}
                                                        {/* <Dropdown.Item
                                                        >Batches 1</Dropdown.Item>
                                                        <Dropdown.Item
                                                        >Batches 2</Dropdown.Item> */}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <div className="btn-group" role="group">
                                                    <button
                                                        type="button"
                                                        className={batchAType === "account" ? "btn btn-secondary active" : "btn btn-secondary"}
                                                        onClick={() => {
                                                            setBatchAId("");
                                                            setBatchAType("account");
                                                        }}
                                                    >
                                                        Accounts
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className={batchAType === "amount" ? "btn btn-secondary active" : "btn btn-secondary"}
                                                        onClick={() => {
                                                            setBatchAId("");
                                                            setBatchAType("amount");
                                                        }}
                                                    >
                                                        Amount
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex text-black batch-analysis-chart align-center">
                                        <div className="chart-box" id="multiBar"></div>
                                        <div className="chart-box ps-5" id="stackedBar"></div>
                                    </div>
                                </div>
                                <div className="col-lg-4 ps-4">
                                    <div className="row batch-analysis-chart mb-5 text-black">
                                        <div className="col-lg-6">
                                            <div>
                                                <h2 className="mb-0 text-black tx-18">Aging Report</h2>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="d-flex justify-end">
                                                <div className="btn-group" role="group">
                                                    <button
                                                        type="button"
                                                        className={agingType === "account" ? "btn btn-secondary active" : "btn btn-secondary"}
                                                        onClick={() => {
                                                            setAgingAId("");
                                                            setAgingType("account");
                                                        }}
                                                    >
                                                        Accounts
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className={agingType === "amount" ? "btn btn-secondary active" : "btn btn-secondary"}
                                                        onClick={() => {
                                                            setAgingAId("");
                                                            setAgingType("amount");
                                                        }}
                                                    >
                                                        Amount
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <Dropdown className="mt-2">
                                                <Dropdown.Toggle
                                                    variant="primary"
                                                    id="dropdown-basic"
                                                    className="dropdown-nostyle text-start select-style"
                                                    style={{
                                                        minWidth: "90px",
                                                        maxWidth: "auto",
                                                    }}
                                                >
                                                    {agingALabel === "" ? "All Batches" : agingALabel} <img src={Arrow} alt="Sort" />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu
                                                    className="select-style-dropdown"
                                                    style={{
                                                        minHeight: "180px",
                                                        maxHeight: "180px",
                                                        overflow: "scroll",
                                                    }}
                                                >
                                                    <Dropdown.Item
                                                        onClick={(e) => {
                                                            setAgingAId("");
                                                            setAgingALabel("");
                                                        }}
                                                    >
                                                        All Batches
                                                    </Dropdown.Item>
                                                    {BatchIds &&
                                                        BatchIds.length > 0 &&
                                                        BatchIds.map((val, i) => {
                                                            return (
                                                                <Dropdown.Item
                                                                    onClick={(e) => {
                                                                        setAgingAId(val.value);
                                                                        setAgingALabel(val.label);
                                                                    }}
                                                                    key={i}
                                                                >
                                                                    {val.label}
                                                                </Dropdown.Item>
                                                            );
                                                        })}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div className="chart-box ps-5" id="agingChart"></div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="border-bottom-dash mt-5 mb-5"></div>
                                </div>
                            </div>

                            {/* Disposition */}
                            <div className="row">
                                <div className="col-lg-8 border-right-dash disposition-box">
                                    <div className="row disposition-chart mb-5 text-black">
                                        <div className="col-lg-6">
                                            <div>
                                                <h2 className="mb-0 text-black tx-18">Disposition</h2>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 ">
                                            <div className="d-flex justify-end">
                                                <Dropdown className="me-3">
                                                    <Dropdown.Toggle
                                                        style={{ display: "flex" }}
                                                        variant="primary"
                                                        id="dropdown-basic"
                                                        className="w-100 dropdown-nostyle text-start select-style"
                                                    >
                                                        {dispoAProd === "" ? "All Products" : ndispoAProd} <img src={Arrow} alt="Sort" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="select-style-dropdown">
                                                        <Dropdown.Item
                                                            onClick={(e) => {
                                                                setDispoAProd("");
                                                            }}
                                                        >
                                                            All Products
                                                        </Dropdown.Item>
                                                        {products &&
                                                            products.length > 0 &&
                                                            products.map((val, i) => {
                                                                return (
                                                                    <Dropdown.Item
                                                                        onClick={(e) => {
                                                                            setDispoAProd(val.val);
                                                                            setnDispoAProd(val.name);
                                                                        }}
                                                                        key={i}
                                                                    >
                                                                        {val.name}
                                                                    </Dropdown.Item>
                                                                );
                                                            })}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <Dropdown className="me-4">
                                                    <Dropdown.Toggle
                                                        style={{
                                                            minWidth: "90px",
                                                            maxWidth: "auto",
                                                            display: "flex",
                                                        }}
                                                        variant="primary"
                                                        id="dropdown-basic"
                                                        className="w-100 dropdown-nostyle text-start select-style"
                                                    >
                                                        {dispoALabel === "" ? "All Batches" : dispoALabel} <img src={Arrow} alt="Sort" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu
                                                        style={{
                                                            minHeight: "180px",
                                                            maxHeight: "180px",
                                                            minWidth: "250px",
                                                            maxWidth: "250px",
                                                            overflow: "scroll",
                                                        }}
                                                        className="select-style-dropdown"
                                                    >
                                                        <Dropdown.Item
                                                            onClick={(e) => {
                                                                setDispoAId("");
                                                                setDispoALabel("");
                                                            }}
                                                        >
                                                            All Batches
                                                        </Dropdown.Item>
                                                        {BatchIds &&
                                                            BatchIds.length > 0 &&
                                                            BatchIds.map((val, i) => {
                                                                return (
                                                                    <Dropdown.Item
                                                                        onClick={(e) => {
                                                                            setDispoAId(val.value);
                                                                            setDispoALabel(val.label);
                                                                        }}
                                                                        key={i}
                                                                    >
                                                                        {val.label}
                                                                    </Dropdown.Item>
                                                                );
                                                            })}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <div className="btn-group" role="group">
                                                    <button
                                                        type="button"
                                                        className={dispoAtype === "account" ? "btn btn-secondary active" : "btn btn-secondary"}
                                                        onClick={() => setDispoAtype("account")}
                                                    >
                                                        Accounts
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className={dispoAtype === "amount" ? "btn btn-secondary active" : "btn btn-secondary"}
                                                        onClick={() => setDispoAtype("amount")}
                                                    >
                                                        Amount
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-center row">
                                        <div className="chart-box" id="dispositionChart"></div>
                                    </div>
                                </div>
                                <div className="col-lg-4 ps-4">
                                    <div className="row mb-5 text-black">
                                        <div className="col-lg-6">
                                            <div>
                                                <h2 className="mb-0 text-black tx-18">Risk Assessment</h2>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 ">
                                            <div className="d-flex justify-end">
                                                <div className="btn-group" role="group">
                                                    <button
                                                        type="button"
                                                        className={riskAtype === "account" ? "btn btn-secondary active" : "btn btn-secondary"}
                                                        onClick={() => setRiskAtype("account")}
                                                    >
                                                        Accounts
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className={riskAtype === "amount" ? "btn btn-secondary active" : "btn btn-secondary"}
                                                        onClick={() => setRiskAtype("amount")}
                                                    >
                                                        Amount
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="d-flex">
                                                <Dropdown className="mt-2 me-3">
                                                    <Dropdown.Toggle
                                                        style={{ display: "flex" }}
                                                        variant="primary"
                                                        id="dropdown-basic"
                                                        className="dropdown-nostyle text-start select-style"
                                                    >
                                                        {riskAProd === "" ? "All Products" : nriskAProd} <img src={Arrow} alt="Sort" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="select-style-dropdown">
                                                        <Dropdown.Item
                                                            onClick={(e) => {
                                                                setRiskAProd("");
                                                            }}
                                                        >
                                                            All Products
                                                        </Dropdown.Item>
                                                        {products &&
                                                            products.length > 0 &&
                                                            products.map((val, i) => {
                                                                return (
                                                                    <Dropdown.Item
                                                                        onClick={(e) => {
                                                                            setRiskAProd(val.val);
                                                                            setnRiskAProd(val.name);
                                                                        }}
                                                                        key={i}
                                                                    >
                                                                        {val.name}
                                                                    </Dropdown.Item>
                                                                );
                                                            })}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <Dropdown className="mt-2 me-4">
                                                    <Dropdown.Toggle
                                                        style={{
                                                            minWidth: "90px",
                                                            display: "flex",
                                                        }}
                                                        variant="primary"
                                                        id="dropdown-basic"
                                                        className="dropdown-nostyle text-start select-style"
                                                    >
                                                        {riskALabel === "" ? "All Batches" : riskALabel} <img src={Arrow} alt="Sort" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu
                                                        style={{
                                                            minHeight: "180px",
                                                            maxHeight: "180px",
                                                            minWidth: "250px",
                                                            maxWidth: "250px",
                                                            overflow: "scroll",
                                                        }}
                                                        className="select-style-dropdown"
                                                    >
                                                        <Dropdown.Item
                                                            onClick={(e) => {
                                                                setRiskAId("");
                                                                setRiskALabel("");
                                                            }}
                                                        >
                                                            All Batches
                                                        </Dropdown.Item>
                                                        {BatchIds &&
                                                            BatchIds.length > 0 &&
                                                            BatchIds.map((val, i) => {
                                                                return (
                                                                    <Dropdown.Item
                                                                        onClick={(e) => {
                                                                            setRiskAId(val.value);
                                                                            setRiskALabel(val.label);
                                                                        }}
                                                                        key={i}
                                                                    >
                                                                        {val.label}
                                                                    </Dropdown.Item>
                                                                );
                                                            })}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chart-box ps-5" id="riskChart"></div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="border-bottom-dash mt-5 mb-5"></div>
                                </div>
                            </div>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="agents">Agents</Tab.Pane>
                    <Tab.Pane eventKey="geoanalysis">Geoanalysis</Tab.Pane>
                </Tab.Content>
            </Tab.Container>

            <Modal size="sm" show={smShow} onHide={() => setSmShow(false)} aria-labelledby="example-modal-sizes-title-sm" backdrop="static">
                <Modal.Header>
                    <Modal.Title>YTD</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="mb-3 mt-3 row">
                            <label htmlFor="from" className="col-md-3 col-form-label">
                                Date Between
                            </label>
                            <div className="col-md-9">
                                <div className="row">
                                    <div className="col-lg-6 datepicker datepicker-small">
                                        <DatePicker label="From" value={from} onChange={(val) => setFrom(val)} />
                                    </div>
                                    <div className="col-lg-6 datepicker datepicker-small">
                                        <DatePicker label="To" value={to} onChange={(val) => setTo(val)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setSmShow(false);
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setDuration("YTD");
                            setSmShow(false);
                        }}
                    >
                        Apply
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Dashboard;
