import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import useGeneralStore from "../../../store/generalStore";

export default function SubMenuItemLink({ icon, label = "", target = "/match" }) {
    const isSidebarOpen = useGeneralStore((state) => state.is_sidebar_open);

    return (
        <li className={`nav-item ${useRouteMatch(target) && "active"}`}>
            <Link to={target} style={{ display: "flex", alignItems: "start" }}>
                <div style={{ width: "1.5rem", float: "left", height: "1.5rem", textAlign: "center", marginRight: "0.5rem" }}>{icon}</div>
                <span
                    style={{
                        transition: "all linear 0.5s, opacity linear 0.2s",
                        width: isSidebarOpen ? "auto" : "0px",
                        height: isSidebarOpen ? "auto" : "0px",
                        opacity: isSidebarOpen ? "1" : "0",
                        overflow: "hidden",
                    }}
                >
                    {label}
                </span>
            </Link>
        </li>
    );
}
