import React, { useEffect, useState } from "react";

export default function BorrowerSearch({ value = "", onChange = () => {} }) {
    const [search, setSearch] = useState("");

    useEffect(() => {
        setSearch(!!value ? value : "");
    }, [value]);

    return (
        <>
            <div className="filter-label">Borrower</div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    onChange(search);
                }}
                className="filter-search-box"
            >
                <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} className="form-control" placeholder="Search" />
                <button type="submit" className="search-icon">
                    <svg width="15" height="15" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
                        <g fill="#727272" fillRule="nonzero">
                            <path d="M6.162 0a6.16 6.16 0 1 0 0 12.319 6.16 6.16 0 0 0 6.161-6.16A6.166 6.166 0 0 0 6.162 0zm0 10.176a4.018 4.018 0 1 1-.002-8.035 4.018 4.018 0 0 1 .002 8.035zM14.686 13.17l-2.143-2.142a1.072 1.072 0 0 0-1.516 1.516l2.143 2.142a1.072 1.072 0 0 0 1.516-1.516z" />
                        </g>
                    </svg>
                </button>
            </form>
        </>
    );
}
