import React from "react";

export default function UploadProgressBar({ progressValue = 0 }) {
	return (
		<div className="progress-main text-start ps-5 pe-5 ms-5 me-5">
			<p className="mb-1">Uploading ({progressValue}%)</p>
			<div className="progress">
				<div
					className="progress-bar"
					style={{
						width: `${progressValue}%`,
						transition: "width 50ms",
					}}
					id="file"
					value={progressValue}
					role="progressbar"
				>
					{progressValue}%
				</div>
			</div>
		</div>
	);
}
