import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { getNoticeTypes, moveToNoticeBatch } from "../../../API/panelApis";
import useGeneralStore from "../../../store/generalStore";
import ContentLoader from "../../../Components/ContentLoader";
import useForm from "../../../hooks/useForm";
import { MultiSelect } from "react-multi-select-component";
import { getAgentLanguages } from "../../../API";
import SelectDropdown from "../../../ui/forms/SelectDropdown";

const commModeOptions = [
    { label: "Postal", value: "postal" },
    { label: "Whatsapp", value: "whatsapp" },
    { label: "Email", value: "email" },
    { label: "SMS", value: "sms" },
];

export default function MoveToNoticeModal({ show, setShow, item, setItem }) {
    const [data, setData] = useState([]);
    const [moveToNoticeFields, handleMoveToNoticeFields, resetMoveToNoticeFields] = useForm({
        communication_mode: [],
        notice_language: "",
    });
    const [noticeLanguageOptions, setNoticeLanguageOptions] = useState([]);
    const [loading, setLoading] = useState(true);

    const closeModal = () => {
        setShow(false);
        setItem(null);
        resetMoveToNoticeFields();
    };

    const fetchNoticeTypes = async () => {
        setLoading(true);

        try {
            const res = await getNoticeTypes();
            console.log(res)
            if (res)
                setData(
                    res?.data?.map((name) => ({
                        label: name,
                        type: name,
                    }))
                );
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    const fetchLanguages = async () => {
        const res = await getAgentLanguages();
        if (res)
            setNoticeLanguageOptions(
                res?.map(({ language_name }) => ({ label: language_name?.charAt(0)?.toUpperCase() + language_name?.slice(1), value: language_name }))
            );
    };

    useEffect(() => {
        if (!!show) {
            fetchNoticeTypes();
            fetchLanguages();
        }
    }, [show]);

    return (
        <Modal size="xs" show={show} backdrop="static">
            <Modal.Header>
                <Modal.Title>Move to Notices</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mt-3 mb-3">
                    <p className="mb-2">
                        Communication Mode <span className="text-danger">*</span>
                    </p>
                    <MultiSelect
                        className="bg-light-grey text-dark"
                        options={commModeOptions}
                        value={commModeOptions?.filter((el) => moveToNoticeFields?.communication_mode?.includes(el?.value))}
                        onChange={(val) =>
                            handleMoveToNoticeFields(
                                "communication_mode",
                                val?.map((el) => el?.value)
                            )
                        }
                        labelledBy="Select Communication Mode"
                    />
                </div>

                <div className="mb-3">
                    <p className="mb-2">
                        Notice Languages <span className="text-danger">*</span>
                    </p>
                    <SelectDropdown
                        placeholder="Select Languages"
                        options={noticeLanguageOptions}
                        value={moveToNoticeFields?.notice_language}
                        onChange={(val) => handleMoveToNoticeFields("notice_language", val)}
                    />
                </div>

                <div style={{ height: "400px", overflow: "scroll" }}>
                    {!!data?.length && !loading && (
                        <table className="w-100 my-3">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Notice Type</th>
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((el, index) => (
                                    <tr key={index}>
                                        <td style={{ borderBottom: "1px solid #f5f5f5" }} className="py-2 px-1">
                                            {index + 1}
                                        </td>
                                        <td style={{ borderBottom: "1px solid #f5f5f5" }} className="py-2">
                                            {el?.label}
                                        </td>
                                        <td style={{ borderBottom: "1px solid #f5f5f5" }} className="py-2 text-center">
                                            <GenerateButton
                                                batch_id={item?.batch_id}
                                                client_id={item?.client?.client_id}
                                                notice_type={el?.type}
                                                communication_mode={moveToNoticeFields?.communication_mode}
                                                notice_language={moveToNoticeFields?.notice_language}
                                                closeModal={closeModal}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    {!data?.length && !loading && <div className="d-flex align-items-center justify-content-center">No Data Found</div>}
                    {loading && <ContentLoader />}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => closeModal()} variant="secondary">
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function GenerateButton({ batch_id, client_id, notice_type, communication_mode = [], notice_language = "", closeModal = () => {} }) {
    const showAlert = useGeneralStore((state) => state.open_alert);
    const [loading, setLoading] = useState(false);

    const generateNotice = async () => {
        if (!batch_id || !client_id || !notice_type) {
            showAlert({ variant: "danger", msg: "Missing details" });
            return;
        }

        if (!communication_mode?.length) {
            showAlert({ variant: "warning", msg: "Communication Mode not selected" });
            return;
        }

        if (!notice_language) {
            showAlert({ variant: "warning", msg: "Notice Language not selected" });
            return;
        }

        setLoading(true);

        try {
            const res = await moveToNoticeBatch({ batch_id, client_id, notice_type, communication_mode, notice_language });

            if (res) {
                showAlert({ variant: "success", msg: res?.message });
                closeModal();
            }
        } catch (error) {
            console.log(error);
            showAlert({ variant: "danger", msg: "Unable to generate" });
        }
        setLoading(false);
    };

    return (
        <button disabled={loading} onClick={() => generateNotice()} className="btn btn-sm btn-primary">
            Generate
        </button>
    );
}
