import React from "react";
import { maskMobileNumber } from "../../../Functions/mask";
import useAgentStore from "../../../store/agent/agentStore";
import classes from "./style.module.css";
import useAuthStore from "../../../store/authStore";
import useBorrowerInfo from "../../features/borrower-info/store";

export default function IncomingCall() {
  const userData = useAuthStore((state) => state.userData);

  const show = useAgentStore((state) => state.show_incoming_call);
  const toggleIncomingCall = useAgentStore((state) => state.toggleIncomingCall);
  const data = useAgentStore((state) => state.incoming_call_data);
  const addCustomerId = useAgentStore((state) => state.addCustomerId);
  const openBorrowerInfo = useBorrowerInfo((state) => state.openBorrowerInfo);

  return (
    <div className={`${classes.wrapper} ${!show && classes.hidden}`}>
      <div onClick={() => toggleIncomingCall()} className={classes.header}>
        <div>
          <svg width="19" height="25" viewBox="0 0 22 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.26 28.932C15.1912 28.7317 13.7578 28.0388 11.7305 26.2799C9.26624 24.1351 7.40055 22.2316 5.15842 18.8342C2.99757 15.553 2.02013 13.5076 0.80604 9.71308C-0.565892 5.42854 0.116193 3.44522 0.645127 2.64204C1.27565 1.68178 2.05466 1.18274 3.03057 0.725403C3.58379 0.472255 4.15774 0.273935 4.74514 0.132957C4.80404 0.118144 4.85891 0.103779 4.90792 0.0907204C5.20043 0.0114783 5.64355 -0.108073 6.12905 0.199296C6.45328 0.403111 6.71629 0.745135 7.11715 1.34622C7.93947 2.5775 8.91469 5.15928 9.22748 6.39608C9.43701 7.22941 9.57588 7.7796 9.48224 8.34995C9.37198 9.01757 8.97155 9.47333 8.49703 9.95729C8.40807 10.0482 8.32041 10.1346 8.23544 10.2184C7.72245 10.7234 7.60317 10.8748 7.61371 11.187C7.63595 11.8145 8.24923 13.7851 9.48306 15.6555C10.7169 17.5259 12.2084 18.7419 12.757 18.9781C13.0405 19.1004 13.2188 19.0292 13.8598 18.6756C13.9517 18.6249 14.0462 18.5723 14.1445 18.5204C14.8046 18.1674 15.3196 17.9266 15.9318 18.0413L15.9351 18.042C16.4679 18.1418 16.8833 18.4733 17.5719 19.0207C18.4701 19.7348 20.485 21.5857 21.2971 22.8312C21.6921 23.4367 21.9056 23.8157 21.9755 24.2093C22.0808 24.8019 21.8232 25.2027 21.6541 25.4715C21.6258 25.5161 21.5946 25.565 21.5613 25.6195C21.2348 26.1579 20.8624 26.6632 20.4489 27.129C19.7137 27.947 19.0055 28.5543 17.9404 28.8579C17.3928 29.0178 16.8182 29.0431 16.26 28.932Z"
              fill="#437FF7"
            />
          </svg>
          <p>Incoming Calls</p>
        </div>
        <button className={`${classes.close_button} ${!show && classes.open}`}>
          <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.558058 0.558058C0.802136 0.313981 1.19786 0.313981 1.44194 0.558058L6 5.11612L10.5581 0.558058C10.8021 0.313981 11.1979 0.313981 11.4419 0.558058C11.686 0.802136 11.686 1.19786 11.4419 1.44194L6 6.88388L0.558058 1.44194C0.313981 1.19786 0.313981 0.802136 0.558058 0.558058Z"
              fill="#545455"
            />
          </svg>
        </button>
      </div>
      <div className={classes.list_wrapper}>
        {!!data?.length ? (
          <div className={classes.list}>
            {data.map((item, index) => (
              <div key={index} className={classes.list_item} onClick={() => openBorrowerInfo(item?.customer_id)}>
                <p>{item?.customer_name}</p>
                <p>{!!userData?.remove_masking ? item?.customer_number : maskMobileNumber(item?.customer_number)}</p>
              </div>
            ))}
          </div>
        ) : (
          <div className={classes.not_found}>No Incoming Calls</div>
        )}
      </div>
    </div>
  );
}
