import { Fragment, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import useBorrowerInfo from "../store";
import useAuthStore from "../../../../store/authStore";
import { maskEmail, maskMobileNumber } from "../../../../Functions/mask";
import { isEmpty } from "../../../../Functions/isEmpty";

export default function Profile() {
    const [isOpen, setIsOpen] = useState(false);

    const userData = useAuthStore((state) => state.userData);
    const data = useBorrowerInfo((state) => state.data);

    const borrowerDetails = data?.data;

    const toggle = () => setIsOpen((prev) => !prev);

    return (
        <>
            <a onClick={toggle} style={{ color: "#118CEC" }} className="fw-medium py-2">
                Profile
            </a>
                    
            <Offcanvas show={isOpen} onHide={toggle} backdropClassName="opacity-0" placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Profile</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="p-3 pl-5">

                
                    {[
                        ["Current State", !isEmpty(borrowerDetails?.customer_state) ? borrowerDetails?.customer_state : ""],
                        ["Current City", !isEmpty(borrowerDetails?.customer_city) ? borrowerDetails?.customer_city : ""],
                        ["Gender", !isEmpty(borrowerDetails?.gender) ? borrowerDetails?.gender : ""],
                        ["Customer Profile", !isEmpty(borrowerDetails?.customer_profile) ? borrowerDetails?.customer_profile : ""],
                        ["DOB", !isEmpty(borrowerDetails?.dob) ? borrowerDetails?.dob : ""],
                        [
                            "Email",
                            !isEmpty(borrowerDetails?.email) ? (!!userData?.remove_masking ? borrowerDetails?.email : maskEmail(borrowerDetails?.email)) : "",
                        ],
                        [
                            "Alternate Number",
                            !isEmpty(data?.alt_numbers?.alt_contact_number)
                                ? !!userData?.remove_masking
                                    ? data?.alt_numbers?.alt_contact_number
                                    : maskMobileNumber(data?.alt_numbers?.alt_contact_number)
                                : "",
                        ],
                        ["Employer Name", !isEmpty(borrowerDetails?.employer_name) ? borrowerDetails?.employer_name : ""],
                    ].map(([label, value], index) => (
                        <Fragment key={index}>
                            {!!value && (
                                <div className="d-flex justify-content-between gap-3 mb-3">
                                    <p className="mb-0">{label}</p>
                                    <p style={{ wordBreak: "break-all", maxWidth: "60%" }} className="mb-0 text-end fw-bold text-black">
                                        {value}
                                    </p>
                                </div>
                            )}
                        </Fragment>
                    ))}
                            </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
