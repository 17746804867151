import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { HiOutlineDownload } from "react-icons/hi";
import { FaInfoCircle } from "react-icons/fa";
import { IoIosArrowRoundForward } from "react-icons/io";
import { IoMdAdd, IoMdRemove } from "react-icons/io";
import classes from "./style.module.css";
import { IoDocumentText } from "react-icons/io5";
import { fileInstantSingleFir, getFirStates, getRequiredFirData, sendFirCopyToBorrower } from "../../../../../API/agentApis";
import { downloadMediaUrl, fetchFIRComplaints, getDistrictData, getPoliceStationData, updateFIRComplaints } from "../../../../../API";
import { showToast } from "../../../../../utils/handleToast";
import ContentLoader from "../../../../../Components/ContentLoader";
import SelectDropdown from "../../../../../ui/forms/SelectDropdown";
import useBorrowerInfo from "../../store";

const statusDefaults = {
    stateNotIntegrated: false,
    firFiled: false,
    firNotFiled: false,
    showError: false,
    firFiling: false,
};

export default function FirCopy() {
    const data = useBorrowerInfo((state) => state.data);

    const [show, setShow] = useState(false);

    const [communicationMode, setCommunicationMode] = useState(["whatsapp", "email", "sms"]);
    const [mobileNumber, setmobileNumber] = useState([{ number: "" }]);
    const [loading, setLoading] = useState(true);
    const [stateOptions, setStateOptions] = useState([]);
    const [districtOptions, setDistrictOptions] = useState([]);
    const [policeStationOptions, setPoliceStationOptions] = useState([]);
    const [stateSelected, setStateSelected] = useState("");
    const [districtSelected, setDistrictSelected] = useState("");
    const [policeStationSelected, setPoliceStationSelected] = useState("");
    const [firData, setFirData] = useState(null);
    const [complaintData, setComplaintData] = useState(null);
    // Info / alerts
    const [showStatus, setShowStatus] = useState({ ...statusDefaults });

    const handleStatus = (name) =>
        setShowStatus({
            ...statusDefaults,
            [name]: true,
        });

    const closeModal = () => {
        setShow(false);

        setTimeout(() => {
            setmobileNumber([{ number: "" }]);
            setCommunicationMode([]);

            setFirData(null);
            setComplaintData(null);
            setPoliceStationSelected("");
            setDistrictSelected("");
            setStateSelected("");
            setPoliceStationOptions([]);
            setDistrictOptions([]);
            setShowStatus({ ...statusDefaults });
        }, 1000);
    };

    function download({ dataurl, filename }) {
        const link = document.createElement("a");
        link.href = downloadMediaUrl(dataurl);
        link.target = "_blank";
        link.referrerPolicy = "noreferer";
        if (!!filename) link.download = filename;
        link.click();
        link.remove();
    }

    const checkFirStatus = () => {
        // check if fir filed or not
        if (complaintData?.is_fir_filed) {
            // FIR filed
            handleStatus("firFiled");
            return;
        }

        const state = stateOptions?.find((el) => el?.label?.toLowerCase() === complaintData?.state?.toLowerCase());

        // check if state integrated or not
        if (!!state?.disabled) {
            // State not integrated
            handleStatus("stateNotIntegrated");
            return;
        }

        // check for any missing file info
        if (complaintData?.is_filing_failed) {
            handleStatus("showError");
            return;
        }

        handleStatus("firNotFiled");
    };

    const handleNumberInfo = (number, index) => {
        let mobileNumberTemp = [...mobileNumber];
        mobileNumberTemp[index]["number"] = number;
        setmobileNumber(mobileNumberTemp);
    };

    const handleAdd = () => {
        setmobileNumber([...mobileNumber, { number: "" }]);
    };

    const handleRemove = (i) => {
        let formValues = [...mobileNumber];
        formValues.splice(i, 1);
        setmobileNumber(formValues);
    };

    const getData = async () => {
        setLoading(true);

        const res = await getRequiredFirData({ customer_id: data?.data?.customer_id });
        if (res) setFirData(res?.data);
        else {
            showToast({ variant: "danger", message: "Unable to fetch FIR details" });
        }
    };
    const getComplaintData = async () => {
        setLoading(true);

        const res = await fetchFIRComplaints({ complaint_id: firData?.complaint_id });
        if (res) setComplaintData(res?.data);
        else {
            showToast({ variant: "danger", message: "Unable to fetch complaint details" });
        }

        setLoading(false);
    };

    useEffect(() => {
        if (!!show && !!data?.data?.customer_id) {
            setCommunicationMode(["whatsapp", "sms", "email"]);
            setmobileNumber([{ number: complaintData?.mobile }]);
            getData();
        }
    }, [data, show]);

    useEffect(() => {
        if (!!show && !!complaintData) setmobileNumber([{ number: complaintData?.mobile }]);
    }, [show, complaintData]);

    useEffect(() => {
        !!show && !!firData && getComplaintData();
    }, [firData, show]);

    const getStates = async () => {
        const res = await getFirStates();
        if (res) setStateOptions(res?.data?.map((el) => ({ label: el?.state_name, value: el?.state_id, disabled: !el?.is_active })));
    };

    const getDistricts = async () => {
        const res = await getDistrictData(stateSelected);
        if (res) setDistrictOptions(res?.data?.map((el) => ({ label: el?.district_name, value: el?.district_id })));
    };

    const getPoliceStation = async () => {
        const res = await getPoliceStationData(districtSelected);
        if (res) setPoliceStationOptions(res?.data?.map((el) => ({ label: el?.police_station_name, value: el?.police_station_id })));
    };

    useEffect(() => {
        !!show && getStates();
    }, [show]);

    useEffect(() => {
        !!show && !!stateSelected && getDistricts();
    }, [stateSelected, show]);

    useEffect(() => {
        !!show && !!districtSelected && getPoliceStation();
    }, [districtSelected, show]);

    useEffect(() => {
        if (!!show && !!stateOptions?.length && !!complaintData) {
            const state = stateOptions?.find((el) => el?.label?.toLowerCase() === complaintData?.state?.toLowerCase());
            if (!!state && !state?.disabled) setStateSelected(state?.value);
            checkFirStatus();
        }
    }, [stateOptions, complaintData, show]);

    useEffect(() => {
        !!show &&
            !!districtOptions?.length &&
            !!complaintData &&
            setDistrictSelected(districtOptions?.find((el) => el?.label?.toLowerCase() === complaintData?.district?.toLowerCase())?.value);
    }, [districtOptions, complaintData, show]);

    useEffect(() => {
        !!show &&
            !!policeStationOptions?.length &&
            !!complaintData &&
            setPoliceStationSelected(policeStationOptions?.find((el) => el?.label?.toLowerCase() === complaintData?.police_station_name?.toLowerCase())?.value);
    }, [policeStationOptions, complaintData, show]);

    const fileInstantFir = async ({ with_client_address = false }) => {
        handleStatus("firFiling");
        const instantFirBody = !!with_client_address
            ? { customer_id: data?.data?.customer_id, client_id: data?.client_id }
            : { customer_id: data?.data?.customer_id };
        const res = await fileInstantSingleFir(instantFirBody);
        if (res) {
            showToast({ variant: "success", message: res?.message });
            handleStatus("firFiling");
            setTimeout(() => {
                getComplaintData();
            }, 5 * 60 * 1000);
        } else {
            showToast({ variant: "danger", message: "FIR filing failed" });
        }
    };

    const fileFir = async () => {
        handleStatus("firFiling");
        const res1 = await updateFIRComplaints(
            { complaint_id: complaintData?.complaint_id },
            {
                state: stateOptions?.find((el) => el?.value === stateSelected)?.label,
                district: districtOptions?.find((el) => el?.value === districtSelected)?.label,
                police_station_name: policeStationOptions?.find((el) => el?.value === policeStationSelected)?.label,
                complaint_id: complaintData?.complaint_id,
            }
        );

        if (res1) {
            fileInstantFir({ with_client_address: false });
        } else {
            showToast({ variant: "danger", message: "Unable to update details" });
            handleStatus("showError");
        }
    };

    const sendFirCopy = async () => {
        const res = await sendFirCopyToBorrower({
            customer_id: data?.data?.customer_id,
            mode: communicationMode?.join(","),
        });
        if (res) {
            showToast({ variant: "success", message: res?.message });
            closeModal();
        }
    };

    return (
        <>
            <a
                onClick={() => setShow(true)}
                style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", color: "#118CEC" }}
                className="col-6 py-2 fw-medium"
            >
                FIR Copy
            </a>

            <Modal show={show} size="sm">
                <Modal.Header>
                    <Modal.Title>Send FIR Copy</Modal.Title>
                </Modal.Header>
                <Modal.Body className="mx-0 px-0" style={{ minHeight: "300px" }}>
                    {!loading && (
                        <>
                            {/* Status  */}
                            <>
                                {showStatus?.firFiling && (
                                    <div
                                        className="w-100 mb-4"
                                        style={{ backgroundColor: "#4393D0", color: "#fff", marginTop: "-1px", width: "100%", overflow: "hidden" }}
                                    >
                                        <div className={classes.horizontal_loader}>
                                            <svg width="100%" height="100%">
                                                <defs>
                                                    <pattern
                                                        id="striped-loader-horizontal"
                                                        x="0"
                                                        y="0"
                                                        width="25"
                                                        height="30"
                                                        patternUnits="userSpaceOnUse"
                                                        style={{ transform: "skew(-30deg)" }}
                                                    >
                                                        <rect width="15" height="30" fill="#2C73A9" />
                                                        <rect x="15" width="10" height="30" fill="#7DB2DE" />
                                                    </pattern>
                                                </defs>

                                                <rect x="0" y="0" width="100%" height="100%" fill="url(#striped-loader-horizontal)" />
                                            </svg>
                                        </div>
                                        <div className="px-4 py-2">
                                            <p className="mb-0">Please wait for 5 min. FIR is getting filed.</p>

                                            <button
                                                className="fw-bold tx-16"
                                                onClick={() => getComplaintData()}
                                                style={{ border: "none", outline: "none", textAlign: "left", backgroundColor: "transparent", color: "inherit" }}
                                            >
                                                Check Status Now <IoIosArrowRoundForward style={{ fontSize: "32px" }} />
                                            </button>
                                        </div>
                                    </div>
                                )}

                                {showStatus?.stateNotIntegrated && (
                                    <div
                                        className="w-100 mb-4 px-4 py-3 d-flex align-items-center gap-2"
                                        style={{ backgroundColor: "#5487F7", marginTop: "-1px", width: "100%", color: "#fff" }}
                                    >
                                        <FaInfoCircle style={{ fontSize: "20px" }} /> <strong>Filing with Client Address</strong>
                                    </div>
                                )}
                                {/* {showStatus?.stateNotIntegrated && (
                                    <div
                                        className="w-100 mb-4 px-4 py-3"
                                        style={{ backgroundColor: "#FA4E4E", marginTop: "-1px", width: "100%", color: "#fff" }}
                                    >
                                        <strong>Cannot File FIR</strong>
                                        <br />
                                        <p className="mb-0">{!!complaintData?.state ? complaintData?.state : <em>STATE_NAME</em>} State not Integrated.</p>
                                    </div>
                                )} */}

                                {showStatus?.firNotFiled && (
                                    <div
                                        className="w-100 mb-4 px-4 py-3"
                                        style={{ backgroundColor: "#FA4E4E", marginTop: "-1px", width: "100%", color: "#fff" }}
                                    >
                                        <strong>FIR Not Filed</strong>
                                    </div>
                                )}

                                {showStatus?.showError && (
                                    <div
                                        className="w-100 mb-4 px-4 py-3"
                                        style={{ backgroundColor: "#FA4E4E", marginTop: "-1px", width: "100%", color: "#fff" }}
                                    >
                                        <strong>FIR Not Filed</strong>
                                        <br />
                                        <p className="mb-0">{complaintData?.missing_info}</p>
                                    </div>
                                )}

                                {showStatus?.firFiled && (
                                    <div
                                        className="w-100 mb-4 px-4 py-3"
                                        style={{ backgroundColor: "#398E3D", marginTop: "-1px", width: "100%", color: "#fff" }}
                                    >
                                        <strong>FIR Filed</strong>
                                    </div>
                                )}
                            </>

                            <div className="px-4 pt-2">
                                <p className="mb-3" style={{ fontWeight: "500" }}>
                                    <span style={{ color: "#999", marginBottom: "0.15rem", display: "inline-block" }}>Address</span>
                                    <br />
                                    <span>{complaintData?.address1 || "- - - - - -"}</span>
                                </p>

                                <div className="mb-3 d-flex gap-2 align-items-start justify-content-between" style={{ fontWeight: "500" }}>
                                    <p className="mb-0">
                                        <span style={{ color: "#999", marginBottom: "0.15rem", display: "inline-block" }}>State</span>
                                        <br />
                                        <span>{complaintData?.state || "- - -"}</span>
                                    </p>
                                    <p className="mb-0">
                                        <span style={{ color: "#999", marginBottom: "0.15rem", display: "inline-block" }}>Pincode</span>
                                        <br />
                                        <span>{complaintData?.zip_code || "- - -"}</span>
                                    </p>
                                    <p className="mb-0">
                                        <span style={{ color: "#999", marginBottom: "0.15rem", display: "inline-block" }}>Landmark</span>
                                        <br />
                                        <span>{complaintData?.landmark || "- - -"}</span>
                                    </p>
                                </div>

                                <p className="mb-3" style={{ fontWeight: "500" }}>
                                    <span style={{ color: "#999", marginBottom: "0.15rem", display: "inline-block" }}>Police Station</span>
                                    <br />
                                    <span>{complaintData?.police_station_name || "- - - - - -"}</span>
                                </p>
                            </div>

                            {/* Form */}
                            {(!!showStatus.firNotFiled || !!showStatus.showError) && (
                                <div className="mb-4 mt-2 px-4">
                                    <div className="mb-3">
                                        <p className="mb-2">State</p>
                                        <SelectDropdown options={stateOptions} placeholder="Select State" value={stateSelected} onChange={setStateSelected} />
                                    </div>
                                    <div className="mb-3">
                                        <p className="mb-2">District</p>
                                        <SelectDropdown
                                            options={districtOptions}
                                            placeholder="Select District"
                                            value={districtSelected}
                                            onChange={setDistrictSelected}
                                            disabled={!stateSelected}
                                        />
                                    </div>
                                    <div>
                                        <p className="mb-2">Police Station</p>
                                        <SelectDropdown
                                            options={policeStationOptions}
                                            placeholder="Select Police Station"
                                            value={policeStationSelected}
                                            onChange={setPoliceStationSelected}
                                            disabled={!districtSelected}
                                        />
                                    </div>
                                </div>
                            )}

                            {showStatus.firFiled && (
                                <div className="py-4 px-4" style={{ backgroundColor: "#F5F6F8" }}>
                                    <div
                                        className="mb-3 d-flex align-items-center justify-content-between"
                                        style={{ border: "1px solid #e1e1e1", borderRadius: "4rem", backgroundColor: "#fff" }}
                                    >
                                        <div className="d-flex align-items-center gap-2 px-3 py-2 w-100">
                                            <IoDocumentText className="tx-20 flex-shrink-0" />
                                            <p
                                                style={{ maxWidth: "330px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                                                className="mb-0 w-100"
                                            >
                                                {complaintData?.pdf_url?.split("/").splice(-1)[0]}
                                            </p>
                                        </div>
                                        <button
                                            className="d-flex align-items-center justify-content-center text-primary py-2 px-3 flex-shrink-0"
                                            style={{
                                                border: "none",
                                                borderLeft: "1px solid #e1e1e1",
                                                outline: "none",
                                                backgroundColor: "transparent",
                                                height: "100%",
                                                fontSize: "24px",
                                            }}
                                            onClick={() => download({ dataurl: complaintData?.pdf_url })}
                                        >
                                            <HiOutlineDownload />
                                        </button>
                                    </div>

                                    <div className="mb-3">
                                        {mobileNumber.map((item, i) => (
                                            <div className="mb-2 m-number" key={i}>
                                                <label htmlFor="number" className="col-form-label">
                                                    Mobile Number {mobileNumber.length > 1 && `#${i + 1}`}
                                                </label>
                                                <div
                                                    className="d-flex align-items-center justify-content-start"
                                                    style={{ backgroundColor: "#fff", border: "1px solid #e1e1e1", borderRadius: "0.3rem" }}
                                                >
                                                    <div className="w-100">
                                                        <input
                                                            name={i}
                                                            type="text"
                                                            className="form-control"
                                                            style={{ border: "none", outline: "none" }}
                                                            value={item.number}
                                                            onChange={(e) => {
                                                                handleNumberInfo(e?.target?.value, i);
                                                            }}
                                                        />
                                                    </div>

                                                    {i > 0 && (
                                                        <button
                                                            style={{
                                                                border: "none",
                                                                outline: "none",
                                                                backgroundColor: "transparent",
                                                                borderLeft: "1px solid #e1e1e1",
                                                            }}
                                                            className="text-danger flex-shrink-0 py-2 px-3 fw-bold d-flex align-items-center justify-content-center gap-1"
                                                            onClick={() => handleRemove(i)}
                                                        >
                                                            <IoMdRemove /> Remove
                                                        </button>
                                                    )}
                                                    {i === mobileNumber.length - 1 && (
                                                        <button
                                                            style={{
                                                                border: "none",
                                                                outline: "none",
                                                                backgroundColor: "transparent",
                                                                borderLeft: "1px solid #e1e1e1",
                                                            }}
                                                            className="text-primary flex-shrink-0 py-2 px-3 fw-bold d-flex align-items-center justify-content-center gap-1"
                                                            onClick={() => handleAdd()}
                                                        >
                                                            <IoMdAdd /> Add
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="row">
                                        <label htmlFor="number" className="col-lg-12 col-form-label pb-0">
                                            Communication Mode
                                        </label>
                                        <div className="col-12 d-flex items-center justify-content-start gap-4 mt-1">
                                            {[
                                                { label: "WhatsApp", value: "whatsapp" },
                                                { label: "SMS", value: "sms" },
                                                { label: "Email", value: "email" },
                                            ].map(({ label, value }, index) => (
                                                <label className="col-form-label pt-0" key={index}>
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={communicationMode?.includes(value)}
                                                        onChange={(e) => {
                                                            if (e.target.checked && !communicationMode?.includes(value))
                                                                setCommunicationMode((prev) => [...prev, value]);
                                                            if (!e.target.checked) setCommunicationMode((prev) => prev?.filter((el) => el !== value));
                                                        }}
                                                    />{" "}
                                                    {label}
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                    {loading && <ContentLoader />}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                    {!loading && (
                        <>
                            {(!!showStatus.firNotFiled || !!showStatus.showError) && (
                                <Button variant="primary" onClick={() => fileFir()}>
                                    File Now
                                </Button>
                            )}
                            {!!showStatus.stateNotIntegrated && (
                                <Button variant="primary" onClick={() => fileInstantFir({ with_client_address: true })}>
                                    File Now
                                </Button>
                            )}
                            {!!showStatus.firFiled && (
                                <Button variant="primary" onClick={() => sendFirCopy()}>
                                    {"Save & Send Statement"}
                                </Button>
                            )}
                        </>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    );
}
