import { format,addDays } from "date-fns";
import React, { useState,useMemo } from "react";
import { Button, Modal } from "react-bootstrap";
import { postDispositionData } from "../../../../API/agentApis";
import { isEmpty } from "../../../../Functions/isEmpty";
import useCallCustomer from "../../../../hooks/useCallCustomer";
import { showToast } from "../../../../utils/handleToast";
import { handleNotifications } from "../../../../utils/handleNotification";
import DatePicker from "../../../../ui/forms/DatePicker";
import useForm from "../../../../hooks/useForm";
import useBorrowerInfo from "../store";

const MAX_NO_INSTALLMENT = 3;
const findPtpMaxDate = () => {
    const today = new Date()
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)
    const nexSeventhDay = new Date(new Date().setDate(today.getDate() + 7))
    return nexSeventhDay < lastDayOfMonth ? nexSeventhDay : lastDayOfMonth
  }
  const ptpMaxDate = findPtpMaxDate();

const emiFields = Array.from({ length: MAX_NO_INSTALLMENT }).reduce((acc, _, index) => {
    acc[`emi${index + 1}`] = "";
    acc[`emi${index + 1}_payment_date`] = "";
    return acc;
}, {});

const emiDataDefaults = {
    amount: "",
    installments: 1,
    ...emiFields,
};

export default function WantSettlement({ show, setShow = () => {}, disposition = null, reset = () => {} }) {
    const data = useBorrowerInfo((state) => state.data);
    const closeBorrowerInfo = useBorrowerInfo((state) => state.closeBorrowerInfo);

    const { callCustomer, callTypes } = useCallCustomer();

    const [emiData, handleEmiData, resetEmiData] = useForm({ ...emiDataDefaults });

    const [loading, setLoading] = useState(false);

    const closeModal = () => {
        setShow(false);
        resetEmiData();
    };
      const getMinMaxDates = useMemo(() => {
    const now = new Date()
    const dates = [
      { min: now, max: addDays(now, 7) },
      { min: addDays(now, 7), max: addDays(now, 30) },
      { min: addDays(now, 30), max: addDays(now, 60) }
    ]
    return dates
  }, [])

    const addDisposition = async () => {
        if (emiData?.amount <= 0 || !emiData?.amount) {
            showToast({ variant: "warning", message: "Please enter an amount > 0" });
            return;
        }

        setLoading(true);

        const { data: customerData } = data;

        const settlementEmis = Array.from({ length: emiData?.installments }, (_, i) => ({
            emi_no: i + 1,
            amount: Number(emiData?.[`emi${i + 1}`]),
            payment_date: emiData?.[`emi${i + 1}_payment_date`] ? format(new Date(emiData?.[`emi${i + 1}_payment_date`]), "yyyy-MM-dd") : "",
        }));

        const res = await postDispositionData({
            loan_account_no: !!customerData?.loan_account_no ? customerData?.loan_account_no : null,
            batch_id: customerData?.batch ? customerData?.batch : null,
            product_name: customerData?.model ? customerData?.model : null,
            due_agmt_no: customerData?.due_agmt_no ? customerData?.due_agmt_no : null,
            customer_name: customerData?.customer_name ? customerData?.customer_name : null,
            mobile_number: customerData?.customer_mobile_number ? customerData?.customer_mobile_number : null,
            due_amount: customerData?.total_due ? customerData?.total_due : 0,
            payment_mode: customerData?.payment_type ? customerData?.payment_type : null,

            disposition,
            alt_contact_number: null,
            remark: null,
            ptp_amount: 0,
            ptp_date: null,
            paid_amount: 0,

            settlement_amount: emiData?.amount ? emiData?.amount : null,
            number_of_emi: emiData?.installments,
            settlement_emi_data: settlementEmis,

            contact_date: format(new Date(), "yyyy-MM-dd"),
            agency_name: "The Medius",
            channel: "HUMAN_CALL",
            idle_time: 0,
            chance_of_payment: 0,
        });

        if (res) {
            let message = handleNotifications({
                customer_mobile_number: customerData?.customer_mobile_number,
                message: res?.success_message,
                notification_type: res?.notification_type,
                callCustomer: () => callCustomer({ call_type: callTypes?.AGENT, customer_mobile_number: customerData?.customer_mobile_number }),
            });

            showToast({
                variant: res?.variant === "yellow" ? "warning" : res?.variant === "red" ? "danger" : res?.variant === "green" ? "success" : "info",
                message: message,
            });

            closeModal();
            resetEmiData();
            reset();
            closeBorrowerInfo();
        } else showToast({ variant: "danger", message: "Failed to Add Disposition" });

        setLoading(false);
    };

    return (
        <Modal className="popup-style-one settlement-popup" size="md" show={show}>
            <Modal.Header>
                <Modal.Title>Settlement Recovery</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isEmpty(data?.data?.pre_approved_sattlement_amount) && (
                    <div className="alert alert-warning">
                        <strong>No pre-approved settlement amount provided.</strong>
                        <br />
                        Proceed with raising settlement request without amount. You can speak to your manager to get settlement amount for this borrower.
                    </div>
                )}

                <div className="popup-content">
                    <table style={{ border: "none" }} className="table w-100 mt-0">
                        <tbody>
                            <tr>
                                <td style={{ border: "none" }} className="py-1 px-0 text-left">
                                    Total Outstanding :
                                </td>
                                <td style={{ border: "none" }} className="py-1 px-0 text-left">
                                    <strong>{!!data?.data?.pos ? `Rs. ${data?.data?.pos}` : ""}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: "none" }} className="py-1 px-0 text-right">
                                    Pre Approved Amount :
                                </td>
                                <td style={{ border: "none" }} className="py-1 px-0 text-right">
                                    <strong>
                                        {!isEmpty(data?.data?.pre_approved_sattlement_amount) ? `Rs. ${data?.data?.pre_approved_sattlement_amount}` : ""}
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: "none" }} className="py-1 px-0 text-right">
                                    EMI :
                                </td>
                                <td style={{ border: "none" }} className="py-1 px-0 text-right">
                                    <strong>{!isEmpty(data?.data?.emi) ? `Rs. ${data?.data?.emi}` : ""}</strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="pt-2">
                        <p className="mb-1">Final Settlement Amount</p>
                        <input
                            type="text"
                            className="form-control mb-1"
                            placeholder="Enter Amount"
                            value={emiData?.amount}
                            onChange={(e) => {
                                const val = e.target.value;
                                if (isNaN(val) && val !== "") return;
                                if (Number(val) < 50_000 && emiData?.installments > 1) handleEmiData("installments", 1);
                                handleEmiData("amount", val);
                            }}
                        />
                    </div>

                    <div className="mt-4">
                        <p className="mb-2">Number of Installments</p>
                        <div className="d-flex align-items-center justify-content-start gap-3">
                            {Array.from({ length: emiData?.amount < 50_000 ? 1 : MAX_NO_INSTALLMENT }).map((_, index) => {
                                const installment = index + 1;
                                return (
                                    <span
                                        key={installment}
                                        style={{
                                            border: "1px solid",
                                            borderRadius: "0.5rem",
                                            width: "2.5rem",
                                            height: "2.5rem",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            cursor: "pointer",
                                            backgroundColor: emiData?.installments === installment ? "#EFFBFE" : "transparent",
                                            borderColor: emiData?.installments === installment ? "#4498B8" : "#dedede",
                                            color: emiData?.installments === installment ? "#4498B8" : "#000",
                                        }}
                                        onClick={() => handleEmiData("installments", installment)}
                                    >
                                        {installment}
                                    </span>
                                );
                            })}
                        </div>
                    </div>

                   
        <div className="mt-4 gap-3">
          {Array.from({ length: emiData?.installments }).map((_, index) => (
            <div key={index} className="row mb-2">
              <div className="col-6">
                <p className="mb-1">{`Emi #${index + 1}`}</p>
                <input
                  type="text"
                  className="form-control mb-1"
                  placeholder="Enter Amount"
                  value={emiData?.[`emi${index + 1}`]}
                  onChange={(e) => (!isNaN(e.target.value) ? handleEmiData(`emi${index + 1}`, e.target.value) : {})}
                />
              </div>

              <div className="col-6">
                <p className="mb-1">{`Payment Date #${index + 1}`}</p>
                <DatePicker
                  label={`Payment Date #${index + 1}`}
                  value={emiData?.[`emi${index + 1}_payment_date`]}
                  onChange={(val) => handleEmiData(`emi${index + 1}_payment_date`, val)}
                  minDate={getMinMaxDates[index].min}
                  maxDate={getMinMaxDates[index].max}
                />
              </div>
            </div>
          ))}
        </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => closeModal()}>
                    Close
                </Button>

                <Button disabled={loading} onClick={() => addDisposition()} variant="primary">
                    {loading ? "Sending..." : "Send"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
