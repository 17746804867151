function functionName(val , dispA) {
    const disp = dispA && dispA.length > 1 ? dispA : []


    const sval =  val ? val.toUpperCase() : val

    let required  = disp.find(item => item.name === sval)
   if(required){
       return required.value
   }

    return sval;
}

export default functionName