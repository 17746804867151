import React, { useState, useEffect } from 'react';
import { fetchTemplates } from '../../../API';
import { postWhatsappDashboardata } from '../../../API/panelApis';

const WhatsappDashboard = ({ user }) => {
  const [broadcastName, setBroadcastName] = useState(''); // Broadcast name state
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [status, setStatus] = useState(null);
  const [messageHistory, setMessageHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState([]);

  // Fetch templates from API
  useEffect(() => {
    const loadTemplates = async () => {
      try {
        const res = await fetchTemplates();
        setTemplates(res.templates || []); // Use fetched templates
      } catch (error) {
        console.error('Error fetching templates:', error);
      }
    };
    loadTemplates();
  }, []);

  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!broadcastName || !selectedFile || !selectedTemplate) {
      setStatus({ type: 'danger', message: 'Please fill in all fields.' });
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append('broadcast_name', broadcastName); // Pass the broadcast name
    formData.append('template', selectedTemplate); // Pass the selected template
    formData.append('file', selectedFile); // Attach the selected file

    try {
      const success = await postWhatsappDashboardata(formData); // Send FormData to API
      if (success) {
        setStatus({ type: 'success', message: 'Broadcast scheduled successfully!' });
        setMessageHistory((prev) => [
          { id: Date.now(), broadcastName, fileName: selectedFile.name, status: 'scheduled', timestamp: new Date() },
          ...prev,
        ]);
        resetForm();
      } else {
        throw new Error('Failed to schedule broadcast');
      }
    } catch (error) {
      setStatus({ type: 'danger', message: 'Failed to schedule broadcast. Please try again.' });
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setBroadcastName('');
    setSelectedTemplate('');
    setSelectedFile(null);
  };

  return (
    <div className="container mt-4">
      <h1 className="mb-4">Scheduling Broadcast</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="broadcastName" className="form-label">Broadcast Name</label>
          <input
            id="broadcastName"
            type="text"
            className="form-control"
            placeholder='Broadcast Name'
            value={broadcastName}
            onChange={(e) => setBroadcastName(e.target.value)}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="template" className="form-label">Message Template</label>
          <select
            id="template"
            className="form-select"
            value={selectedTemplate}
            onChange={(e) => setSelectedTemplate(e.target.value)}
          >
            <option value="">Select a template</option>
            {templates.map((template) => (
              <option key={template.id} value={template.id} className="text-dark">
                {template.template_name}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="file" className="form-label">Select File</label>
          <input
            type="file"
            id="file"
            className="form-control"
            onChange={(e) => setSelectedFile(e.target.files[0])}
            required
          />
        </div>

        <button type="submit" className="btn btn-primary w-100" disabled={isLoading}>
          {isLoading ? 'Scheduling...' : 'Schedule Broadcast'}
        </button>
      </form>

      {status && (
        <div className={`alert alert-${status.type} mt-3`} role="alert">
          {status.message}
        </div>
      )}
    </div>
  );
};

export default WhatsappDashboard;
