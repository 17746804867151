const calls = {
    collectionBatch: "/dashboard/api/batch/",
    uploadBatch: "",
    uploadPreBatch: "/dashboard/api/pre_litigation_batch/",
    collectionAccounts: "/dashboard/api/account/",
    login: "/account/api/login/",
    sms: "message91/api/get_message_converjations_by_customer_mobile_number/",
    whatsapp: "/dashboard/api/get_convo_sql/",
    uploadReceipts: "/dashboard/api/paid_receipt/",
    allDownload: "/dashboard/api/download_all_paid_receipts/",
    fileSingleFir: "/fir/api/single_fir/",
    checkPdfUrl: "/fir/api/complaints/?complaint_id=",
};

export default calls;
