import React, { useEffect, useCallback } from "react";
import { fetchCollectionBatch, updateCollectionStatus, markAsArchiveStatus, downloadMediaUrl } from "../../../API";
import getFirstLetterCapital from "../../../Functions/getFirstLetterCapital";
import { useState } from "react";

import InputLabel from "@material-ui/core/InputLabel";
import Dropdown from "react-bootstrap/Dropdown";
import Arrow from "../../../Static/RawImages/arrow.png";
import ContentLoader from "../../../Components/ContentLoader";
import Paging from "../../../Components/Paging";
import BarRadialChart from "../../../Components/Charts/BarRadialChart";
import ReactDOM from "react-dom";
import NoRecord from "../../../Components/NoRecord";
import moment from "moment";
import { isEmpty } from "../../../Functions/isEmpty";
import { format } from "date-fns";
import useAuthStore from "../../../store/authStore";
import useGeneralStore from "../../../store/generalStore";
import MoveToNoticeModal from "../../Components/Collection/MoveToNoticeModal";
import CollectionBatchHeader from "../../Components/Collection/Batch/Header";
import { MdSmsFailed } from "react-icons/md";
import { compactFormat } from "../../../Functions/formatNumber";
import { Link, useHistory } from "react-router-dom";

function CollectionBatch() {
    const userData = useAuthStore((state) => state.userData);
    const showAlert = useGeneralStore((state) => state.open_alert);
    const updateFetchedCount = useGeneralStore((state) => state.update_fetched_count);
    const removeUser = useAuthStore((state) => state.removeUser);

    const history = useHistory();

    const [batchData, setBatchData] = useState(null);
    const [pageTotal, setPageTotal] = useState("NaN");
    const [currentPage, setCurrentPage] = useState(1);
    const [searchParameter, setSearchParameter] = useState({});
    const [HitReload, setHitReload] = useState(false);
    const [loading, setLoading] = useState(false);

    const [showMoveToNoticeModal, setShowMoveToNoticeModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const fetchCollectionBatchData = useCallback(async () => {
        setLoading(true);
        try {
            let myParams;
            myParams = { ...searchParameter, page: currentPage, size: 10 };
            const res = await fetchCollectionBatch(myParams);
            if (res && res.status) {
                const resData = res.data;
                setBatchData(resData);
                handleInitPagination(res);
                updateFetchedCount(res.total);
            } else {
                setBatchData([]);
                showAlert({ variant: "danger", msg: "Unable to fetch Batch details" });
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                removeUser();
                history.push("/login");
                console.log('Unauthorized access. Please log in again.');
              } else {
                console.error('An error occurred:', error);
              }
        }
        setLoading(false);
    }, [searchParameter, currentPage, HitReload]);

    const handleInitPagination = (res) => {
        let total = res.total;
        let totalPage = Math.ceil(total / 10);
        setPageTotal(totalPage);
    };

    const handlePagination = (type, target) => {
        if (type === "directValue") setCurrentPage(parseInt(target));
    };

    const changeChartData = ({ index, lableValue1, lableValue2 }) => {
        const lable1 = document.getElementById(`cb_toggelLable1_${index}`);
        const lable2 = document.getElementById(`cb_toggelLable2_${index}`);

        if (lableValue1) {
            lable1.innerHTML = getFirstLetterCapital(lableValue1);
            lable1.title = lableValue1;
        } else {
            lable2.innerHTML = lableValue2 === "total" ? "All Product" : getFirstLetterCapital(lableValue2);
            lable2.title = lableValue2;
        }

        let filter2 = lable2.title;
        let filter1 = lable1.title;

        const data = {
            data: [
                {
                    name: "Connected",
                    value:
                        filter1 === "accounts"
                            ? batchData?.[index]?.statics_data?.[0]?.[filter2]?.connected
                            : batchData?.[index]?.statics_data?.[0]?.[filter2]?.connected_amount,
                    color: "#359aba",
                    indexValue: index,
                    tooltipText: "Connected Calls - ",
                },
                {
                    name: "PTP",
                    value:
                        filter1 === "accounts"
                            ? batchData?.[index]?.statics_data?.[0]?.[filter2]?.ptp
                            : batchData?.[index]?.statics_data?.[0]?.[filter2]?.ptp_amount,
                    color: "#4fbcce",
                    indexValue: index,
                    tooltipText: "Promise To Pay - ",
                },
                {
                    name: "Not Connected",
                    value: batchData?.[index]?.statics_data?.[0]?.[filter2]?.not_connected_amount,
                    color: "#4fbcce",
                    indexValue: index,
                    tooltipText: "Not Connected - ",
                },
                // {
                //     name: "Broken PTP",
                //     value:
                //         filter1 === "accounts"
                //             ? batchData?.[index]?.statics_data?.[0]?.[filter2]?.broken_ptp
                //             : batchData?.[index]?.statics_data?.[0]?.[filter2]?.broken_ptp_amount,
                //     color: "#4fbcce",
                //     indexValue: index,
                //     tooltipText: "Broken PTP - ",
                // },
                {
                    name: "Paid",
                    value:
                        filter1 === "accounts"
                            ? batchData?.[index]?.statics_data?.[0]?.[filter2]?.paid
                            : batchData?.[index]?.statics_data?.[0]?.[filter2]?.paid_amount,
                    color: "#9debdc",
                    indexValue: index,
                    tooltipText: "Paid - ",
                },
            ],
            title: compactFormat(batchData?.[index].statics_data?.[0]?.[filter2]?.[filter1]),
            tooltipText: "Connected Calls - ",
        };

        const parentChart = document.getElementById(`${index}_cb_chart-box`);

        ReactDOM.unmountComponentAtNode(parentChart);

        ReactDOM.render(<BarRadialChart data={data} scale={1} />, parentChart);
    };

    const batchStatusUpdate = async (btchId, status) => {
        try {
            let body = {
                batch_id: btchId,
                client_id: userData?.client_id,
                batch_status: status,
            };
            const res = await updateCollectionStatus(body);
            if (res && res.status) {
                showAlert({
                    variant: "info",
                    msg: res?.message,
                });
                fetchCollectionBatchData();
            } else {
                showAlert({
                    variant: "warning",
                    msg: res?.message,
                });
            }
        } catch (error) {
            showAlert({
                variant: "danger",
                msg: error.toString(),
            });
        }
    };

    const batchArchiveUpdate = async (btchId) => {
        try {
            let body = {
                batch_id: [btchId],
                client_id: userData?.client_id,
                // batch_status: status,
            };
            const res = await markAsArchiveStatus(body);
            if (res && res.status) {
                showAlert({
                    variant: "info",
                    msg: res?.message,
                });
                fetchCollectionBatchData();
            } else {
                showAlert({
                    variant: "warning",
                    msg: res?.message,
                });
            }
        } catch (error) {
            showAlert({
                variant: "danger",
                msg: error.toString(),
            });
        }
    };

    useEffect(() => {
        fetchCollectionBatchData();
    }, [fetchCollectionBatchData, HitReload]);

    return (
        <>
            <CollectionBatchHeader setSearchParameter={setSearchParameter} setHitReload={setHitReload} />
            <hr className="mt-3 mb-5" />

            {!loading && !!batchData?.length && (
                <>
                    {batchData?.map((item, index) => {
                        let data = {
                            data: [
                                {
                                    name: "Connected",
                                    value: item?.statics_data?.[0]?.total?.connected || 0,
                                    color: "#359aba",
                                    indexValue: index,
                                    tooltipText: "Connected Calls - ",
                                },
                                {
                                    name: "PTP",
                                    value: item?.statics_data?.[0]?.total?.ptp || 0,
                                    color: "#4fbcce",
                                    indexValue: index,
                                    tooltipText: "Promise To Pay - ",
                                },
                                {
                                    name: "Not Connected",
                                    value: item?.statics_data?.[0]?.total?.not_connected_amount || 0,
                                    color: "#4fbcce",
                                    indexValue: index,
                                    tooltipText: "Not Connected - ",
                                },
                                // {
                                //     name: "Broken PTP",
                                //     value: item?.statics_data?.[0]?.total?.broken_ptp || 0,
                                //     color: "#4fbcce",
                                //     indexValue: index,
                                //     tooltipText: "Broken PTP - ",
                                // },
                                { name: "Paid", value: item?.statics_data?.[0]?.total?.paid || 0, color: "#9debdc", indexValue: index, tooltipText: "Paid - " },
                            ],
                            title: item?.statics_data?.[0]?.total?.accounts ? compactFormat(item?.statics_data?.[0]?.total?.accounts) : "0",
                            tooltipText: "Connected Calls - ",
                        };
                        return (
                            <React.Fragment key={index}>
                                {/* <UploadBatch /> */}
                                <div key={item?.id} className="row d-flex justify-between batch-listing">
                                    <div className="col-md-3">
                                        <div className="d-flex align-start">
                                            <div className="border-right-dotted w-100">
                                                <p className="mb-2">
                                                    {/* <b className="text-blue"> Batch No. {item?.client_batch_id}</b> */}

                                                    <Link to={`/collection/accounts/${item?.batch_id}`}>
                                                        <b className="text-blue"> Batch No. {item?.client_batch_id}</b>
                                                    </Link>
                                                </p>
                                                <p className="mb-2">
                                                    <span className="batch-name" title={item?.batch_name}>
                                                        Ref - {item?.batch_name}
                                                    </span>
                                                </p>
                                                {/* upload_failure_reason , failed*/}
                                                <p className="mb-2">
                                                    Uploaded Date - {!isEmpty(item?.uploaded_date) ? format(new Date(item?.uploaded_date), "do MMM yyyy") : ""}
                                                </p>
                                                <div>
                                                    {item?.batch_status === "new" ? (
                                                        moment.duration(moment().startOf("day").diff(moment(item?.uploaded_date, "YYYY-MM-DD"))).asDays() <
                                                        1 ? (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="43" height="25" viewBox="-263.6 383.2 43.3 25.2">
                                                                <path
                                                                    className="st0"
                                                                    fill="#FF5700"
                                                                    d="M-220.3,395.8c0-7-5.7-12.6-12.6-12.6l-30.6,0l0,25.2l30.7,0C-226,408.4-220.3,402.8-220.3,395.8z M-232.9,406.6l-28.8,0l0-21.6l28.8,0c6,0,10.8,4.9,10.8,10.8C-222.1,401.8-227,406.6-232.9,406.6z"
                                                                />
                                                                <path
                                                                    className="st0"
                                                                    fill="#FF5700"
                                                                    d="M-232.9,386.8l-27,0l0,18l27,0c5,0,9-4,9-9C-223.9,390.8-227.9,386.8-232.9,386.8z M-250.5,399.4l-1.6-2.8 c-0.4-0.8-0.9-1.7-1.3-2.6l0,0c0,1,0.1,2,0.1,3.2l0,2.2l-1.5,0l0-7.3l1.9,0l1.5,2.7c0.4,0.8,0.9,1.7,1.2,2.5l0,0 c-0.1-1-0.1-1.9-0.1-3l0-2.1l1.5,0l0,7.3L-250.5,399.4L-250.5,399.4z M-247.3,399.4l0-7.3l4.5,0l0,1.4l-2.8,0l0,1.5l2.7,0l0,1.3 l-2.7,0l0,1.7l3,0l0,1.3C-242.7,399.4-247.3,399.4-247.3,399.4z M-236.4,399.4l-0.6-3.1c-0.1-0.7-0.3-1.4-0.3-2.2l0,0 c-0.1,0.8-0.3,1.5-0.4,2.2l-0.7,3.1l-1.8,0l-1.7-7.3l1.8,0l0.6,3c0.2,0.9,0.3,1.8,0.4,2.5l0,0c0.1-0.8,0.3-1.7,0.5-2.6l0.6-3 l1.8,0l0.6,3.1c0.2,0.9,0.3,1.6,0.4,2.4l0,0c0.1-0.8,0.3-1.7,0.4-2.5l0.6-3l1.7,0l-1.9,7.3 C-234.6,399.4-236.4,399.4-236.4,399.4z M-228.2,397.7c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2 C-226.2,396.9-227.1,397.8-228.2,397.7z"
                                                                />
                                                            </svg>
                                                        ) : (
                                                            <p>
                                                                Status - <span className="text-info fw-bold">In Progress</span>
                                                            </p>
                                                        )
                                                    ) : item?.batch_status === "completed" ? (
                                                        <p>
                                                            Status - <span className="text-success fw-bold">Completed</span>
                                                        </p>
                                                    ) : item?.batch_status === "Active_Batches" ? (
                                                        <p>
                                                            Status - <span className="text-warning fw-bold">Active</span>
                                                        </p>
                                                    ) : item?.batch_status === "uploading" ? (
                                                        <p>
                                                            Status - <span className="text-info fw-bold">Uploading</span>
                                                        </p>
                                                    ) : item?.batch_status === "failed" ? (
                                                        <p>
                                                            Status - <span className="text-danger fw-bold">Failed</span>
                                                        </p>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                <div>
                                                    {item?.archiveStatus === "archived" ? (
                                                        <p>
                                                            Archive Status - <span className="text-success fw-bold">Archived</span>
                                                        </p>
                                                    ) : item?.archiveStatus === "pending" ? (
                                                        <p>
                                                            Archive Status - <span className="text-warning fw-bold">Pending</span>
                                                        </p>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>

                                                {!!item?.upload_failure_reason && (
                                                    <>
                                                        <div className="alert alert-danger py-1 px-2 my-1 d-flex align-items-start gap-2 me-1">
                                                            <MdSmsFailed style={{ fontSize: "18px", marginTop: "0.2rem" }} />
                                                            {item?.upload_failure_reason}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 border-right-dotted">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="ps-4">
                                                    <Dropdown>
                                                        <Dropdown.Toggle
                                                            variant="primary"
                                                            className="batch-select mb-4 dropdown-nostyle text-start select-style"
                                                        >
                                                            <span id={`cb_toggelLable1_${index}`} title="accounts">
                                                                Accounts
                                                            </span>
                                                            <img src={Arrow} alt="Sort" />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="select-style-dropdown">
                                                            <Dropdown.Item
                                                                onClick={() => {
                                                                    changeChartData({ index: index, lableValue1: "accounts" });
                                                                }}
                                                            >
                                                                Accounts
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                onClick={() => {
                                                                    changeChartData({ index: index, lableValue1: "amount" });
                                                                }}
                                                            >
                                                                Amount
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <Dropdown>
                                                        <Dropdown.Toggle
                                                            variant="primary"
                                                            id="dropdown-basic"
                                                            className="batch-select mb-4 dropdown-nostyle text-start select-style"
                                                        >
                                                            <span id={`cb_toggelLable2_${index}`} title="total">
                                                                All Product
                                                            </span>
                                                            <img src={Arrow} alt="Sort" />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="select-style-dropdown">
                                                            {item?.statics_data.length > 0 &&
                                                                Object.keys(item?.statics_data?.[0]).map((mapItem, i) => {
                                                                    return (
                                                                        <Dropdown.Item
                                                                            key={i}
                                                                            onClick={() => {
                                                                                changeChartData({ index: index, lableValue2: mapItem });
                                                                            }}
                                                                            href=""
                                                                        >
                                                                            {mapItem === "total" ? "All Product" : getFirstLetterCapital(mapItem)}
                                                                        </Dropdown.Item>
                                                                    );
                                                                })}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="row status-progress">
                                                    <div className="chart-box" id={`${index}_cb_chart-box`}>
                                                        <BarRadialChart data={data} scale={1} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 ps-5">
                                        <InputLabel id="label" className="mb-1">
                                            Action
                                        </InputLabel>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="primary" id="dropdown-basic" className="w-100 text-start select-style">
                                                Select <img src={Arrow} alt="Sort" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="select-style-dropdown">
                                                <Dropdown.Item
                                                    disabled={!item?.raw_file}
                                                    href={downloadMediaUrl(item?.raw_file)}
                                                    onClick={() => showAlert({ variant: "info", msg: "Downloading Report." })}
                                                >
                                                    Download Uploaded Data
                                                </Dropdown.Item>
                                                {item?.batch_status === "completed" && (
                                                    <Dropdown.Item onClick={() => batchStatusUpdate(item?.batch_id, "new")}>Mark as Active</Dropdown.Item>
                                                )}
                                                {item?.batch_status === "new" && (
                                                    <Dropdown.Item onClick={() => batchStatusUpdate(item?.batch_id, "completed")}>
                                                        Mark as Completed
                                                    </Dropdown.Item>
                                                )}
                                                {/* {item?.batch_status === "completed" && item?.archiveStatus === null && (
                                                    <Dropdown.Item onClick={() => batchArchiveUpdate(item?.batch_id)}>Archive Request</Dropdown.Item>
                                                )} */}

                                                <Dropdown.Item
                                                    onClick={() => {
                                                        setSelectedItem(item);
                                                        setShowMoveToNoticeModal(true);
                                                    }}
                                                >
                                                    Move to Notice
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>

                                <hr className="mt-4 mb-4" />
                            </React.Fragment>
                        );
                    })}

                    <div className="mt-0 pb-2">
                        <Paging currentPage={currentPage} pageTotal={pageTotal} handlePagination={handlePagination} />
                    </div>

                    <MoveToNoticeModal show={showMoveToNoticeModal} setShow={setShowMoveToNoticeModal} item={selectedItem} setItem={setSelectedItem} />
                </>
            )}

            {loading && <ContentLoader />}
            {!loading && !batchData?.length && <NoRecord />}
        </>
    );
}

export default CollectionBatch;
