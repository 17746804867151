import React, { useState } from "react";
import classes from "./style.module.css";
import { postSendSessionMessage } from "../../API";
import { showToast } from "../../utils/handleToast";
import MessageInput from "./MessageInput";
import Actions from "./Actions";

export default function SendMessage({ number, onSend = () => {}, isCandidate = true, isDisabled = false, actions = [] }) {
  const [loading, setLoading] = useState(false);

  const onSendMessage = async ({ msg, file }) => {
    if (!(msg || file) || !number) return;

    setLoading(true);

    const formData = new FormData();
    formData.append("number", number);
    formData.append("is_candidate", isCandidate);

    if (msg) {
      formData.append("msg", msg);
    }

    if (file) {
      formData.append("msg_caption", "Document");
      formData.append("file", file);
      formData.append("msg_type", "DOCUMENT");
    }

    const res = await postSendSessionMessage(formData);

    if (res) {
      showToast({ variant: "success", message: res?.message });
      onSend();
    } else {
      showToast({ variant: "danger", message: "Failed to send message" });
    }

    setLoading(false);
  };

  if (!isDisabled)
    return (
      <>
        <div className={classes.messaging_container}>
          {/* Actions */}
          <Actions loading={loading} onSendMessage={onSendMessage} />

          {/* send message */}
          <MessageInput loading={loading} onSendMessage={onSendMessage} />
        </div>
      </>
    );

  return (
    <div className={classes.messaging_container_disabled}>
      <p className="px-4 m-0 text-muted">You cannot send a message as 24 hours have passed since the last reply, or if there has been no reply.</p>
    </div>
  );
}
