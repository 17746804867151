import React, { useState, useEffect } from "react";
import FilterButton from "../../../../ui/buttons/FilterButton";
import SortButton from "../../../../ui/buttons/SortButton";
import { format } from "date-fns";
import { Dropdown } from "react-bootstrap";
import UpdateBatchData from "./UpdateBatchData";
import UploadNewBatch from "./UploadNewBatch";
import CollectionBatchFilter from "./Filter";
import UploadWithdrawData from "./UploadWithdrawData";

function CollectionBatchHeader({ setSearchParameter, setHitReload }) {
    const [SortAsc, setSortAsc] = useState(true);

    // filter
    const [showFilter, setShowFilter] = useState(false);
    const [filterData, setFilterData] = useState({
        search: "",
        batch_no: "",
        date_from: null,
        date_to: null,
        specific_date: null,
        batch_status: "",
        all_communication_mode: "",
    });
    const [searchBy, setSearchBy] = useState("");

    const [showUpdateBatchDataModal, setShowUpdateBatchDataModal] = useState(false);
    const [showUploadNewBatchModal, setShowUploadNewBatchModal] = useState(false);
    const [showUploadWithdrawData, setShowUploadWithdrawData] = useState(false);

    const filterMapping = () => ({
        batch_name: filterData?.search || "",
        client_batch_id: filterData?.batch_no || "",
        batch_status: filterData?.batch_status || "",
        from_date: !!filterData?.date_from ? format(new Date(filterData?.date_from), "yyyy-MM-dd") : "",
        to_date: !!filterData?.date_to ? format(new Date(filterData?.date_to), "yyyy-MM-dd") : "",
        specific_date: !!filterData?.specific_date ? format(new Date(filterData?.specific_date), "yyyy-MM-dd") : "",
    });

    useEffect(() => {
        // apply filter actiins
        setSearchParameter({ ...filterMapping() });
    }, [filterData]);

    return (
        <div className="row d-flex d-wrap justify-between filter-section">
            <div className="d-flex d-wrap align-items-center justify-content-between gap-4">
                <div className="d-flex align-center">
                    <FilterButton onClick={() => setShowFilter(true)} active={!!filterData && !!Object.values(filterData)?.some((el) => !!el)} />
                    <SortButton
                        onClick={() => {
                            setSortAsc(!SortAsc);
                            let sort = SortAsc ? "asc" : "dsc";
                            setSearchParameter({ ...filterMapping(), sort });
                        }}
                        label="Sort By Batch"
                        active={!SortAsc}
                    />

                    {/* <p className="mb-0 ms-3 tx-12 tx-grey">Ongoing data will be completed in <strong className="text-black">15 days</strong>.</p> */}
                </div>

                <div className="d-wrap d-flex align-items-center justify-content-end gap-2">
                    <Dropdown>
                        <Dropdown.Toggle variant="outline-primary" type="button" id="dropdown-basic" className="btn-lg">
                            <span className="me-2">More Actions</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu style={{ border: "1px solid #009ec0" }} className="select-style-dropdown w-auto">
                            <Dropdown.Item
                                onClick={() => setShowUpdateBatchDataModal(true)}
                                className="d-flex align-items-center justify-content-start gap-2 py-2"
                            >
                                <svg width="16" height="16" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.9988 7.12H11.2188L13.9588 4.3C11.2288 1.6 6.80878 1.5 4.07878 4.2C1.34878 6.91 1.34878 11.28 4.07878 13.99C6.80878 16.7 11.2288 16.7 13.9588 13.99C15.3188 12.65 15.9988 11.08 15.9988 9.1H17.9988C17.9988 11.08 17.1188 13.65 15.3588 15.39C11.8488 18.87 6.14878 18.87 2.63878 15.39C-0.861217 11.92 -0.891217 6.28 2.61878 2.81C6.12878 -0.66 11.7588 -0.66 15.2688 2.81L17.9988 0V7.12V7.12ZM9.49878 5V9.25L12.9988 11.33L12.2788 12.54L7.99878 10V5H9.49878Z" />
                                </svg>
                                Update Batch Data
                            </Dropdown.Item>

                            <Dropdown.Item
                                onClick={() => setShowUploadWithdrawData(true)}
                                className="d-flex align-items-center justify-content-start gap-2 py-2"
                            >
                                <svg width="14" height="17" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="currentColor" fillRule="evenodd">
                                        <path d="M.903 8.201a.7.7 0 0 1 1.4 0v5.601a.7.7 0 0 0 .701.7h8.405a.7.7 0 0 0 .7-.7v-5.6a.7.7 0 0 1 1.4 0v5.6a2.1 2.1 0 0 1-2.1 2.1H3.004a2.1 2.1 0 0 1-2.101-2.1v-5.6zM7.206 2.19 4.9 4.497a.7.7 0 0 1-.99-.99L6.71.706a.7.7 0 0 1 .99 0l2.802 2.8a.7.7 0 1 1-.99.99L7.206 2.19z" />
                                        <path d="M6.506 1.2a.7.7 0 0 1 1.4 0v9.102a.7.7 0 0 1-1.4 0V1.2z" />
                                    </g>
                                </svg>
                                Upload Withdraw Data
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    <button className="btn btn-primary btn-lg ms-2" onClick={() => setShowUploadNewBatchModal(true)}>
                        <svg width="14" height="17" xmlns="http://www.w3.org/2000/svg">
                            <g fill="currentColor" fillRule="evenodd">
                                <path d="M.903 8.201a.7.7 0 0 1 1.4 0v5.601a.7.7 0 0 0 .701.7h8.405a.7.7 0 0 0 .7-.7v-5.6a.7.7 0 0 1 1.4 0v5.6a2.1 2.1 0 0 1-2.1 2.1H3.004a2.1 2.1 0 0 1-2.101-2.1v-5.6zM7.206 2.19 4.9 4.497a.7.7 0 0 1-.99-.99L6.71.706a.7.7 0 0 1 .99 0l2.802 2.8a.7.7 0 1 1-.99.99L7.206 2.19z" />
                                <path d="M6.506 1.2a.7.7 0 0 1 1.4 0v9.102a.7.7 0 0 1-1.4 0V1.2z" />
                            </g>
                        </svg>
                        Upload New Batch File
                    </button>
                </div>
            </div>

            {/* Filter */}
            <CollectionBatchFilter
                show={showFilter}
                close={() => setShowFilter(false)}
                prevFilter={filterData}
                setPrevFilter={setFilterData}
                searchByPrev={searchBy}
                setSearchByPrev={setSearchBy}
            />

            {/* Upload New Batch */}
            <UploadNewBatch
                show={showUploadNewBatchModal}
                setShow={setShowUploadNewBatchModal}
                reload={() => {
                    setHitReload((prev) => !prev);
                }}
            />

            {/* Batch Data Update Modal */}
            <UpdateBatchData show={showUpdateBatchDataModal} setShow={setShowUpdateBatchDataModal} />

            {/* Upload Withdraw Data */}
            <UploadWithdrawData show={showUploadWithdrawData} setShow={setShowUploadWithdrawData} />
        </div>
    );
}

export default CollectionBatchHeader;
