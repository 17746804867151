import { create } from "zustand";
import { devtools } from "zustand/middleware";

const store = (set) => ({
    // customer details - source of leads
    customer_source_of_lead: "",
    addCustomerSourceOfLeadData: (customer_source_of_lead) => set(() => ({ customer_source_of_lead })),
    removeCustomerSourceOfLeadData: () => set(() => ({ customer_source_of_lead: "" })),

    // customer id
    customer_id: "",
    addCustomerId: (customer_id) => set(() => ({ customer_id })),
    removeCustomerId: () => set(() => ({ customer_id: "" })),

    // incoming call popup
    show_incoming_call: false,
    toggleIncomingCall: () => set((state) => ({ show_incoming_call: !state.show_incoming_call })),
    showIncomingCall: () => set(() => ({ show_incoming_call: true })),
    hideIncomingCall: () => set(() => ({ show_incoming_call: false })),

    incoming_call_data: [],
    addIncomingCallData: (data) => set((state) => ({ incoming_call_data: [data, ...state.incoming_call_data].slice(0, 5) })),

    removeIncomingCallData: ({ customer_number }) =>
        set((state) => ({
            incoming_call_data: [...state.incoming_call_data.filter((item) => item?.customer_number?.toString() !== customer_number?.toString())],
        })),
    clearIncomingCallData: () => set(() => ({ incoming_call_data: [] })),

    // call common activity api
    call_common_activity_toggle: false,
    toggleCallCommonActivity: () => set((state) => ({ call_common_activity_toggle: !state.call_common_activity_toggle })),

    // call on adding disposition
    reload_now: false,
    reload: () => set((state) => ({ reload_now: !state.reload_now })),

    // common activity data
    common_activity_data: [],
    addCommonActivityData: ({ common_activity_data }) => set(() => ({ common_activity_data })),
    removeCommonActivityData: () => set(() => ({ common_activity_data: [] })),

    // lead counts
    lead_count_fetch_flag: true,
    toggleLeadCountFetchFlag: () => set((state) => ({ lead_count_fetch_flag: !state.lead_count_fetch_flag })),
    lead_counts: {
        important_leads: 0,
        addtional_leads: 0,
        pending_disposition_leads: 0,
        broken_ptp_leads: 0,
        settlement_leads: 0,
        ptp_leads: 0,
    },
    addLeadCounts: (newCount) =>
        set((state) => ({
            lead_counts: { ...state?.lead_counts, ...newCount },
        })),
    resetLeadCounts: () =>
        set(() => ({
            lead_counts: {
                important_leads: 0,
                addtional_leads: 0,
                pending_disposition_leads: 0,
                broken_ptp_leads: 0,
                settlement_leads: 0,
                ptp_leads: 0,
            },
        })),
});

const useAgentStore = create(devtools(store));

export default useAgentStore;
