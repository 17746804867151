import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import adminbg from "../../Static/RawImages/admin-login-bg.png";
import mediusWhite from "../../Static/RawImages/logo-white.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import useAuthStore from "../../store/authStore";
import { login } from "../../API/commonApis";

function Login() {
  const createUser = useAuthStore((state) => state.createUser);
  const removeUser = useAuthStore((state) => state.removeUser);
  const isUserAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const userdata = useAuthStore((state) => state.userData);

  const email = useRef(null);
  const password = useRef(null);
  const [loginErrLog, setLoginErrLog] = useState([]);

  const history = useHistory();

  const handelSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoginErrLog([]);

      if (email.current.value && password.current.value) {
        localStorage.clear();
        const formData = new FormData();
        formData.append("email", email.current.value.toLowerCase().trim());
        formData.append("password", password.current.value);

        const res = await login(formData);
        let data = res.data;
        let message = res.message;
        if (res === "error") {
          toast.error("Invalid Credentials");
        }
        if (message === "Incorrect email ID.") {
          setLoginErrLog((prevLog) => [...prevLog, "Email Is Invalid"]);
        }
        if (message === "Incorrect password.") {
          setLoginErrLog((prevLog) => [...prevLog, "Password Is Invalid"]);
        }
        if (!data) {
          setLoginErrLog((prevLog) => [...prevLog, message ?? ""]);
        }
        if (data) {
          if (data.role === "admin") {
            removeUser();
            createUser({
              token: data.token,
              role: "admin",
              userData: {
                email: data.email,
                full_name: data.full_name,
                user_id: data.user_id,
                client_id: data.active_client?.client_id,
                logo_url: data.active_client?.logo_url,
                groups: data.groups,
              },
            });
          } else {
            setLoginErrLog((prevLog) => [...prevLog, "Not A Admin's Email"]);
          }
        }
      } else {
        if (!email.current.value) {
          setLoginErrLog((prevLog) => [...prevLog, "Email Is Blank"]);
        }
        if (!password.current.value) {
          setLoginErrLog((prevLog) => [...prevLog, "Password Is Blank"]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (isUserAuthenticated) {
    const userGroups = userdata?.groups || [];

    if (userGroups.includes("all")) {
      history.push("/admin/others/dashboard");
    } else if (userGroups.includes("call_quality")) {
      history.push("/admin/daily-ops/call-quality");
    } else if (userGroups.includes("chat")) {
      history.push("/admin/chat-panel/chat");
    } else if (userGroups.includes("settlement")) {
      history.push("/admin/settlements/manage-settlements");
    } else if (userGroups.includes("agent")) {
      history.push("/admin/agents/manage-agents");
    } else if (userGroups.includes("hr")) {
      history.push("/admin/hr/candidates");
    } else {
      history.push("/admin/others/dashboard");
    }

    return null;
  }

  return (
    <div style={{ backgroundImage: `url(${adminbg})` }} className="admin-login">
      <div className="container-fluid">
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick />
        <div className="row">
          <div className="col-md-12 ps-5">
            <img src={mediusWhite} alt="login" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-sm-6 col-md-5 col-lg-4 pt-5">
            <form onSubmit={handelSubmit}>
              <div className="login-text">
                <span>Welcome To</span>
                <br />
                Admin Panel
              </div>
              <div className="mb-3 row">
                <label htmlFor="login_email" className="col-sm-12 col-form-label">
                  Email Id
                </label>
                <div className="col-sm-12">
                  <input ref={email} type="text" id="login_email" className="form-control" />
                </div>
                {loginErrLog.length > 0 && (
                  <div style={{ fontSize: 16 }} role="alert">
                    <div className="text-danger">{loginErrLog[0]}</div>
                  </div>
                )}
              </div>
              <div className="mb-3 row">
                <label htmlFor="login_password" className="col-sm-12 col-form-label">
                  Password
                </label>
                <div className="col-sm-12">
                  <input ref={password} type="password" id="login_password" className="form-control" />
                </div>
                {loginErrLog.length > 1 && (
                  <div style={{ fontSize: 16 }} role="alert">
                    <div className="text-danger">{loginErrLog[1]}</div>
                  </div>
                )}
              </div>
              <div className="mb-0 row">
                <div className="col-sm-12">
                  <button type="submit" className="btn btn-primary w-100 mt-2">
                    Login
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
