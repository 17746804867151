// Preletigation Fir Batch Upload Mappings

export const toShowParameters = [
    "lan_number",
    "customer_name",
    "state",
    "zip_code",
    "prd_grp",
    "emi_amount",
    "od_pos_final",
    "bkt_grp",
    "mobile",
    "alt_mobile_1",
    "alt_mobile_2",
    "address1",
    "address2",
    "address3",
    "address4",
    "district",
    "police_station_name",
    "clm_name",
    "clm_contact_no"
];

export const toHideParameters = ["company_name", "email", "city"];

export const labelMapping = {
    email: "Email",
    company_name: "Company Name",
    lan_number: "Loan Account No.",
    customer_name: "Customer Name",
    address1: "Address 1",
    address2: "Address 2",
    address3: "Address 3",
    address4: "Address 4",
    city: "City",
    state: "State",
    district: "District",
    police_station_name: "Police Station",
    zip_code: "Pincode",
    mobile: "Primary Contact",
    od_pos_final: "Total Oustanding",
    prd_grp: "Product group",
    emi_amount: "EMI",
    bkt_grp: "Due Since / BKT",
    alt_mobile_1: "2nd Contact",
    alt_mobile_2: "3rd Contact",
    address_type: "Address type",
    clm_name: "Collection Manager Name",
    clm_contact_no: "Collection Manager Mobile",
};

export const mappings = {
    lan_number: ["lanno", "loanno", "loannumber", "lan", "loanaccountno", "loanaccountnumber", "agmtno","accountreference"],
    company_name: ["entityname", "entity", "clientname"],
    customer_name: ["customername", "cname", "custname", "customer", "name", "nameofcustomer", "borrowername", "barrowername", "contactname"],
    email: ["email", "mail", "emailid", "mailid","currentemail"],
    address1: ["address1", "add1", "customerresiadd", "address", "currentaddress", "barrowercustomeraddress1"],
    address2: ["address2", "add2", "barrowercustomeraddress2"],
    address3: ["address3", "add3", "barrowercustomeraddress3"],
    address4: ["address4", "add4", "barrowercustomeraddress4"],
    city: ["city", "barrowercity"],
    state: ["state", "barrowerstate"],
    district: ["district", "districtname"],
    police_station_name: ["policestation", "station"],
    zip_code: ["zipcode", "barrowercustomerpincode", "barrowercustomerzipcode", "pincode", "pin", "zip","currentpincode"],
    mobile: ["mobile", "customerm", "mobilenumber","currentmobile"],
    od_pos_final: ["odposfinal", "netdisbamount", "outstandingamount", "tos"],
    prd_grp: ["prdgrp", "productcategory1", "product", "pro", "productname", "prdname" ,"productgroup"],
    emi_amount: ["emi", "emiamount", "ftotemi"],
    alt_mobile_1: ["permanentmobile"],
    clm_name: ["collectionmanagername","manager"],
    clm_contact_no: ["managermobileno"],
};
