export const postalIcon = (
    <svg width="25" height="22" xmlns="http://www.w3.org/2000/svg">
        <g fill="#505050" fillRule="nonzero">
            <path d="M14.452 21.449h-2.155a.346.346 0 1 1 0-.692h2.155a.346.346 0 1 1 0 .692zM6.682 21.449H.362a.346.346 0 0 1-.346-.346V7.106a.346.346 0 1 1 .692 0v13.65h5.974a.346.346 0 1 1 0 .693zM10.567 21.449H8.412a.346.346 0 1 1 0-.692h2.155a.346.346 0 1 1 0 .692zM23.886 21.449h-7.704a.346.346 0 1 1 0-.692h7.358V7.106a.346.346 0 0 1 .692 0v13.997a.346.346 0 0 1-.346.346z" />
            <path d="M.362 7.452a.346.346 0 0 1-.218-.615l4.15-3.363a.347.347 0 1 1 .437.539L.58 7.376a.346.346 0 0 1-.218.076zM23.886 7.452a.346.346 0 0 1-.217-.077l-4.152-3.363a.346.346 0 1 1 .436-.538l4.151 3.363a.346.346 0 0 1-.218.615z" />
            <path d="M12.124 16.57a4.49 4.49 0 0 1-3.002-1.148L.132 7.363a.346.346 0 1 1 .46-.513l8.992 8.057a3.805 3.805 0 0 0 5.08 0l8.991-8.057a.346.346 0 0 1 .462.515l-8.991 8.057a4.49 4.49 0 0 1-3.002 1.149zM14.452 21.449h-2.155a.346.346 0 1 1 0-.692h2.155a.346.346 0 1 1 0 .692z" />
            <path d="M6.682 21.449H.362a.346.346 0 0 1-.217-.615l8.216-6.636a.346.346 0 1 1 .434.54l-7.454 6.019h5.341a.346.346 0 1 1 0 .692zM10.567 21.449H8.412a.346.346 0 1 1 0-.692h2.155a.346.346 0 1 1 0 .692zM23.886 21.449h-7.704a.346.346 0 1 1 0-.692h6.725l-7.454-6.02a.346.346 0 1 1 .434-.538l8.216 6.635a.346.346 0 0 1-.217.615zM19.735 11.17a.346.346 0 0 1-.346-.345V.692H4.859v10.133a.346.346 0 1 1-.692 0V.345c0-.19.155-.345.346-.345h15.222c.19 0 .346.155.346.346v10.479a.346.346 0 0 1-.346.346z" />
            <path d="M16.967 3.805H7.281a.346.346 0 0 1 0-.691h9.686a.346.346 0 1 1 0 .691zM16.967 6.573H7.281a.346.346 0 1 1 0-.692h9.686a.346.346 0 1 1 0 .692zM16.967 9.34H7.281a.346.346 0 0 1 0-.691h9.686a.346.346 0 1 1 0 .692z" />
        </g>
    </svg>
);

export const smsIcon = (
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.52 0A11.52 11.52 0 0 0 1.44 17.074L0 23.04l5.822-1.507A11.52 11.52 0 1 0 11.52 0zm0 22.08a10.56 10.56 0 0 1-5.38-1.474l-.174-.105-4.608 1.18 1.1-4.742-.101-.173a10.56 10.56 0 1 1 9.163 5.314z"
            fill="#505050"
            fillRule="nonzero"
        />
    </svg>
);

export const emailIcon = (
    <svg width="26" height="20" xmlns="http://www.w3.org/2000/svg">
        <g fill="#505050" fillRule="nonzero">
            <path d="M0 0v14.58a4.86 4.86 0 0 0 4.86 4.86h16.2a4.86 4.86 0 0 0 4.86-4.86V0H0zm24.84 14.58a3.78 3.78 0 0 1-3.78 3.78H4.86a3.78 3.78 0 0 1-3.78-3.78V1.08h23.76v13.5z" />
            <path d="m22.94 4.142-.724-.8-9.256 8.349-9.202-8.338-.723.8 9.925 8.996z" />
        </g>
    </svg>
);

export const whatsappIcon = (
    <svg width="26" height="26" xmlns="http://www.w3.org/2000/svg">
        <g fill="#505050" fillRule="nonzero">
            <path d="M12.96 0A12.96 12.96 0 0 0 1.62 19.208L0 25.92l6.55-1.696A12.96 12.96 0 1 0 12.96 0zm0 24.84c-2.13 0-4.22-.573-6.053-1.658l-.195-.119-5.184 1.329 1.237-5.335-.114-.195A11.88 11.88 0 1 1 12.96 24.84z" />
            <path d="M8.586 14.894a16.588 16.588 0 0 0 5.518 4.322c.807.382 1.886.836 3.088.914.074.003.145.006.22.006.807 0 1.455-.278 1.983-.852.003-.003.01-.01.013-.016.188-.227.402-.431.625-.648.152-.146.308-.298.457-.454.69-.719.69-1.633-.007-2.33l-1.947-1.946c-.33-.344-.726-.525-1.14-.525-.415 0-.813.181-1.154.521l-1.16 1.16a6.164 6.164 0 0 0-.32-.168 4.007 4.007 0 0 1-.357-.195c-1.056-.67-2.015-1.545-2.932-2.67-.463-.586-.774-1.078-.991-1.58.304-.276.59-.564.865-.846.097-.1.197-.201.298-.302.35-.35.538-.754.538-1.166 0-.411-.185-.816-.538-1.166l-.966-.966c-.113-.113-.22-.223-.33-.337-.214-.22-.438-.447-.658-.651-.334-.327-.726-.499-1.14-.499-.412 0-.807.172-1.154.502L6.185 6.214c-.44.44-.69.975-.742 1.594-.061.774.081 1.597.45 2.592.568 1.539 1.423 2.968 2.693 4.494zM6.234 7.876c.039-.43.204-.79.515-1.102L7.954 5.57c.188-.181.396-.275.597-.275.197 0 .398.094.583.282.217.2.42.411.641.635l.337.343.966.966c.2.2.304.405.304.606 0 .2-.103.405-.304.605-.1.1-.201.205-.302.305-.3.305-.583.593-.894.868l-.016.017c-.269.268-.227.524-.162.719l.01.026c.25.6.596 1.17 1.137 1.85.972 1.199 1.996 2.128 3.123 2.844.14.091.289.162.428.234.13.065.25.126.356.194l.036.02c.107.055.21.08.314.08.26 0 .428-.165.483-.22l1.212-1.211c.188-.188.392-.289.593-.289.246 0 .447.152.573.289l1.954 1.95c.389.389.386.81-.01 1.221-.136.146-.278.286-.43.431-.227.22-.464.448-.678.703-.372.402-.816.59-1.39.59-.055 0-.113-.003-.168-.006-1.063-.068-2.051-.483-2.793-.836a15.759 15.759 0 0 1-5.252-4.115c-1.209-1.455-2.022-2.81-2.56-4.26-.334-.892-.46-1.608-.408-2.259z" />
        </g>
    </svg>
);
