// API Endpoints
const API_ENPOINTS = {
  call_call_quality_listing_v1: "/call/api/call-quality-listing-v1/",
  campaigns_todays_ivr_campaign_restart_summary: "/campaigns/api/todays_ivr_campaign_restart_summary/",
  client_panel_excluded_customer: "/client_panel/api/excluded_customer/",
  dashboard_candidate_interview_info: "/dashboard/api/candidate_interview_info/",
  dashboard_gupshup_mapping_summary: "/dashboard/api/gupshup_mapping_summary/",
  dashboard_borrower_page_admin_data: "/dashboard/api/borrower_page_admin_data/",
  dashboard_borrower_page_admin_action_history: "/dashboard/api/borrower_page_admin_action_history/",
  dashboard_get_gupshup_account_data: "/dashboard/api/get_gupshup_account_data/",
  dashboard_gupshup_template_mapping: "/dashboard/api/gupshup_template_mapping/",
  dashboard_gupshup_template_mappingNew: "/dashboard/api/gupshup_template_mapping_new/",
  dashboard_prospective_candidate: "/dashboard/api/prospective_candidate/",
  dashboard_prospective_candidateNew: "/dashboard/api/prospective_candidate_new/",
  dashboard_send_prospective_candidate_jd: "/dashboard/api/send_prospective_candidate_jd/",
  dashboard_generate_send_offer_letter: "/dashboard/api/generate_send_offer_letter/",
  dashboard_panel_data: "/dashboard/api/panel-data/",
  dashboard_get_agent_leads_count: "/dashboard/api/get_agent_leads_count/",
  dashboard_final_summary_management_report: "/dashboard/api/final_summary_management_report/",
  dashboard_client_poc: "/dashboard/api/client_poc/",
  dashboard_client_pocNew: "/dashboard/api/client_poc_new/",
  dashboard_payment_receipt: "/dashboard/api/payment_receipt/",
  dashboard_payment_receiptNew: "/dashboard/api/payment_receipt_new/",
  dashboard_alternate_number_management_report: "/dashboard/api/alternate_number_management_report/",
  dashboard_get_retention_data: "/dashboard/api/get_retention_data/",
  dashboard_auto_campaigning_mapping: "/dashboard/api/auto_campaigning_mapping/",
  dashboard_auto_campaigning_mappingNew: "/dashboard/api/auto_campaigning_mapping_new/",
  dashboard_upload_media: "/dashboard/api/upload_media/",
  dashboard_gupshup_template: "/dashboard/api/gupshup_template/",
  dashboard_gupshup_account: "/dashboard/api/gupshup-account/",
  dashboard_new_mis_report: "/dashboard/api/new_mis_report/",
  dashboard_send_missing_retention_data_email: "/dashboard/api/send_missing_retention_data_email/",
  dashboard_languages: "/dashboard/api/languges/",
  dashboard_get_batchs_for_admin: "/dashboard/api/get_batchs_for_admin/",
  pre_litigation_send_consolidated_setl_request_email: "/pre_litigation/api/send_consolidated_setl_request_email/",
  pre_litigation_send_extraction_data_email_by_admin: "/pre_litigation/api/send_extraction_data_email_by_admin/",
  pre_litigation_rtp_extraction_data_email_by_admin: "/pre_litigation/api/rtp_extraction_data_email_by_admin/",
  pre_litigation_send_extraction_data_email_by_admin_new: "/pre_litigation/api/send_extraction_data_email_by_admin_new/",
  pre_litigation_new_borrower_page_admin_action: "/pre_litigation/api/new_borrower_page_admin_action/",
  pre_litigation_open_ai_analysis: "/pre_litigation/api/open_ai_analysis/",
  pre_litigation_notice_campaigning_report: "/pre_litigation/api/notice_campaigning_report/",
  pre_litigation_setl_analysis_report: "/pre_litigation/api/setl_analysis_report/",
  pre_litigation_setl_stage_analysis_report: "/pre_litigation/api/setl_stage_analysis_report/",
  pre_litigation_borrower_report: "/pre_litigation/api/borrower_report/",
  pre_litigation_get_conciliation_intention: "/pre_litigation/api/get_conciliation_intention/",
  pre_litigation_get_conciliation_intentionNew: "/pre_litigation/api/get_conciliation_intention_new/",
  pre_litigation_payment_review: "/pre_litigation/api/payment_review/",
  pre_litigation_all_notice_pdf: "/pre_litigation/api/all_notice_pdf/",
  pre_litigation_delivery_status: "/pre_litigation/api/delivery_status/",
  dashboard_call_ai_analysis_report: "/dashboard/api/call_ai_analysis_report/",
  dashboard_call_agent_analysis_report: "/dashboard/api/call_agent_analysis_report/",
  dashboard_wap_delivery_report: "/dashboard/api/wap_delivery_report/",
  dashboard_wap_reply_report: "/dashboard/api/wap_reply_report/",
  dashboard_email_delivery_report: "/dashboard/api/email_delivery_report/",
  dashboard_email_reply_report: "/dashboard/api/email_reply_report/",
  dashboard_sms_delivery_report: "/dashboard/api/sms_delivery_report/",
  dashboard_conciliation_meeting_report: "/dashboard/api/conciliation_meeting_report/",
  dashboard_conciliation_intention_report: "/dashboard/api/conciliation_intention_report/",
  dashboard_borrower_report: "/dashboard/api/borrower_report/",
  dashboard_new_retention_report: "/dashboard/api/new_retention_report/",
  dasboard_new_call_report: "/dashboard/api/new_call_report/",
  pre_litigation_letter_aging_summary: "/pre_litigation/api/letter_aging_summary/",
  pre_litigation_letter_overall_summary: "/pre_litigation/api/letter_overall_summary/",
  process_panel_helpline_report: "/process_panel/api/helpline_report/",
  dashboard_active_allocation_report: "/dashboard/api/active_allocation_report/",
  pre_litigation_all_notice_pdf_settlement_report: "/pre_litigation/api/all_notice_pdf_settlement_report/",
  fir_send_police_statement: "/fir/api/send_police_statement/",
  dashboard_allocation_analysis: "/dashboard/api/allocation_analysis/",
  client_panel_excluded_customer_data: "/client_panel/api/excluded_customer_data/",
  pre_litigation_generate_missing_notice_pdf: "/pre_litigation/api/generate_missing_notice_pdf/",
  fir_upload_fir_copies: "/fir/api/upload_fir_copies/",
  campaigns_exotel_campaign: "/campaigns/api/exotel_campaign/",
  dashboard_ivr_call_report: "/dashboard/api/ivr_call_report/",
  dashboard_conciliation_meeting_reminder_email: "/dashboard/api/conciliation_meeting_reminder_email/",
  dashboard_exophone_report: "/dashboard/api/exophone_report/",
  dashboard_account: "/dashboard/api/account/",
  dashboard_borrower_account_details: "/dashboard/api/borrower_account_details/",
  fir_working_state: "/fir/api/fir_working_state/",
  fir_working_stateNew: "/fir/api/fir_working_state_new/",
  batch_allocation_report: "/dashboard/api/batch_allocation_report/",
  download_not_working_batch_file: "/fir/api/download_not_working_batch_file",
  dashboard_todays_activity: "/dashboard/api/todays_activity/",
  dashboard_remove_data_from_consolidate_mail: "/dashboard/api/remove_data_from_consolidate_mail/",
  dashboard_update_batch_campaigning_status: "/dashboard/api/update_batch_campaigning_status/",
  dashboard_update_client_channels: "/dashboard/api/update_client_channels/",
  campaigns_urgent_daily_campaign: "/campaigns/api/urgent_daily_campaign/",
  campaigns_urgent_daily_campaign_delete: "/campaigns/api/urgent_daily_campaign_new/",
  pre_litigation_whatsapp_chats_data: "/pre_litigation/api/whatsapp_chats_data",
  pre_litigation_alt_whatsapp_chats_data: "/pre_litigation/api/alt_whatsapp_chats_data",
  pre_litigation_concilation_intention_history: "/pre_litigation/api/concilation_intention_history/",
  pre_litigation_download_concilation_hearing: "/pre_litigation/api/download_concilation_hearing",
  axis_communication_report: "/pre_litigation/api/axis_communication_report/",
  campaigns_call_record: "/campaigns/api/call_record/",
  send_waiver_request_email: "/dashboard/api/send_waiver_request_email",
  filter_admin_action_data: "/pre_litigation/api/get_admin_action_data",
  pre_litigation_update_payment_review: "/pre_litigation/api/update_payment_review/",
  campaigns_call_detail: "/call/api/get_call_quality_by_callsid",
  admin_agent_tags_api: "/pre_litigation/api/admin_agent_tags_api/",
  filter_payment_file: "/dashboard/api/filter_payment_file/",
  user_crud: "/account/api/user_crud/",
  crons: "/dashboard/api/cron_management/",
  settlement_report: "/dashboard/api/setl_action_taken_report/",
  chat_panel_report: "/dashboard/api/chat_panel_report/",
};

export { API_ENPOINTS };

export const WEB_SOCKET_URL = "wss://api.themedius.ai/ws/notifications/";
