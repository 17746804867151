import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { getSendPaymentFollowup } from "../../../API";
import { maskMobileNumber } from "../../../Functions/mask";
import { showToast } from "../../../utils/handleToast";
import useAuthStore from "../../../store/authStore";

function SendPaymentFollowup({ data }) {
    const userData = useAuthStore((state) => state.userData);

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const closeModal = () => {
        setShow(false);
        setLoading(false);
    };

    const send = async () => {
        if (!data?.data?.customer_id) {
            showToast({ variant: "warning", message: "Customer not found" });
            return;
        }

        setLoading(true);

        const res = await getSendPaymentFollowup({
            customer_id: data?.data?.customer_id,
        });

        if (res) {
            showToast({ variant: "success", message: res?.message });
            closeModal();
        } else {
            showToast({ variant: "danger", message: "Failed to send." });
        }

        setLoading(false);
    };

    return (
        <>
            <a className="dropdown-item" onClick={() => setShow(true)}>
                Send Payment Followup
            </a>

            <Modal size="sm" show={show}>
                <Modal.Header>
                    <Modal.Title>Send Payment Followup</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ minHeight: "10rem" }}>
                    <div className="py-5">
                        <p className="mb-1">Mobile Number</p>
                        <input
                            type="text"
                            value={!!userData?.remove_masking ? data?.data?.customer_mobile_number : maskMobileNumber(data?.data?.customer_mobile_number)}
                            readOnly
                            className="form-control"
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                    <Button disabled={loading} variant="primary" onClick={send}>
                        {loading ? "Sending..." : "Send"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default SendPaymentFollowup;
