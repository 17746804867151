import API from "./axios";

function handleApiError(error) {
  let data = error?.response?.data ?? { message: error?.message };
  return { success: false, data, code: error?.response?.status };
}

export const apiGET = async (endPoint, params, cancelToken) => {
  try {
    const res = !!cancelToken
      ? await API.get(endPoint, {
          params,
          cancelToken,
        })
      : await API.get(endPoint, { params });

    return { data: res.data, success: true, isCancel: false };
  } catch (error) {
    return {
      ...handleApiError(error),
      isCancel: error?.toString() === "Cancel",
    };
  }
};

export const apiPOST = async (endPoint, body, params, config = {}) => {
  try {
    const res = await API.post(endPoint, body, { params, ...config });

    return { data: res.data, success: true };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiPUT = async (endPoint, body, params) => {
  try {
    const res = await API.put(endPoint, body, { params });

    return { data: res.data, success: true };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiPATCH = async (endPoint, body, params) => {
  try {
    const res = await API.patch(endPoint, body, { params });

    return { data: res.data, success: true };
  } catch (error) {
    return handleApiError(error);
  }
};

export const apiDELETE = async (endPoint, body, params) => {
  try {
    const res = await API.delete(endPoint, {
      data: body,
      params,
    });

    return { data: res.data, success: true };
  } catch (error) {
    return handleApiError(error);
  }
};
