import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import mediusLogo from "../../Static/RawImages/user.png";
import Dropdown from "react-bootstrap/Dropdown";
import { FiChevronDown } from "react-icons/fi";
import useAuthStore from "../../store/authStore";
import useGeneralStore from "../../store/generalStore";
import { logout } from "../../API/commonApis";

function Header() {
    const removeUser = useAuthStore((state) => state.removeUser);
    const userData = useAuthStore((state) => state.userData);
    const totalResultFetched = useGeneralStore((state) => state.total_result_fetched);
    const isSidebarOpen = useGeneralStore((state) => state.is_sidebar_open);

    const [urlPointer, setUrlPointer] = useState("");
    const [showTotalResultCount, seShowTotalResultCount] = useState(false);

    const location = useLocation();

    function logoutUser() {
        logout();
        removeUser();
    }

    function getUrlPointer() {
        let showResultTemp = false;
        let urlPointerTemp = "Client Panel";

        let { pathname } = location;
        // Dashboard
        if (pathname === "/") {
            urlPointerTemp = "Dashboard";
        }
        // Collection
        else if (pathname === "/collection/batch") {
            showResultTemp = true;
            urlPointerTemp = "Batch";
        } else if (pathname?.toString()?.indexOf("/collection/accounts") > -1) {
            showResultTemp = true;
            urlPointerTemp = "Accounts";
        } else if (pathname === "/collection/dailyreport") {
            urlPointerTemp = "Daily Report";
        } else if (pathname === "/collection/settlement-tracker") {
            showResultTemp = true;
            urlPointerTemp = "Settlement Tracker";
        } else if (pathname === "/collection/daily-settlement-report") {
            urlPointerTemp = "Daily Settlement Report";
        }
        // Prelitigation - Legal Notices
        else if (pathname === "/prelitigation/legal/batch") {
            showResultTemp = true;
            urlPointerTemp = "Batch";
        } else if (pathname.includes("/prelitigation/legal/notices")) {
            showResultTemp = true;
            urlPointerTemp = "Legal Notices";
        }
        // Prelitigation - FIR
        else if (pathname === "/prelitigation/fir/batch") {
            showResultTemp = true;
            urlPointerTemp = "Batch";
        } else if (pathname.includes("/prelitigation/fir/complaints")) {
            showResultTemp = true;
            urlPointerTemp = "Complaints";
        }
        // Litigation
        else if (pathname === "/litigation/batch") {
            urlPointerTemp = "Batch";
        } else if (pathname === "/litigation/case") {
            urlPointerTemp = "Case";
        } else if (pathname === "/litigation/advocates") {
            urlPointerTemp = "Advocates";
        } else if (pathname === "/litigation/calendar") {
            urlPointerTemp = "Calendar";
        }
        // All Files
        else if (pathname === "/all-files/reports") {
            urlPointerTemp = "View Files";
        } else if (pathname === "/all-files/all-downloads") {
            urlPointerTemp = "Download Files";
        }

        // Default
        else {
            showResultTemp = false;
            urlPointerTemp = "Client Panel";
        }

        setUrlPointer(urlPointerTemp);
        seShowTotalResultCount(showResultTemp);
    }

    useEffect(() => {
        getUrlPointer();
    }, [location]);

    return (
        <>
            <div style={{ width: "100%", paddingTop: "72px", position: "relative", zIndex: "100" }}>
                <header
                    className="d-flex align-center header justify-between"
                    style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        paddingLeft: !!isSidebarOpen ? "178px" : "62px",
                        width: "100%",
                        transition: "all ease 0.5s",
                        zIndex: "99",
                    }}
                >
                    <div className="d-flex align-center header-left mb-lg-0 mb-2">
                        {urlPointer && (
                            <>
                                <h1> {urlPointer} </h1>
                                {showTotalResultCount && (
                                    <>
                                        {totalResultFetched > 1 ? (
                                            <small>Total Results : {totalResultFetched}</small>
                                        ) : totalResultFetched === 1 ? (
                                            <small>Total Result : {totalResultFetched}</small>
                                        ) : (
                                            <small>Total Result : 0</small>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    <div className="d-flex align-center header-right">
                        <Dropdown>
                            <Dropdown.Toggle>
                                <div className="d-flex align-items-center justify-content-center gap-1 text-dark">
                                    <img
                                        src={!!userData?.logo_url ? userData?.logo_url : mediusLogo}
                                        style={{ width: "40px", height: "40px", objectFit: "cover", borderRadius: "50%" }}
                                        alt="User"
                                    />
                                    <p className="m-0" style={{ fontWeight: "normal" }}>
                                        {userData?.full_name || ""}
                                    </p>
                                    <FiChevronDown style={{ fontSize: "18px" }} />
                                </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={logoutUser}>Logout </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </header>
            </div>
        </>
    );
}

export default Header;
