import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import axios from "axios";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
//bootstrap

import "bootstrap/dist/js/bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

//styles
import "./Styles/SCSS/Dashboard.css";
import "./Styles/SCSS/AccountExtra.css";
import "./Styles/SCSS/CollectionAccounts.css";
import "./Styles/SCSS/CollectionBatch.css";
import "./Styles/SCSS/Header.css";
import "./Styles/SCSS/MainHeader.css";
import "./Styles/SCSS/Sidebar.css";
import "./Styles/SCSS/Agent.css";
import "./Styles/SCSS/Admin.css";
import "./Styles/SCSS/AdminCampaignmanage.css";
import "./Styles/SCSS/App.css";
import "./Styles/SCSS/Custom.css";
import "./Styles/SCSS/TriggerEvents.css";
import "./Styles/SCSS/AllLeads.css";
import "./Styles/SCSS/complaint-screens.css";
import "./Styles/global.css";
import "./Styles/SCSS/AgentReport.css";
import "./Styles/SCSS/spacing.css";
import "./Styles/SCSS/Variable.css";
import "./Styles/SCSS/AgentProfileView.css";
import "./Styles/SCSS/AgentPanel.css";

axios.defaults.headers.post["Content-Type"] = "multipart/form-data";

const customHistory = createBrowserHistory();

ReactDOM.render(
    <React.StrictMode>
        <HashRouter history={customHistory}>
            <App />
        </HashRouter>
    </React.StrictMode>,
    document.getElementById("root")
);

reportWebVitals();
