import React from "react";
import classes from "./style.module.css";

export default function VideoAttachment({ url = "" }) {
    return (
        <video className={classes.video_msg} src={url} controls>
            Your browser does not support the video tag.
        </video>
    );
}
