import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { isEmpty } from "../../Functions/isEmpty";
import ContentLoader from "../ContentLoader";
import { fetchSmsData } from "../../API";
import { PiScalesLight } from "react-icons/pi";
import { Offcanvas } from "react-bootstrap";
import { IoCloseCircle } from "react-icons/io5";

function AccountSms({ mobile }) {
  const [data, setData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchSmsData(mobile);
      setData(res?.data || []);
    };
    if (mobile && isOpen) fetchData();
  }, [mobile, isOpen]);

  return (
    <>
      <div className="d-flex align-items-center  justify-content-center" role="button" onClick={toggle}>
        <svg style={{ fill: "#0A7AFF" }} width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.52 0A11.52 11.52 0 0 0 1.44 17.074L0 23.04l5.822-1.507A11.52 11.52 0 1 0 11.52 0zm0 22.08a10.56 10.56 0 0 1-5.38-1.474l-.174-.105-4.608 1.18 1.1-4.742-.101-.173a10.56 10.56 0 1 1 9.163 5.314z"
            fillRule="nonzero"
          />
        </svg>
      </div>
      SMS
      <Offcanvas style={{ width: "28rem", paddingTop: "0.75rem" }} show={isOpen} onHide={toggle} backdropClassName="opacity-0" placement="end">
        <Offcanvas.Header>
          <Offcanvas.Title className="d-flex justify-content-between align-items-center w-100 px-2">
            <div className="fw-600 fs-4">SMS Record {data && data.length ? `(${data.length})` : ""} </div>{" "}
            <button onClick={toggle} type="button" className="bg-white border-0">
              <IoCloseCircle fill="#999999" size={30} />
            </button>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {!data ? (
            <div className="position-relative top-50">
              <ContentLoader />
            </div>
          ) : data.length === 0 ? (
            <p className="text-muted m-0 p-5 text-center">No Data Found</p>
          ) : (
            <div className="account_extra_sms px-4">
              {data.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="account_extra_date">
                      <p className="title-hr mt-2 mb-4">
                        <span>{!isEmpty(item?.created_date) ? format(new Date(item?.created_date), "do MMM yyyy") : ""}</span>
                      </p>
                    </div>
                    <div className="sms-data">{item.message_body}</div>
                  </React.Fragment>
                );
              })}
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default AccountSms;
