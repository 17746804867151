import React from "react";
import useGeneralStore from "../../store/generalStore";

export default function FixedHeader({ children }) {
    const isSidebarOpen = useGeneralStore((state) => state.is_sidebar_open);

    return (
        <>
            <div style={{ height: "4.5rem", position: "relative", width: "100%" }} />
            <div
                style={{
                    position: "fixed",
                    top: "72px",
                    left: "0",
                    paddingLeft: !!isSidebarOpen ? "178px" : "62px",
                    width: "100%",
                    transition: "all ease 0.5s",
                    zIndex: "99",
                    height: "4.5rem",
                    borderBottom: "1px solid #e5e5e5",
                    backgroundColor: "#fff",
                }}
            >
                <div className="d-flex align-items-center justify-content-between gap-4 px-4 h-100">{children}</div>
            </div>
        </>
    );
}
