import React from "react";

export default function FilterButton({ onClick, disabled = false, active = false }) {
    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={active ? "font-weight-bold btn btn-secondary me-3 ms-0 flex-shrink-0" : "btn btn-secondary me-3 ms-0 flex-shrink-0"}
            style={{ position: "relative" }}
        >
            <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                <g fill="#000" fillRule="nonzero">
                    <path d="M19.2 2.4a2.4 2.4 0 0 0-2-2.364V0h-.8v.036a2.4 2.4 0 0 0 0 4.728V19.2h.8V4.764a2.4 2.4 0 0 0 2-2.364zM16.8 4a1.6 1.6 0 1 1 0-3.2 1.6 1.6 0 0 1 0 3.2zM10 10.036V0h-.8v10.036a2.4 2.4 0 0 0 0 4.728V19.2h.8v-4.436a2.4 2.4 0 0 0 0-4.728zM9.6 14a1.6 1.6 0 1 1 0-3.2 1.6 1.6 0 0 1 0 3.2zM2.8.036V0H2v.036a2.4 2.4 0 0 0 0 4.728V19.2h.8V4.764a2.4 2.4 0 0 0 0-4.728zM2.4 4a1.6 1.6 0 1 1 0-3.2 1.6 1.6 0 0 1 0 3.2z" />
                </g>
            </svg>
            Filter
        </button>
    );
}
