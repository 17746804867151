import { forwardRef, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import { IoCloseCircle } from "react-icons/io5";

const StateBreakup = forwardRef(function (props, ref) {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);

  const toggle = () => setIsOpen((state) => !state);

  useImperativeHandle(
    ref,
    () => {
      return {
        showModal(data) {
          setData(data);
          toggle();
        },
      };
    },
    []
  );

  return (
    <Modal size="lg" show={isOpen} onHide={toggle} clo>
      <Modal.Header className="h5 fw-bold d-flex justify-content-between align-content-center">
        <span>State Breakup Data</span>
        <button onClick={toggle} className="border-0">
          <IoCloseCircle />
        </button>
      </Modal.Header>
      <Modal.Body className="py-2">
        <table className="table table-bordered table-striped w-100">
          <thead>
            <tr className="border">
              <th className="align-middle text-center py-2 px-3 fw-semibold">State</th>
              <th className="align-middle text-center py-2 px-3 fw-semibold">Total Complaints</th>
              <th className="align-middle text-center py-2 px-3 fw-semibold">FIR Field Count</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, i) => (
              <tr key={i}>
                <td className="align-middle text-center py-2 px-3">{item?.state}</td>
                <td className="align-middle text-center py-2 px-3">{item?.total_complaints}</td>
                <td className="align-middle text-center py-2 px-3">{item?.fir_filed_count}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
});

export default StateBreakup;
