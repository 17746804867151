import React, { useState } from "react";
import { IoMdAdd } from "react-icons/io";
import SendDocuments from "./SendDocuments";
import classes from "./style.module.css";

// export const whatsappActions = Object.freeze({
//     // DOCUMENT: "DOCUMENT",
// });

export default function Actions({ loading, onSendMessage }) {
    const [show, setShow] = useState(false);

    const toggleAction = () => setShow((prev) => !prev);

    const onSend = async ({ msg, file }) => {
        await onSendMessage({ msg, file });
        setShow(false);
    };

    return (
        <>
            <button onClick={toggleAction} className={`${classes.action_btn} ${show && classes.active}`}>
                <IoMdAdd />
            </button>

            {show && <div onClick={toggleAction} style={{ position: "fixed", inset: "0", background: "transparent", zIndex: "1" }} />}

            <div className={`${classes.action_list} ${show && classes.active}`}>
                <SendDocuments loading={loading} sendMessage={onSend} />
            </div>
        </>
    );
}
