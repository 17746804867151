// ===============================================
// PANEL LANDING PAGES and Other Controlled Pages
// ===============================================

export const DEFAULT_PAGE = "/";

export const panelPages = [
    { name: "Notice", key: "Notice", route: "/prelitigation/legal/batch" },
    { name: "Collection", key: "Collection", route: "/collection/batch" },
    { name: "Fir", key: "Fir", route: "/prelitigation/fir/batch" },
];

export const panelPagesOptions = panelPages?.map(({ name, key }) => ({ label: name, value: key }));

// ====================================
// PANEL SETTINGS
// ====================================

export const panelSettingConstants = {
    HIDE_DASHBOARD: "hide_dashboard",
    HIDE_COLLECTION: "hide_collection",
    HIDE_PRELITIGATION: "hide_prelitigation",
    HIDE_FIR: "hide_fir",
    HIDE_LEGAL_NOTICE_BATCH_UPLOAD: "hide_legal_notice_batch_upload",
    SHOW_LEGAL_MOTICE_BATCH_UPLOAD_ONLY: "show_legal_notice_batch_upload_only",
    HIDE_ALL_FILE_FILTERS: "hide_all_file_filters",
    HIDE_NOTICE_BULK_ACTION: "hide_notice_bulk_action",
    ALLOW_LEADS: "allow_leads",

    // rare case keys, will manage from db
    PAYMENT_SCRAPPING: "payment_scrapping",
};

export const panelSettingsOptions = [
    { label: "Hide Dashboard", value: panelSettingConstants?.HIDE_DASHBOARD, defaultStatus: false },
    { label: "Hide Collection", value: panelSettingConstants?.HIDE_COLLECTION, defaultStatus: false },
    { label: "Hide Prelitigation", value: panelSettingConstants?.HIDE_PRELITIGATION, defaultStatus: false },
    { label: "Hide Prelitigation - FIR", value: panelSettingConstants?.HIDE_FIR, defaultStatus: false },
    {
        label: "Hide Prelitigation - Legal Notice - Batch Upload (with mapping)",
        value: panelSettingConstants?.HIDE_LEGAL_NOTICE_BATCH_UPLOAD,
        defaultStatus: false,
    },
    {
        label: "Show Prelitigation - Legal Notice - Batch Upload (without mapping)",
        value: panelSettingConstants?.SHOW_LEGAL_MOTICE_BATCH_UPLOAD_ONLY,
        defaultStatus: false,
    },
    { label: "Hide all file filters", value: panelSettingConstants?.HIDE_ALL_FILE_FILTERS, defaultStatus: false },
    { label: "Hide Notice Bulk Action", value: panelSettingConstants?.HIDE_NOTICE_BULK_ACTION, defaultStatus: false },
    { label: "Allow Leads", value: panelSettingConstants?.ALLOW_LEADS, defaultStatus: true },
];

export const panelSettingsDefaultObj = panelSettingsOptions?.reduce((obj, item) => Object.assign(obj, { [item.value]: item.defaultStatus }), {});
