import React, { useState, useEffect } from "react";
import axios from "axios";

import { Switch, Route, useHistory } from "react-router-dom";
import NotFound from "./Pages/NotFound";

import { ToastContainer } from "react-toastify";

import PanelRoutes from "./Panel/Routes";
import AgentRoutes from "./Agent/Routes";
import useAuthStore from "./store/authStore";
import AlertMsg from "./ui/AlertMsg";
import ToastWrapper from "./Components/ToastWrapper";
import AdminApp from "./Admin/AdminApp";
import getBaseUrl from "./apis/getBaseUrl";
import Unavailable from "./Agent/Unavailable";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const [subDomain, setSubDomain] = useState("");

  const [width, setWidth] = useState(window.innerWidth);

  const userToken = useAuthStore((state) => state.token);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const userRole = useAuthStore((state) => state.role);
  const userData = useAuthStore((state) => state.userData);
  const removeUser = useAuthStore((state) => state.removeUser);

  const history = useHistory();

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    const hostDetails = getBaseUrl();
    axios.defaults.baseURL = hostDetails.baseUrl;
    const subDomainName = hostDetails.subDomain;

    setSubDomain(subDomainName);

    if (subDomainName === "agent") {
      window.addEventListener("resize", handleWindowSizeChange);
    }

    return () => {
      subDomainName === "agent" && window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    document.title = "Medius AI";

    axios.defaults.params = {};

    if (isAuthenticated) {
      axios.defaults.headers.common["Authorization"] = `Token ${userToken}`;

      if (userRole === "agent" || userRole === "client") {
        axios.defaults.params["client_id"] = userData?.client_id;
      }
    }

    if (isAuthenticated && userRole === "admin") document.title = "Medius - Admin Panel";
    if (isAuthenticated && userRole === "agent") document.title = "Medius - Agent Panel";
    if (isAuthenticated && userRole === "client") document.title = "Medius - Client Panel";

    if (!isAuthenticated) {
      removeUser();
      history.push("/login");
    }
  }, [userRole, userToken, isAuthenticated]);

  return (
    <div className="App">
      <AlertMsg />
      <ToastWrapper />
      <ToastContainer />
      <QueryClientProvider client={queryClient}>
        <Switch>
          <Route exact path="/404" component={NotFound} />

          {subDomain === "agent" && <AgentRoutes />}

          {subDomain === "admin" && <AdminApp />}

          {subDomain === "panel" && <PanelRoutes />}
        </Switch>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </div>
  );
}

export default App;
