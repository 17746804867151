import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import DatePicker from "../../../../ui/forms/DatePicker";

const batchStatuses = { ALL_BATCHES: "all_batches", ACTIVE_BATCHES: "active_batches", COMPLETED_BATCHES: "completed" };
const searchByModes = { DATE_BETWEEN: "date_between", SPECIFIC_DATE: "specific" };

const filterDefaults = {
    search: "",
    batch_no: "",
    date_from: null,
    date_to: null,
    specific_date: null,
    batch_status: "",
};

function CollectionBatchFilter({ show, close, prevFilter, setPrevFilter, searchByPrev, setSearchByPrev }) {
    const [filter, setFilter] = useState({ ...filterDefaults });
    const [searchBy, setSearchBy] = useState("");

    // handler
    const handleFilter = (name, val) => setFilter((prev) => ({ ...prev, [name]: val }));
    const updateSearchBy = (type) => {
        setSearchBy(type);
        setFilter((prev) => ({ ...prev, date_from: null, date_to: null, specific_date: null }));
    };

    const clearFilter = () => {
        setFilter({ ...filterDefaults });
        setPrevFilter({ ...filterDefaults });
        setSearchBy("");
    };

    const closeFilter = () => {
        close();
    };

    const applyFilter = () => {
        setPrevFilter({ ...filter });
        setSearchByPrev(searchBy);
        closeFilter();
    };

    useEffect(() => {
        if (!!show) {
            setFilter({ ...prevFilter });
            setSearchBy(searchByPrev);
        }
    }, [show]);

    return (
        <Modal size="sm" show={show} onHide={close} backdrop="static">
            <Modal.Header>
                <Modal.Title>Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body onKeyDown={(e) => e.key === "Enter" && applyFilter()}>
                {/* Search Filter */}
                <div className="mb-2 row modal-search">
                    <div className="col-md-12 pt-3 pb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search by file name"
                            value={filter?.search}
                            onChange={(e) => handleFilter("search", e.target.value)}
                        />
                    </div>
                </div>

                {/* Search by Batch Number */}
                <div className="grey-bg mb-2">
                    <div className="mb-2 row">
                        <label htmlFor="search_by_batch_number" className="col-md-8 col-form-label pt-2 mt-1">
                            Search by Batch Number
                        </label>
                        <div className="col-md-4">
                            <input
                                id="search_by_batch_number"
                                type="text"
                                className="form-control"
                                placeholder="Batch No."
                                value={filter?.batch_no}
                                onChange={(e) => handleFilter("batch_no", e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                {/* Batch statuses */}
                <div className="mb-2 row">
                    <div className="col-md-3">
                        <label htmlFor="all_batches" className="col-form-label">
                            <input
                                className="form-check-input ml-0"
                                type="radio"
                                name="batch_statuses"
                                id="all_batches"
                                // value="All_Batches"
                                checked={filter?.batch_status === batchStatuses.ALL_BATCHES}
                                onChange={() => handleFilter("batch_status", batchStatuses.ALL_BATCHES)}
                            />{" "}
                            All Batches
                        </label>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="active_batches" className="col-form-label">
                            <input
                                className="form-check-input ml-0"
                                type="radio"
                                name="batch_statuses"
                                id="active_batches"
                                // value="Active_Batches"
                                checked={filter?.batch_status === batchStatuses.ACTIVE_BATCHES}
                                onChange={() => handleFilter("batch_status", batchStatuses.ACTIVE_BATCHES)}
                            />{" "}
                            Active Batches
                        </label>
                    </div>
                    <div className="col-md-5">
                        <label htmlFor="completed_batches" className="col-form-label">
                            <input
                                className="form-check-input ml-0"
                                type="radio"
                                name="batch_statuses"
                                id="completed_batches"
                                // value="completed"
                                checked={filter?.batch_status === batchStatuses.COMPLETED_BATCHES}
                                onChange={() => handleFilter("batch_status", batchStatuses.COMPLETED_BATCHES)}
                            />{" "}
                            Completed Batches
                        </label>
                    </div>
                </div>

                {/* Search By Date */}
                <div className="grey-bg">
                    <div className="mb-2 row">
                        <label className="col-sm-5 col-form-label">Search by Date</label>
                    </div>
                    <div className="mb-2 row">
                        <label htmlFor="date_between" className="col-md-4 col-form-label">
                            <input
                                className="form-check-input ml-0"
                                type="radio"
                                name="search_by_date_type"
                                id="date_between"
                                checked={searchBy === searchByModes?.DATE_BETWEEN}
                                onChange={() => updateSearchBy(searchByModes?.DATE_BETWEEN)}
                            />{" "}
                            Date Between
                        </label>
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-6 ps-0 datepicker datepicker-small datepicker-small-white">
                                    <DatePicker
                                        disabled={searchBy !== searchByModes?.DATE_BETWEEN}
                                        value={filter?.date_from}
                                        maxDate={filter?.date_to}
                                        onChange={(val) => handleFilter("date_from", val)}
                                        label="From"
                                    />
                                </div>
                                <div className="col-md-6 ps-0 datepicker datepicker-small datepicker-small-white">
                                    <DatePicker
                                        disabled={searchBy !== searchByModes?.DATE_BETWEEN}
                                        value={filter?.date_to}
                                        minDate={filter?.date_from}
                                        onChange={(val) => handleFilter("date_to", val)}
                                        label="To"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <label htmlFor="specific_date" className="col-md-6 col-form-label">
                            <input
                                className="form-check-input ml-0"
                                type="radio"
                                name="search_by_date_type"
                                id="specific_date"
                                checked={searchBy === searchByModes?.SPECIFIC_DATE}
                                onChange={() => updateSearchBy(searchByModes?.SPECIFIC_DATE)}
                            />{" "}
                            Specific Date
                        </label>
                        <div className="col-md-6 datepicker">
                            <div className="col-md-12 datepicker datepicker-small datepicker-small-white w-100">
                                <DatePicker
                                    disabled={searchBy !== searchByModes?.SPECIFIC_DATE}
                                    value={filter?.specific_date}
                                    onChange={(val) => handleFilter("specific_date", val)}
                                    label="Specific date"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={clearFilter}>
                    Clear Filter
                </Button>
                <Button variant="secondary" onClick={closeFilter}>
                    Close
                </Button>
                <Button variant="primary" onClick={applyFilter}>
                    Apply
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default CollectionBatchFilter;
