import Select, { components } from "react-select";
import DatePicker from "../../../../ui/forms/DatePicker";
import { dispositionOptions, flattenedDispositionOptions, settlementProcess, vehicleDisp } from "../data";
import { useEffect, useMemo, useState } from "react";
import { createDispOptions } from "../helpers";
import useBorrowerInfo from "../store";
import useCallCustomer from "../../../../hooks/useCallCustomer";
import useForm from "../../../../hooks/useForm";
import { postDispositionData } from "../../../../API/agentApis";
import { endOfMonth, format, startOfMonth } from "date-fns";
import { handleNotifications } from "../../../../utils/handleNotification";
import { showToast } from "../../../../utils/handleToast";
import WantSettlement from "./want-settlement";
import PtpConfirm from "./ptp-confirm";

const findPtpMaxDate = () => {
  const today = new Date();
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const nexSeventhDay = new Date(new Date().setDate(today.getDate() + 7));

  return nexSeventhDay < lastDayOfMonth ? nexSeventhDay : lastDayOfMonth;
};

const ptpMaxDate = findPtpMaxDate();

export default function AddDisposition() {
  const data = useBorrowerInfo((state) => state.data);
  const closeBorrowerInfo = useBorrowerInfo((state) => state.closeBorrowerInfo);

  const [showWantSettlement, setShowWantSettlement] = useState(false);
  const [showPtpConfirmation, setShowPtpConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);

  const { callCustomer, callTypes } = useCallCustomer();

  const [dispForm, handleDispForm, resetDispForm] = useForm({
    disposition: "",
    ptp_amount: "",
    paid_amount: "",
    ptp_date: null,
    remark: "",
    alt_contact_number: "",
    paid_date: "",
  });

  const resetFields = () => {
    setShowPtpConfirmation(false);
    setShowWantSettlement(false);
    setLoading(false);
    resetDispForm();
  };

  const addDisposition = async () => {
    if (dispForm?.disposition === "PTP" && dispForm?.ptp_date > ptpMaxDate) {
      showToast({ message: `Grace period cannot be given beyond ${format(new Date(ptpMaxDate), "dd-MM-yyyy")}`, variant: "warning" });
      return;
    }
    setLoading(true);

    const { data: customerData } = data;
    const res = await postDispositionData({
      customer: customerData?.customer_id,
      loan_account_no: !!customerData?.loan_account_no ? customerData?.loan_account_no : null,
      batch_id: customerData?.batch ? customerData?.batch : null,
      product_name: customerData?.model ? customerData?.model : null,
      due_agmt_no: customerData?.due_agmt_no ? customerData?.due_agmt_no : null,
      customer_name: customerData?.customer_name ? customerData?.customer_name : null,
      mobile_number: customerData?.customer_mobile_number ? customerData?.customer_mobile_number : null,
      due_amount: customerData?.total_due ? customerData?.total_due : 0,
      payment_mode: customerData?.payment_type ? customerData?.payment_type : null,

      disposition: dispForm?.disposition,
      alt_contact_number: dispForm.alt_contact_number ? dispForm.alt_contact_number : null,
      remark: dispForm.remark ? dispForm.remark : null,
      ptp_amount: dispForm.ptp_amount ? Number(dispForm.ptp_amount) : 0,
      ptp_date: dispForm.ptp_date ? format(new Date(dispForm.ptp_date), "yyyy-MM-dd") : null,
      paid_amount: dispForm.paid_amount ? Number(dispForm.paid_amount) : 0,

      paid_date: dispForm.paid_date ? format(new Date(dispForm.paid_date), "yyyy-MM-dd") : null,

      settlement_amount: null,
      number_of_emi: 0,
      settlement_emi_data: null,

      contact_date: format(new Date(), "yyyy-MM-dd"),
      agency_name: "The Medius",
      channel: "HUMAN_CALL",
      idle_time: 0,
      chance_of_payment: 0,
    });

    if (res) {
      
      let message = handleNotifications({
        customer_mobile_number: data?.data?.customer_mobile_number,
        message: res?.success_message,
        notification_type: res?.notification_type,
        callCustomer: () => callCustomer({ call_type: callTypes?.AGENT, customer_mobile_number: data?.data?.customer_mobile_number }),
      });

      showToast({
        variant: res?.variant === "yellow" ? "warning" : res?.variant === "red" ? "danger" : res?.variant === "green" ? "success" : "info",
        message: res.success_message,
      });

      resetDispForm();
      closeBorrowerInfo();
    } else showToast({ variant: "danger", message: "Failed to Add Disposition" });

    setLoading(false);
  };

  useEffect(() => {
    resetFields();
  }, []);

  const [objective, dispOptions] = useMemo(() => {
    switch (data?.settlement_intention) {
      case settlementProcess.LETTER_RECEIVED:
        return ["Collect payment", createDispOptions(["PTP", "RTP", "PAID", "P-PAID"])];

      default:
        const isTwoWheeler = data?.data?.product_name === "TW" || data?.data?.product_name === "Two Wheeler";
        return ["", isTwoWheeler ? [...dispositionOptions, ...vehicleDisp] : dispositionOptions];
    }
  }, [data?.settlement_intention, data?.data?.product_name]);

  return (
    <div className="flex-grow-1">
      <p className="mb-3 fw-bold tx-16 text-black">Add Disposition</p>

      {!!objective && <p className="alert alert-info text-primary px-2 py-1">{objective}</p>}

      <div className="mb-3">
        <label className="form-label mb-1">Disposition</label>
        <Select
          placeholder="Select"
          isClearable={true}
          styles={{
            control: (provided, state) => ({
              ...provided,
              padding: "0.2rem 0",
              paddingLeft: "0.4rem",
              border: "1px solid #dddddd !important",
              borderRadius: "6px",
              backgroundColor: state.isDisabled ? "#f1f1f1" : "#fff",
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isSelected ? "#E0E7E9" : state.isFocused ? "#f4f6f8" : "white",
              color: !state.isSelected && state.isDisabled ? "#aaa" : "#555",
              zIndex: "999",
            }),
          }}
          options={dispOptions}
          value={flattenedDispositionOptions?.find((el) => el?.value === dispForm?.disposition) ?? ""}
          onChange={(val) => handleDispForm("disposition", val?.value ?? "")}
          components={{
            Group: (props) => (
              <div style={{ borderBottom: `1px solid #e0e0e0` }}>
                <components.Group {...props} />
              </div>
            ),
          }}
        />
      </div>

      {["PTP"].includes(dispForm?.disposition) && (
        <div className="mb-3">
          <label className="form-label mb-1">Amount</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Amount"
            value={dispForm?.ptp_amount}
            onChange={(e) => (!isNaN(e.target.value) ? handleDispForm("ptp_amount", e.target.value) : {})}
          />
        </div>
      )}

      {["PAID", "P-PAID"].includes(dispForm?.disposition) && (
        <>
          <div className="mb-3">
            <label className="form-label mb-1">Amount</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Amount"
              value={dispForm?.paid_amount}
              onChange={(e) => (!isNaN(e.target.value) ? handleDispForm("paid_amount", e.target.value) : {})}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="paidDate" className="form-label mb-1">
              Payment Date
            </label>
            <input
              type="date"
              className="form-control"
              placeholder="Enter Payment Date"
              // Only set date limits for "P-PAID", not "PAID"
              min={dispForm?.disposition === "P-PAID" ? format(startOfMonth(new Date()), "yyyy-MM-dd") : undefined}
              max={dispForm?.disposition === "P-PAID" ? format(endOfMonth(new Date()), "yyyy-MM-dd") : undefined}
              value={dispForm?.paid_date}
              onChange={(e) => handleDispForm("paid_date", e.target.value)}
            />
          </div>
        </>
      )}

      {["WN"].includes(dispForm?.disposition) && (
        <div className="mb-3">
          <label className="form-label mb-1">Alternate Number</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Phone Number"
            value={dispForm?.alt_contact_number}
            minLength={10}
            maxLength={10}
            onChange={(e) => (!isNaN(e.target.value) ? handleDispForm("alt_contact_number", e.target.value) : {})}
          />
        </div>
      )}

      {["RTP"].includes(dispForm?.disposition) && (
        <div className="mb-3">
          <label className="form-label mb-1">Reason</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Reason"
            value={dispForm?.remark}
            onChange={(e) => handleDispForm("remark", e.target.value)}
          />
        </div>
      )}

      {["PTP"].includes(dispForm?.disposition) && (
        <div className="mb-3">
          <label className="form-label mb-1">Date</label>
          <DatePicker
            label="Select Date"
            value={dispForm?.ptp_date}
            onChange={(val) => handleDispForm("ptp_date", val)}
            // only for ptp data
            maxDate={ptpMaxDate}
          />
        </div>
      )}

      {!["SETL", "PTP"].includes(dispForm?.disposition) && (
        <button disabled={!dispForm?.disposition || loading} onClick={() => addDisposition()} className="mt-2 btn btn-primary btn-lg w-100 rounded">
          {loading ? "Saving..." : "Save Disposition"}
        </button>
      )}

      {["SETL"].includes(dispForm?.disposition) && (
        <button onClick={() => setShowWantSettlement(true)} className="mt-2 btn btn-primary btn-lg w-100 rounded">
          Add Disposition
        </button>
      )}

      {["PTP"].includes(dispForm?.disposition) && (
        <button
          disabled={!dispForm?.ptp_amount || !dispForm?.ptp_date}
          onClick={() => setShowPtpConfirmation(true)}
          className="mt-2 btn btn-primary btn-lg w-100 rounded"
        >
          Add Disposition
        </button>
      )}

      <WantSettlement show={showWantSettlement} setShow={setShowWantSettlement} disposition={dispForm?.disposition} reset={resetDispForm} />

      <PtpConfirm
        show={showPtpConfirmation}
        setShow={setShowPtpConfirmation}
        onConfirm={() => addDisposition()}
        amount={dispForm?.ptp_amount}
        date={dispForm?.ptp_date}
      />
    </div>
  );
}
