import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { IoClose, IoCheckmarkSharp } from "react-icons/io5";
import { generateLegalNotice, getGeneratedLegalNoticeTemplates } from "../../../../API/panelApis";
import useGeneralStore from "../../../../store/generalStore";
import ContentLoader from "../../../../Components/ContentLoader";

export default function GenerateNoticeModal({ show, setShow, item, setItem }) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const closeModal = () => {
        setShow(false);
        setItem(null);
        setData([]);
    };

    const getData = async () => {
        if (!item?.batch_id || !item?.client?.client_id) {
            closeModal();
            return;
        }

        setLoading(true);

        try {
            const res = await getGeneratedLegalNoticeTemplates({ client_id: item?.client?.client_id, batch_id: item?.batch_id });
            if (res) {
                let allTemplates = res?.data?.all_templates?.map(({ name, type }) => ({
                    label: name,
                    type: type,
                    generated: res?.data?.generated_templates?.includes(type),
                }));

                let generatedTemplates = [];
                for (let i = 0; i < res?.data?.generated_templates?.length; i++) {
                    const element = res?.data?.generated_templates[i];
                    if (!allTemplates?.find((el) => el?.type === element)) generatedTemplates.push({ label: element, type: element, generated: true });
                }

                setData([...allTemplates, ...generatedTemplates]);
            }
        } catch (error) {
            console.log(error);
        }

        setLoading(false);
    };

    useEffect(() => {
        !!show && !!item && getData();
    }, [show, item]);

    return (
        <Modal size="xs" show={show} backdrop="static">
            <Modal.Header>
                <Modal.Title>Generate Legal Notices</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{ height: "400px", overflow: "scroll" }}>
                    {!!data?.length && !loading && (
                        <table className="w-100 my-3">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Notice Type</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((el, index) => (
                                    <tr key={index}>
                                        <td style={{ borderBottom: "1px solid #f5f5f5" }} className="py-2 px-1">
                                            {index + 1}
                                        </td>
                                        <td style={{ borderBottom: "1px solid #f5f5f5", width: "185px" }} className="py-2">
                                            {el?.label}
                                        </td>
                                        <td style={{ borderBottom: "1px solid #f5f5f5" }} className="py-2">
                                            {el?.generated && (
                                                <span className="badge bg-success tx-12 py-1">
                                                    <IoCheckmarkSharp style={{ fontSize: "11px", transform: "scale(1.5)", marginRight: "3px" }} /> Generated
                                                </span>
                                            )}

                                            {!el?.generated && (
                                                <>
                                                    <span className="badge bg-danger tx-12 py-1">
                                                        <IoClose style={{ fontSize: "11px", transform: "scale(1.5)", marginRight: "5px" }} />
                                                        Not Generated
                                                    </span>
                                                    <GenerateButton batch_id={item?.batch_id} client_id={item?.client?.client_id} type={el?.type} />
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                    {!data?.length && !loading && <div className="d-flex align-items-center justify-content-center">No Data Found</div>}
                    {loading && <ContentLoader />}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => closeModal()} variant="secondary">
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function GenerateButton({ batch_id, client_id, type }) {
    const showAlert = useGeneralStore((state) => state.open_alert);
    const [loading, setLoading] = useState(false);

    const generateNotice = async () => {
        if (!batch_id || !client_id || !type) {
            showAlert({ variant: "danger", msg: "Unable to generate" });
            return;
        }

        setLoading(true);

        try {
            const res = await generateLegalNotice({ batch_id, client_id, notice_type: type });

            if (res) showAlert({ variant: "success", msg: res?.message });
        } catch (error) {
            console.log(error);
            showAlert({ variant: "danger", msg: "Unable to generate" });
        }
        setLoading(false);
    };

    return (
        <button disabled={loading} onClick={() => generateNotice()} className="btn btn-sm btn-primary ms-2">
            Generate
        </button>
    );
}
