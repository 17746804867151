import axios from "axios";
import useAuthStore from "../store/authStore";
import getBaseUrl from "./getBaseUrl";

const API = axios.create({ baseURL: getBaseUrl().baseUrl });

API.interceptors.request.use((config) => {
  const { token, role, userData } = useAuthStore.getState();

  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }
  if ((role === "agent" || role === "client") && !!userData?.client_id) {
    config.params["client_id"] = userData?.client_id;
  }

  return config;
});

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      window.location.reload(false);
    }
    return Promise.reject(error);
  }
);

export default API;
