import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { uploadPaymentList } from "../../../API/panelApis";
import useCheckFileFormat, { acceptFileFormats, fileFormats } from "../../../hooks/checkFileFormat";
import useAuthStore from "../../../store/authStore";
import useGeneralStore from "../../../store/generalStore";

export default function UploadPaymentList({ show, setShow = () => {} }) {
    const showAlert = useGeneralStore((state) => state.open_alert);
    const userData = useAuthStore((state) => state.userData);

    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);

    const { checkFileFormat } = useCheckFileFormat();

    const closeModal = () => {
        setShow(false);
        setFile(null);
        setLoading(false);
    };

    function handleFileValidation(e) {
        if (!checkFileFormat(e, fileFormats.EXCEL)) return;
        setFile(e.target.files[0]);
    }

    const uploadFile = async () => {
        setLoading(true);

        let formData = new FormData();
        formData.append("file", file);
        formData.append("client_id", userData?.client_id);

        const res = await uploadPaymentList(formData);

        if (res) {
            showAlert({ variant: "success", msg: res?.message });
            closeModal();
        } else {
            showAlert({ variant: "danger", msg: "Action Failed" });
        }

        setLoading(false);
    };

    return (
        <Modal size="sm" show={show} backdrop="static">
            <Modal.Header>
                <Modal.Title>Upload Payment List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="py-4">
                    <div className="row align-items-center mb-3">
                        <div className="col-4">Choose File</div>
                        <div className="col-8">
                            <input type="file" className="form-control" accept={acceptFileFormats.EXCEL} onChange={(e) => handleFileValidation(e)} />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={() => closeModal()}>
                    Close
                </button>
                <button disabled={loading || !file} className="btn btn-primary" onClick={() => uploadFile()}>
                    {loading ? "Uploading..." : "Upload"}
                </button>
            </Modal.Footer>
        </Modal>
    );
}
