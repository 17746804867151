import { create } from "zustand";
import { devtools } from "zustand/middleware";

const store = (set) => ({
    // file mapping
    mappings: {},
    addMapping: (newMapping) => set(() => ({ mappings: newMapping })),
    removeMapping: () => set(() => ({ mappings: {}, mappingsObj: {} })),

    // file mapping obj => with slug
    mappingsObj: {},
    addMappingObj: (mappingsObj) => set(() => ({ mappingsObj })),
});

const useClientStore = create(devtools(store));

export default useClientStore;
