import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import Select from "react-select";
import Dropdown from "react-bootstrap/Dropdown";
import ReactDOM from "react-dom";

import Arrow from "../../Static/RawImages/arrow.png";
import CircularWithBarChart from "../Charts/CircularWithBarChart";
import HorizontalBarChart from "../Charts/HorizontalBarChart";

export default function FirBatchItem({
  data,
  downloadUploadedData,
  downloadTrackingReport1,
  downloadFirAnalysisReport,
  fileFir,
  servingToBorrower,
  downloadNotWorkingBatchFile,
  downloadFirCopies,
  viewStateBreakup,
}) {
  const [stateSelected, setStateSelected] = useState("all");

  const stateOptions = !!data?.state_wise_report
    ? [{ label: "ALL", value: "all" }, ...Object.keys(data?.state_wise_report)?.map((el) => ({ label: el?.toString()?.toUpperCase(), value: el?.toString() }))]
    : [];

  return (
    <div className="row d-flex justify-between">
      {/* Batch Data */}

      <div className="col-md-12 col-lg-3 mb-lg-0 mb-3 d-flex align-start">
        {/* Batch Details */}
        <div className="w-75">
          <Link to={`/prelitigation/fir/complaints/${data?.batch_id}`} className="text-primary">
            <p className="mb-2">
              <strong>Batch No. {data?.client_batch_id ? data?.client_batch_id : "-"}</strong>
            </p>
          </Link>

          <p className="mb-2">
            Ref - <span className="text-black">{data.batch_name ? data.batch_name : ""}</span>
          </p>

          {!!data.uploaded_date && <p className="mb-2">Date - {format(new Date(), "do MMM yyyy")}</p>}

          <p className="mb-2">
            Total Complaints : <span className="text-black">{data.total_complaints ? data.total_complaints : "0"}</span>
          </p>

          {!!stateOptions.length && (
            <div className="d-flex flex-column align-items-start">
              <label className="col-form-label pt-0 pb-0 w-100 mb-1">State Report</label>

              <Select
                options={stateOptions}
                value={stateOptions?.find((el) => el?.value == stateSelected)}
                onChange={(newVal) => setStateSelected(newVal?.value)}
                className="w-100"
                placeholder="Select"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    padding: "0.2rem 0",
                    paddingLeft: "10px",
                    border: "1px solid #dddddd !important",
                    borderRadius: "6px",
                    backgroundColor: "#f4f6f8",
                    maxWidth: "220px",
                    textTransform: "uppercase",
                    boxShadow: "none !important",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? "#E0E7E9" : state.isFocused ? "#f4f6f8" : "white",
                    color: "#555",
                    textTransform: "uppercase",
                  }),
                }}
              />
            </div>
          )}
        </div>
      </div>

      {/* Graphs */}

      <div className="col-md-12 col-lg-6 mb-lg-0 mb-3">
        <div className="status-progress">
          <GraphData stateOptions={stateOptions} stateSelected={stateSelected} data={data} />
        </div>
      </div>

      {/* Actions */}

      <div className="col-md-12 col-lg-3 mb-lg-0 mb-3">
        <div className="action-box">
          <label htmlFor="dropdown-basic" className="col-form-label pb-2">
            Action
          </label>
          <Dropdown>
            <Dropdown.Toggle variant="primary" id="dropdown-basic" className="w-100 text-start select-style">
              Select <img src={Arrow} alt="Sort" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="select-style-dropdown">
              <Dropdown.Item onClick={() => downloadUploadedData(data?.raw_file)}>Download Uploaded Data</Dropdown.Item>
              <Dropdown.Item onClick={() => downloadTrackingReport1(data?.client?.client_id, data?.batch_id)}>Download FIR Report</Dropdown.Item>
              <Dropdown.Item onClick={() => downloadFirAnalysisReport({ client_id: data?.client?.client_id, batch_id: data?.batch_id })}>
                Download FIR Analysis Report
              </Dropdown.Item>
              <Dropdown.Item onClick={() => fileFir(data.batch_id, false)}>File FIR</Dropdown.Item>
              <Dropdown.Item onClick={() => fileFir(data.batch_id, true)}>File Failed FIRs</Dropdown.Item>
              <Dropdown.Item onClick={() => servingToBorrower(data?.client?.client_id, data?.batch_id)}>Serving To Borrower</Dropdown.Item>
              <Dropdown.Item onClick={() => downloadNotWorkingBatchFile(data?.client?.client_id, data?.batch_id)}>Download Batch Data</Dropdown.Item>
              <Dropdown.Item onClick={() => downloadFirCopies(data?.client?.client_id, data?.batch_id)}>Download FIR Copies</Dropdown.Item>
              <Dropdown.Item onClick={() => viewStateBreakup(data?.batch_id)}>View State Breakup</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      {/* End line */}

      <div className="col-md-12">
        <hr className="mt-4 mb-4" />
      </div>
    </div>
  );
}

function GraphData({ stateOptions, stateSelected, data }) {
  const horizontalCharts = [
    { label: "WhatsApp", toolTip: "Delivered - ", percentKey: "whatspp_delivered_percent", totalKey: "total_whatsapp" },
    { label: "Email", toolTip: "Delivered - ", percentKey: "email_delivered_percent", totalKey: "total_email" },
    { label: "SMS", toolTip: "Delivered - ", percentKey: "sms_delivered_percent", totalKey: "total_sms" },
    // { label: "Postal", toolTip: "Delivered ", percentKey: "postal_delivered_percent", totalKey: "total_postal" },
  ]?.map((el) => ({
    value:
      !!stateOptions?.length && stateSelected != "all"
        ? data?.state_wise_report?.[stateSelected]?.[el?.percentKey]?.toString() || "0"
        : data?.[el?.percentKey]?.toString() || "0",
    color: "#1dc4e9",
    color1: "#1de9b6",
    label: el?.label,
    tooltipLabel:
      el?.toolTip +
      (!!stateOptions?.length && stateSelected != "all"
        ? data?.state_wise_report?.[stateSelected]?.[el?.percentKey]?.toString() || "0"
        : data?.[el?.percentKey]?.toString() || "0"),
  }));

  const circularCharts = {
    data: {
      name: "Complaints",
      value:
        !!stateOptions?.length && stateSelected != "all"
          ? data?.state_wise_report?.[stateSelected]?.filed_complaint_percent?.toString() || "0"
          : data?.filed_complaint_percent?.toString() || "0",
      color: "#00777c",
      title:
        !!stateOptions?.length && stateSelected != "all"
          ? data?.state_wise_report?.[stateSelected]?.total_complaints_for_this_state?.toString() || "0"
          : data?.total_complaints?.toString() || "0",
    },
    tooltipText: "Filed - ",
  };

  const ref = useRef(null);

  const renderChart = () => (
    <>
      <CircularWithBarChart data={circularCharts} />
      <HorizontalBarChart data={horizontalCharts} />
    </>
  );

  useLayoutEffect(() => {
    if (!!ref.current) {
      ReactDOM.render(renderChart(), ref.current);
    }
  }, [stateSelected]);

  return (
    <div className="chart-box content-center text-center d-flex pt-4">
      <div ref={ref} className="d-flex">
        {renderChart()}
      </div>
    </div>
  );
}
