// export const isEmpty = (item) =>
// (item === false ||
//     item === "None" ||
//     item === "none" ||
//     item === null ||
//     item === undefined
//     ? true : false
// );

export const isEmpty = (item) => item === false || item === "None" || item === "none" || item === null || item === undefined || String(item) === "0";
