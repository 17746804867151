import React, { useRef } from "react";
import ContentLoader from "../ContentLoader";
import { isEmpty } from "../../Functions/isEmpty";
import { format } from "date-fns";

function AccountsWhatsapp(props) {
    const data = props.AccountWhatsappData;
    const prevMsgRef = useRef(null);

    return data ? (
        <>
            {data?.length > 0 ? (
                <div className="account_extra_whatsapp">
                    {data?.slice(0, 30)?.map((item, i) => {
                        prevMsgRef.current = i <= 0 ? null : !isEmpty(data[i - 1]?.created) ? format(new Date(data[i - 1]?.created), "ddMMyyyy") : null;
                        return (
                            <React.Fragment key={i}>
                                {prevMsgRef.current !== format(new Date(item?.created), "ddMMyyyy") && (
                                    <div className="account_extra_date">
                                        <p className="title-hr mt-2 mb-4">
                                            <span>{!isEmpty(item?.created) ? format(new Date(item?.created), "do MMM yyyy") : ""}</span>
                                        </p>
                                    </div>
                                )}
                                <div className={item.owner === "Wati" ? "account_extra_whatsapp_owner" : "account_extra_whatsapp_user"}>
                                    <div className="account_extra_whatsapp_text">{item.text}</div>
                                    <span style={{ textAlign: "right" }}>{!isEmpty(item?.created) ? format(new Date(item?.created), "hh:mm aaa") : ""}</span>
                                </div>
                            </React.Fragment>
                        );
                    })}
                </div>
            ) : (
                <div className="fs-5 fw-bold">No Data Found</div>
            )}
        </>
    ) : (
        <>
            <ContentLoader />
        </>
    );
}

export default AccountsWhatsapp;
