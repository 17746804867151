import React from "react";
import useGeneralStore from "../../../store/generalStore";
import Filter from "./Filter";

export default function DailyReportHeader({ filters, setFilters }) {
    const isSidebarOpen = useGeneralStore((state) => state.is_sidebar_open);

    return (
        <>
            {/* spacer */}
            <div style={{ height: "4.5rem", position: "relative", width: "100%" }} />

            <div
                style={{
                    position: "fixed",
                    top: "70px",
                    left: "0",
                    paddingLeft: !!isSidebarOpen ? "178px" : "62px",
                    width: "100%",
                    transition: "all ease 0.5s",
                    zIndex: "99",
                    height: "4.5rem",
                    borderBottom: "1px solid #e5e5e5",
                    backgroundColor: "#fff",
                }}
            >
                <div className="d-flex align-items-center justify-content-between gap-4 px-4 h-100">
                    <div className="d-flex align-items-center justify-content-start">
                        <Filter prevFilter={filters} setPrevFilter={setFilters} />

                        <p className="text-muted mb-0 ms-2">
                            <small>{/* <em>{filter?.sort === "asc" ? "Ascending" : "Descending"}</em> */}</small>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}
