import { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { FaCircleCheck } from "react-icons/fa6";
import useBorrowerInfo from "../../store";
import AnalysisCard from "./analysis-card";

export default function SettlementConfirmation() {
    const [isOpen, setIsOpen] = useState(false);

    const isLoading = useBorrowerInfo((state) => state.isAnalysisLoading);

    const toggle = () => setIsOpen((prev) => !prev);

    return (
        <>
            <AnalysisCard onClick={toggle} title="Settlement Confirmation" isLoading={isLoading} tag="" content="" icon={FaCircleCheck} />

            <Offcanvas style={{ width: "350px" }} show={isOpen} onHide={toggle} backdropClassName="opacity-0" placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Settlement Confirmation</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <p className="m-5 text-center text-black">
                        <span style={{ fontSize: "1.5rem" }}>🚧</span>
                        <br />
                        Under Development <br /> come back later!
                    </p>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
